import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MainTitle } from "../../style/buttom-style";

import Form from "./../../components/features/components/Form";
import { BoxColumn, BoxRow } from "../../style/main-box";
import InputField from "../../components/features/components/InputField";
import { CiCircleMinus, CiCirclePlus } from "react-icons/ci";
import useControls from "../../hooks/useControls";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BASEURL, { USERS } from "../../data/Api";
import useRequest from "../../hooks/useRequest";
import filter from "../../utils/ClearNull";

export default function AddNewCustomer() {
  const [Data, setData] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.customers.value);
  const [userData, setUserData] = useState("فردي");

  const [RequestGetCustomers, ResponseGetCustomers] = useRequest({
    method: "GET",
    path: `${BASEURL}users/users/?type=Marchant`,
  });

  const getCustomers = () => {
    RequestGetCustomers({
      onSuccess: (res) => {
        dispatch({ type: "components/set", payload: res.data });
      },
    });
  };

  const [RequestPostCustomer, ResponsePostCustomer] = useRequest({
    method: "POST",
    path: `users/users/`,
  });

  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "marchant_type",
      value: "فردي",
      isRequired: true,
    },
    {
      control: "username",
      value: "",
      isRequired: false,
    },
    {
      control: "address",
      value: "",
      isRequired: false,
    },
    {
      control: "id",
      value: "",
      isRequired: false,
    },
    {
      control: "email",
      value: "",
      isRequired: false,
    },
    {
      control: "commercial_register",
      value: "",
      isRequired: userData === "فردي" ? false : true,
    },
    {
      control: "password",
      value: "",
      isRequired: false,
    },
    {
      control: "phone_numebrs",
      value: [],
      isRequired: false,
    },
  ]);

  const [phones, setPhones] = useState([
    { id: 1, name: "", phone: "", job: "" },
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Function to get customer data for a specific field
    const getCustomerData = (field) => {
      return customers?.results?.find(
        (customer) => customer?.id === controls?.customer
      )?.[field];
    };

    // Construct the request body as an object
    const formData = new FormData();

    // Append simple fields to FormData
    formData.append(
      "username",
      controls.username || getCustomerData("username")
    );
    formData.append("password", controls.password || "");
    formData.append("confirm_password", controls.password || "");
    formData.append("id", controls.id || "");
    formData.append("email", controls.email || getCustomerData("email"));
    formData.append("user_type", "Marchant"); // Hardcoded as 'Supplier' (change if dynamic)
    formData.append("first_name", controls.first_name || "");
    formData.append("last_name", controls.last_name || "");
    formData.append(
      "marchant_type",
      controls.marchant_type || getCustomerData("marchant_type")
    );
    formData.append("marchant_id", "1234"); // Static ID '1234' (change as necessary)
    formData.append("iban", controls.iban || "");
    formData.append("address", controls.address || getCustomerData("address"));
    formData.append("commercial_register", controls.commercial_register || "");
    formData.append("tax_card_no", controls.tax_card_no || "");

    // Append the phones array to FormData
    phones.forEach((phone, index) => {
      formData.append(`phones[${index}][name]`, phone.name);
      formData.append(`phones[${index}][phone]`, phone.phone);
      formData.append(`phones[${index}][job]`, phone.job);
    });

    // Validate before proceeding
    const { isOk } = await validate();
    if (!isOk) return;

    // Send the data using RequestPostCustomer function
    try {
      const res = await RequestPostCustomer({
        body: formData, // Send the plain object as the request body
        onSuccess: (res) => {
          console.log(res);
        },
      });

      console.log(res);

      const response = res?.response?.data;

      // Process the response
      const responseBody = filter({
        obj: {
          id: response?.id?.join(""),
          username: response?.username?.join(""),
          marchant_type: response?.marchant_type?.join(""),
          address: response?.address?.join(""),
          email: response?.email?.join(""),
          password: response?.password?.join(""),
          phones: response?.phones?.join(""),
        },
      });

      setInvalid(responseBody);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    setData(
      customers?.results?.find((obj) => obj.id === controls.customer)
        ?.product_component
    );
  }, [controls.customer]);

  const handleAddPhone = () => {
    const newPhone = {
      id: phones.length + 1,
      name: "",
      phone: "",
      job: "",
      notes: "",
    };
    setPhones((phones) => [...phones, newPhone]);
  };

  const handleDeletePhone = (id) => {
    setPhones((phones) => phones.filter((phone) => phone.id !== id));
  };

  const handleInputChange = (e, id) => {
    const { name, value } = e.target;
    setPhones(
      phones.map((phone) =>
        phone.id === id ? { ...phone, [name]: value } : phone
      )
    );
  };

  useEffect(() => {}, [controls.source]);
  console.log(controls?.source);

  return (
    <main>
      <Container maxWidth="xl" sx={{ mt: { md: 5, xs: 4 } }}>
        <Breadcrumbs path={["العملاء", " عميل جديد"]} />
        <Box sx={{ mb: 5 }}>
          <MainTitle sx={{ fontSize: "24px" }}>إضافة عميل</MainTitle>
        </Box>

        <Form
          childrenProps={{
            saveBtn: {
              onClick: handleSubmit,
              disabled: RequestPostCustomer.isPending,
            },
            closeBtn: {
              onClick: resetControls,
            },
          }}
          sx={{ height: "100%" }}
        >
          <Grid item>
            <Grid container spacing={{ md: 4, xs: 0 }}>
              <Grid item md={12} xs={12}>
                <FormGroup sx={{ flexDirection: "row", marginBottom: "28px" }}>
                  <FormControlLabel
                    sx={{
                      margin: 0,
                      "& .MuiFormControlLabel-label": {
                        fontSize: "12px", // Change this to whatever size you need
                      },
                    }}
                    control={
                      <Checkbox
                        sx={{
                          width: "12px",
                          height: "12px",
                          marginLeft: "8px",
                          "& .MuiSvgIcon-root": {
                            width: "18px",
                            height: "18px",
                            fill: "#183E91",
                          },
                        }}
                        defaultChecked
                      />
                    }
                    onChange={() => {
                      setUserData("فردي");
                      setControl("marchant_type", "فردي");
                    }}
                    label="فردي"
                    checked={userData === "فردي"}
                    required
                  />
                  <FormControlLabel
                    sx={{
                      margin: 0,
                      "& .MuiFormControlLabel-label": {
                        fontSize: "12px", // Change this to whatever size you need
                      },
                    }}
                    required
                    control={
                      <Checkbox
                        sx={{
                          width: "12px", // Set width to 14px
                          height: "12px", // Set height to 14px
                          margin: "8px",

                          "& .MuiSvgIcon-root": {
                            width: "18px",
                            height: "18px",
                            fill: "#183E91",
                          },
                        }}
                      />
                    }
                    checked={userData === "شركة"}
                    onChange={() => {
                      setUserData("شركة");
                      setControl("marchant_type", "شركة");
                    }}
                    label="شركة"
                  />
                </FormGroup>
                <BoxColumn sx={12} gap={4} width={"100%"}>
                  <BoxRow gap={10} flexDirection={{ xs: "column", md: "row" }}>
                    <InputField
                      variant={"outlined"}
                      label="الاسم*"
                      error={Boolean(invalid?.username)}
                      helperText={invalid?.username}
                      required={required.includes("username")}
                      onChange={(e) => setControl("username", e.target.value)}
                      sx={{ width: "60%" }}
                      value={controls.username}
                    />
                    <InputField
                      variant="outlined"
                      label="العنوان*"
                      error={Boolean(invalid?.address)}
                      onChange={(e) => setControl("address", e.target.value)}
                      required={required.includes("address")}
                      value={controls.address}
                      textHelper={controls?.address}
                      helperText={invalid?.address}
                      sx={{ width: "60%" }}
                    />
                  </BoxRow>
                </BoxColumn>
                <BoxColumn sx={12} gap={4} mt={4} width={"100%"}>
                  <BoxRow gap={10} flexDirection={{ xs: "column", md: "row" }}>
                    <InputField
                      variant={"outlined"}
                      label="الكود*"
                      error={Boolean(invalid?.id)}
                      helperText={invalid?.id}
                      required={required.includes("id")}
                      onChange={(e) => setControl("id", e.target.value)}
                      sx={{ width: "100%" }}
                    />
                    <InputField
                      variant={"outlined"}
                      label="البريد الالكتروني*"
                      error={Boolean(invalid?.email)}
                      helperText={invalid?.email}
                      required={required.includes("email")}
                      onChange={(e) => setControl("email", e.target.value)}
                      sx={{ width: "100%" }}
                    />
                  </BoxRow>

                  {userData === "شركة" ? (
                    <BoxRow
                      width={"100%"}
                      gap={10}
                      flexDirection={{ xs: "column", md: "row" }}
                    >
                      <InputField
                        width={"50%"}
                        variant={"outlined"}
                        label="السجل التجاري"
                        error={Boolean(invalid?.commercial_register)}
                        helperText={invalid?.commercial_register}
                        required={required.includes("commercial_register")}
                        onChange={(e) =>
                          setControl("commercial_register", e.target.value)
                        }
                        sx={{ width: "100%" }}
                      />
                      <span></span>
                    </BoxRow>
                  ) : (
                    ""
                  )}
                </BoxColumn>
                <BoxRow
                  marginTop={"24px"}
                  width={"100%"}
                  gap={10}
                  flexDirection={{ xs: "column", md: "row" }}
                >
                  <InputField
                    width={"50%"}
                    variant={"outlined"}
                    label="الرقم السري"
                    error={Boolean(invalid?.password)}
                    helperText={invalid?.password}
                    required={required.includes("password")}
                    onChange={(e) => setControl("password", e.target.value)}
                  />
                  <span></span>
                </BoxRow>
                <Box sx={{ marginTop: "26px" }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontFamily: "Cairo",
                      fontSize: 12,
                      color: "#687785",
                    }}
                  >
                    الهواتف
                  </Typography>
                  <Divider />
                </Box>
                {/* Render Phone Fields Dynamically */}
                {phones.map((phone, index) => (
                  <BoxColumn
                    key={phone.id}
                    sx={{ width: "100%" }}
                    gap={8}
                    mt={2}
                  >
                    <BoxRow gap={2} flexDirection={{ xs: "column", md: "row" }}>
                      <InputField
                        name="name"
                        value={phone.name}
                        onChange={(e) => handleInputChange(e, phone.id)}
                        variant={"outlined"}
                        label="الاسم*"
                        sx={{ width: "100%" }}
                        error={false}
                      />
                      <InputField
                        name="phone"
                        type="number"
                        value={phone.phone}
                        onChange={(e) => handleInputChange(e, phone.id)}
                        variant="outlined"
                        label="الهاتف*"
                        sx={{ width: "100%" }}
                        error={false}
                      />
                      <InputField
                        name="job"
                        value={phone.job}
                        onChange={(e) => handleInputChange(e, phone.id)}
                        variant="outlined"
                        thousandSeparator
                        label="الوظيفة*"
                        sx={{ width: "100%" }}
                        error={false}
                      />

                      {/* Delete button only for more than one phone */}

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: 3,
                        }}
                      >
                        {phones.length > 1 && (
                          <Button
                            onClick={() => handleDeletePhone(phone.id)}
                            sx={{ minWidth: 35 }}
                          >
                            <CiCircleMinus style={{ color: "red" }} size={20} />
                          </Button>
                        )}
                        <Button onClick={handleAddPhone} sx={{ minWidth: 35 }}>
                          <CiCirclePlus size={20} />
                        </Button>
                      </Box>
                    </BoxRow>
                  </BoxColumn>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Form>
        {ResponsePostCustomer.failAlert}
        {ResponsePostCustomer.successAlert}
        {ResponsePostCustomer.failAlert}
      </Container>
    </main>
  );
}
