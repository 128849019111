import React from "react";
import Box from "@mui/material/Box";
import { Container, Tabs } from "@mui/material";
import { Grid, IconButton, InputBase } from "@mui/material";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import Clients from "./clients";
import Stores from "./stores";
import Finances from "./finances";
import { TabPanelStyle } from "../../style/tab-panel";
import { useState } from "react";

const Main = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={1}
        justifyContent={"space-between"}
        sx={{ margin: { md: "1% 0", xs: "6% 0" } }}
      >
        <Grid item md={4}>
          <Box
            display={"flex"}
            border={"1px solid #E6EDFF"}
            borderRadius={"4px"}
            padding={"3px"}
            width={"100%"}
          >
            <IconButton type="button" sx={{ p: 1 }}>
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{
                flex: 1,
                color: "#202124",
                "&::placeholder": {
                  bgColor: "#A9AEB8",
                },
              }}
              placeholder="بحث"
            />
          </Box>
        </Grid>
        <Grid item md={8} sx={{ textAlign: "end" }}>
          <IconButton>
            <NotificationsNoneOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Box
        sx={{
          bgcolor: "background.paper",
          marginTop: "20px",
        }}
      >
        <Tabs
          sx={{ borderBottom: "4px solid #f0f0f0 " }}
          value={value}
          onChange={handleChange}
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable auto tabs example"
        >
          <TabPanelStyle label="عملاء" />
          <TabPanelStyle label="المخازن" />
          <TabPanelStyle label="الماليات" />
        </Tabs>

        <TabPanel value={value} index={0}>
          <Clients />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Stores />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Finances />
        </TabPanel>
      </Box>

   
    </Container>
  );
};

export default Main;

