import React from "react";
import {ButtonPrinter, ButtonAdd, MainTitle} from "../style/buttom-style";
import {Divider, Box, Stack} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
export const Header = ({name, buttonAdd}) => {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "space-between",
				mt: {md: 0, xs: 7},
				width: "100%",
				alignItems: "center",
				paddingLeft: 0,
				paddingRight: 0,
				
			}}>
			<MainTitle sx={{fontSize: {md: "24px", xs: "18px"}}}>{name}</MainTitle>

			<Stack direction="row" spacing={2} gap={3}>
				<ButtonPrinter
					onClick={() => window.print()}
					sx={{
					
						color: "var(--black-color, #202124)",
						fontWeight: "600",
						fontSize: {md: "16px", xs: "14px"},
					}}>
					طباعه
				</ButtonPrinter>
				{buttonAdd}
			</Stack>
		</Box>
	);
};

export const AddButton = ({name, click}) => {
	return (
		<>
			{/* <Divider
				orientation="vertical"
				sx={{width: "3px", backgroundColor: "#f5f5f5"}}
			/> */}
			<ButtonAdd
				sx={{
					color: "var(--black-color, #202124)",
					fontSize: {md: "18px", sm: "14px", xs: "12px"},
					fontWeight: "600",
					marginRight: {md: "20px", xs: "5px"},
					fontFamily: "Cairo",
				}}
				variant="contained"
				startIcon={<AddIcon sx={{ml: 1}} />}
				onClick={click}>
				{name}
			</ButtonAdd>
		</>
	);
};
