import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GetAllComponent from "./get-all-component";

const ProductComponents = () => {
	return (
		<>
			<Accordion
				sx={{
					"&.MuiAccordion-root": {
						boxShadow: "none !important",
					},

					marginBottom: "20px",
					marginTop: "20px",
				}}>
				<AccordionSummary
					sx={{background: "#f8f9fa",fontFamily:'Cairo',}}
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header">
					<Typography sx={{fontFamily:'Cairo',}}>حديد</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<GetAllComponent />
				</AccordionDetails>
			</Accordion>
			<Accordion
				sx={{
					"&.MuiAccordion-root": {
						boxShadow: "none !important",
					},
					"&.MuiPaper-root.MuiPaper-elevation::before": {
						display: "none",
					},
					marginBottom: "20px",
				}}>
				<AccordionSummary
					sx={{background: "#f8f9fa"}}
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel2a-content"
					id="panel2a-header">
					<Typography sx={{fontFamily:'Cairo',}}>بلاستيك</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
						malesuada lacus ex, sit amet blandit leo lobortis eget.
					</Typography>
				</AccordionDetails>
			</Accordion>
			<Accordion
				sx={{
					"&.MuiAccordion-root": {
						boxShadow: "none !important",
					},
					"&.MuiPaper-root.MuiPaper-elevation::before": {
						display: "none",
					},
					marginBottom: "20px",
				}}>
				<AccordionSummary
					sx={{background: "#f8f9fa"}}
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel3a-content"
					id="panel3a-header">
					<Typography sx={{fontFamily:'Cairo',}}>تغليف</Typography>
				</AccordionSummary>
			</Accordion>
		</>
	);
};

export default ProductComponents;
