import {Box, Container, Grid} from "@mui/material";
import React from "react";
import {MainTitle} from "../../style/buttom-style";
import {BoxColumn, BoxProfile, BoxRow} from "../../style/main-box";
import useControls from "../../hooks/useControls";
import InputField from "../../components/features/components/InputField";
import PictureButton from "../../components/features/components/picture-button";
import ToggleLanguage from "../../components/features/components/toggle-language";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {useNavigate} from "react-router-dom";
// import MuiPhoneNumber from "material-ui-phone-number";
const ProfilePage = () => {
	const navigate = useNavigate();
	const [
		{controls, invalid, required},
		{setControl, resetControls, setInvalid, validate},
	] = useControls([
		{
			control: "name",
			value: "",
			isRequired: false,
		},
		{
			control: "email",
			value: "",
			isRequired: false,
			validations: [
				{
					test: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
					message: "البريد غير صالح",
				},
				``,
			],
		},

		{
			control: "phone",
			value: "",
			isRequired: false,
		},
	]);

	return (
		<Container maxWidth="xl">
			<Box
				sx={{
					boxSizing: "border-box",
					display: "flex",
					justifyContent: "space-between",
					mt: {md: 0, xs: 7},
					width: "100%",
					alignItems: "center",
					padding: 2,
				}}>
				<MainTitle sx={{fontSize: {md: "24px", xs: "18px"}}}>
					البروفايل
				</MainTitle>
			</Box>
			<Grid item>
				<Grid container spacing={{md: 3, xs: 0}}>
					<Grid item md={6} xs={12}>
						<BoxProfile>
							<BoxColumn>
								<BoxRow
									sx={{
										padding: "12px 0 ",
										justifyContent: "center",
										alignItems: "center",
										maxHeight: "100%",
										maxWidth: "100%",
									}}>
									<PictureButton />
								</BoxRow>
								<BoxRow sx={{padding: "12px 0 "}}>
									<InputField
										variant={"outlined"}
										label="الاسم "
										placeholder="مصطفى حسنين"
										value={controls.name}
										onChange={(e) => {
											let text = /^(?:[A-Za-z\u0600-\u06ff\s]*)$/;

											let match = text.test(e.target.value);

											if (match) {
												setControl("name", e.target.value);
											}
										}}
										required={required.includes("name")}
										textHelper={controls.name}
										error={Boolean(invalid.name)}
										helperText={invalid.name}
										sx={{
											width: "100%",
											"& .MuiOutlinedInput-root": {
												background: "#fff !important",
											},
										}}
									/>
								</BoxRow>
								<BoxRow sx={{padding: "12px 0 "}}>
									<InputField
										type={"email"}
										variant="outlined"
										label=" البريد الالكتروني"
										placeholder="Mustafa@gmail.com"
										value={controls.email}
										onChange={(e) => {
											setControl("email", e.target.value);
										}}
										required={required.includes("email")}
										textHelper={controls?.email}
										error={Boolean(invalid?.email)}
										helperText={invalid?.email}
										sx={{width: "100%"}}
									/>
								</BoxRow>
								<BoxRow sx={{padding: "12px 0 ", width: "100%"}}>
									{/* <PhoneField
										variant="outlined"
										label="  الهاتف المحمول"
										placeholder=" 023135646"
										value={controls.phone}
										onChange={(e) => {
											setControl("phone", e.target.value);
										}}
										sx={{width: "100%"}}
									/>  */}
									{/* <MuiPhoneNumber
										variant="outlined"
										defaultCountry={"eg"}
										value={controls.phone}
										onChange={(e) => setControl("phone", e)}
										sx={{
											width: "100%",
											"& .MuiOutlinedInput-root": {
												height: "48px !important",
												background: "#F7F8FA",
												borderColor: "#C9CDD4",
												":hover": {
													border: "1px solid #1B53C5 !important",
												},
												":focus-within": {
													border: "1px solid #1B53C5 !important",
													background: "#fff",
												},
											},
											"& .MuiOutlinedInput-root.Mui-disabled": {
												":hover": {
													border: "1px solid #1B53C5 !important",
													boxShadow: "none",
												},
											},
											"& .MuiOutlinedInput-notchedOutline": {
												border: "1px solid #C9CDD4",
											},
										}}
									/> */}
								</BoxRow>
							</BoxColumn>
						</BoxProfile>
					</Grid>
					<Grid item md={6} xs={12}>
						<BoxProfile>
							<BoxRow sx={{padding: "12px 0"}}>
								<ToggleLanguage
									name={"اللغه"}
									icon={<LanguageOutlinedIcon />}
									description={"toggle buttom"}
								/>
							</BoxRow>
						</BoxProfile>
						<BoxProfile>
							<BoxRow sx={{padding: "12px 0"}}>
								<ToggleLanguage
									name={"تغير كلمه المرور"}
									icon={<LockOutlinedIcon />}
									description={"تغير كلمه المرور "}
									navigatePage={() => {
										navigate("change-password");
									}}
								/>
							</BoxRow>
						</BoxProfile>
					</Grid>
				</Grid>
			</Grid>
		</Container>
	);
};

export default ProfilePage;
