// const BASEURL = "http://192.168.1.17:8000/";
// const BASEURL = "https://jellyfish-app-bm7ym.ondigitalocean.app/";
const BASEURL = "https://el-mostafa.cyparta.com/";
export const AddComponent = `components/`;
export const login = `${BASEURL}auth/jwt/create/`;
export const SIGNUP = `${BASEURL}register/`;
export const SOURCES = `${BASEURL}sources/`;
export const PRODUCTS = `${BASEURL}products/`;
export const PRODUCT = `${BASEURL}product/`;
export const USERS = `${BASEURL}users/users`;
export const COMPONENTTYPES = `${BASEURL}component-types/`;
export const MATERIALS = `${BASEURL}materials/`;
export default BASEURL;
