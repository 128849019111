import { Box, Grid, Typography } from "@mui/material";

export default function StatisticsCard({heading,  number}) { 
  return     <Grid item md={2}>
          
 <Box
  display={"flex"}
flexDirection={'column'}
  border={"2px solid #F5F5F5"}
  borderRadius={"4px"}
  paddingY={"15.5px"}
paddingX={'20px'}
>
  <Typography sx={{
fontFamily:"Cairo" ,
fontSize:15,
fontWeight:500,
color:"#7f7f7f",
marginBottom:"8px"
}}
variant="h4">{heading}</Typography>
  <Typography
fontSize={32}
fontWeight={500}
fontFamily={"Cairo"}

>{number}</Typography>
</Box>
  </Grid>  
}