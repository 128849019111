/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import useControls from "../../hooks/useControls";
import InputField from "../../components/features/components/InputField";
import { BoxColumn, BoxRow } from "../../style/main-box";
import Breadcrumbs from "../../components/features/components/Breadcrumbs";
import NumberField from "../../components/features/components/NumberField";
import { MainTitle, SubTitle } from "../../style/buttom-style";
import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import SelectField from "../../components/features/components/SelectField";
import Form from "../../components/features/components/Form";
import useRequest from "../../hooks/useRequest";
import { useDispatch, useSelector } from "react-redux";
import filter from "../../utils/ClearNull";
import BASEURL from "../../data/Api";
import SelectValue from "../../components/features/components/SelectValue";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import TableEdit from "../../components/features/components/TableEdit";

const AddNewManufcturing = () => {
  const materials = useSelector((state) => state.materials.value);
  const types = useSelector((state) => state.types.value);
  const components = useSelector((state) => state.components.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "component",
      value: "",
      isRequired: false,
    },
    {
      control: "name",
      value: "",
      isRequired: false,
    },
    {
      control: "code",
      value: "",
      isRequired: false,
    },
    {
      control: "quantity",
      value: 0,
      isRequired: false,
    },
    // {
    // 	control: "price",
    // 	value: 0,
    // 	isRequired: true,
    // },
    {
      control: "types",
      value: "",
      isRequired: false,
    },
    {
      control: "materials",
      value: [],
      isRequired: false,
    },
  ]);

  const columns = [
    { name: "id", type: "text", inputType: "text" },
    {
      name: "material",
      type: "text",
    },
    { name: "code", type: "text", inputType: "text" },
    { name: "weight", type: "editAble", inputType: "text" },
  ];

  const [editedRows, setEditedRows] = useState([]);
  const [Data, setData] = useState([]);

  // Function to receive the updated row from the child component
  const handleRowUpdate = (updatedRow) => {
    setEditedRows(updatedRow);
  };

  // get components
  const [ReuestGetComponents, ResponseGetComponents] = useRequest({
    method: "GET",
    path: `${BASEURL}components/`,
  });

  // get types of material
  const [ReuestGetTypes, ResponseGetTypes] = useRequest({
    method: "GET",
    path: `${BASEURL}types/?material=${controls?.materials}`,
  });

  //get materials
  const [ReuestGetMaterial, ResponseGetMaterial] = useRequest({
    method: "GET",
    path: `${BASEURL}materials/`,
  });

  //get materials
  const [ReuestPostComponentsMaterial, ResponsePostComponentsMaterial] =
    useRequest({
      method: "POST",
      path: `${BASEURL}component/`,
    });

  // post manufcturing
  const [ReuestPostManufcturing, ResponsePostManufcturing] = useRequest({
    method: "PATCH",
    path: `${BASEURL}components/`,
    successMessage: "تم تصنيع  مكون جديد بنجاح",
  });

  const getComponents = () => {
    ReuestGetComponents({
      onSuccess: (res) => {
        dispatch({ type: "components/set", payload: res?.data });
      },
    });
  };

  const getTypes = () => {
    ReuestGetTypes({
      onSuccess: (res) => {
        dispatch({ type: "types/set", payload: res?.data });
      },
    });
  };
  const getMaterials = () => {
    ReuestGetMaterial({
      onSuccess: (res) => {
        dispatch({ type: "materials/set", payload: res?.data });
      },
    });
  };
  const PostMaterialstoComponents = () => {
    ReuestPostComponentsMaterial({
      id: `${controls.components}/materials`,
      body: {
        materials: editedRows,
      },
      onSuccess: (res) => {
        dispatch({ type: "components/set", payload: res?.data });
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validate().then(({ isOk }) => {
      if (!isOk) return;

      const requestBody = filter({
        obj: {
          name:
            controls.name ||
            components?.results?.find(
              (component) => component?.id === controls?.component
            )?.name,
          code:
            controls.code ||
            components?.results?.find(
              (component) => component?.id === controls?.component
            )?.code,
          quantity: controls.quantity,

          types: controls.types,
        },
        output: "formData",
      });

      if (
        Object.keys(
          filter({
            obj: {
              name: controls.name,
              code: controls.code,
              quantity: controls.quantity,
              types: controls.types,
            },
          })
        ).length <= 1
      )
        return;

      ReuestPostManufcturing({
        id: controls.component,
        body: requestBody,
        onSuccess: (res) => {
          console.log(res);
          ReuestPostComponentsMaterial({
            id: `${controls.component}/materials`,
            body: {
              materials: Data.map((obj) => ({
                material: obj.id,
                weight: Number(obj.weight),
              })),
            },
            onSuccess: (res) => {
              dispatch({ type: "components/addItem", payload: res?.data });
            },
          });
          resetControls();
          navigate("/manufacturing");
        },
      }).then((res) => {
        const response = res?.response?.data;
        resetControls();
        setInvalid(response);
      });
    });
  };

  useEffect(() => {
    setData(
      components?.results?.find((obj) => obj.id === controls.component)
        ?.component_materials
    );
  }, [controls.component]);

  useEffect(() => {
    setData([
      ...Data,
      ...materials?.results?.filter((obj) =>
        controls.materials.includes(obj.id)
      ),
    ]);
  }, [controls.materials]);
  return (
    <>
      <Container maxWidth="xl" sx={{ mt: { md: 0, xs: 4 } }}>
        <Breadcrumbs path={["التصنيع", " تصنيع جديد"]} />
        <Box sx={{ mb: 2 }}>
          <MainTitle sx={{ fontSize: "24px" }}> تصنيع مكون جديد</MainTitle>
        </Box>
        <Form
          childrenProps={{
            saveBtn: {
              onClick: handleSubmit,
              disabled: ReuestPostManufcturing.isPending,
            },
            closeBtn: {
              onClick: resetControls,
              disabled: ResponsePostManufcturing.isPending,
            },
          }}
          sx={{ height: "100%" }}
        >
          <Grid item>
            <Grid container spacing={{ md: 4, xs: 0 }}>
              <Grid item md={12} xs={12}>
                <BoxColumn gap={2} width={"100%"}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignSelf: "flex-start",
                      width: { md: "60%", xs: "100%" },
                    }}
                  >
                    <SelectField
                      variant="outlined"
                      placeholder=" اختر"
                      label="سبق تصنيعها"
                      sx={{ width: { md: "51%", xs: "100%" } }}
                      onOpen={getComponents}
                      value={controls.component}
                      renderValue={(selected) => {
                        return components?.results?.find(
                          (component) => component?.id === selected
                        )?.name;
                      }}
                      onChange={(e) => setControl("component", e.target.value)}
                      isPending={ResponseGetComponents.isPending}
                    >
                      {components?.results?.map((component) => {
                        return (
                          <MenuItem key={component?.id} value={component?.id}>
                            {component?.name}
                          </MenuItem>
                        );
                      })}
                    </SelectField>
                  </Box>

                  <BoxRow gap={4} flexDirection={{ xs: "column", md: "row" }}>
                    <InputField
                      variant={"outlined"}
                      label="اسم المكون* "
                      value={
                        controls.name ||
                        components?.results?.find(
                          (component) => component?.id === controls?.component
                        )?.name
                      }
                      onChange={(e) => {
                        let text = /^(?:[A-Za-z\u0600-\u06ff\s]*)$/;

                        let match = text.test(e.target.value);

                        if (match) {
                          setControl("name", e.target.value);
                        }
                      }}
                      required={required.includes("name")}
                      textHelper={controls.name}
                      error={Boolean(invalid.name)}
                      helperText={invalid.name}
                      sx={{ width: "100%" }}
                    />
                    <InputField
                      variant="outlined"
                      placeholder="الكود"
                      label="الكود"
                      thousandSeparator
                      value={
                        controls.code ||
                        components?.results?.find(
                          (component) => component?.id === controls?.component
                        )?.code
                      }
                      onChange={(e) => setControl("code", e.target.value)}
                      required={required.includes("code")}
                      error={Boolean(invalid.code)}
                      helperText={invalid.code}
                      sx={{ width: "100%" }}
                    />

                    <NumberField
                      variant="outlined"
                      placeholder="العدد"
                      label="العدد*"
                      thousandSeparator
                      value={controls.quantity}
                      onChange={(e) => setControl("quantity", e.target.value)}
                      required={required.includes("quantity")}
                      error={Boolean(invalid.quantity)}
                      helperText={invalid.quantity}
                      sx={{ width: "100%" }}
                    />
                  </BoxRow>
                  {/* <Box
										sx={{
											display: "flex",
											justifyContent: "flex-start",
											alignSelf: "flex-start",
											width: {md: "31%", xs: "100%"},
										}}>
										<NumberField
											variant="outlined"
											placeholder="السعر"
											label="السعر*"
											thousandSeparator
											value={controls.price}
											onChange={(e) => setControl("price", e.target.value)}
											required={required.includes("price")}
											textHelper={controls.price}
											error={Boolean(invalid.price)}
											helperText={invalid.price}
											sx={{width: "100%", fontFamily: "Cairo !important"}}
										/>
									</Box> */}
                </BoxColumn>
              </Grid>
              <Grid md={8} xs={12}>
                <Divider sx={{ m: 3 }} />
                <BoxColumn
                  gap={2}
                  width={"100%"}
                  sx={{ alignItems: "flex-start !important" }}
                >
                  <BoxRow gap={4} flexDirection={{ xs: "column", md: "row" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <SelectField
                        multiple
                        onOpen={getMaterials}
                        value={controls.materials}
                        renderValue={(selected) => {
                          const selectedMaterials = selected?.map((id) =>
                            materials?.results.find(
                              (material) => material?.id === id
                            )
                          );
                          return selectedMaterials
                            ?.map((material) => material?.material)
                            .join(",");
                        }}
                        onChange={(e) =>
                          setControl("materials", e.target.value)
                        }
                        isPending={ResponseGetMaterial.isPending}
                        disabled={controls?.components == "" ? true : false}
                        variant="outlined"
                        placeholder=" الماده الخام "
                        label="  الماده الخام"
                        sx={{ width: { md: "94%", xs: "100%" } }}
                      >
                        {materials?.results?.map((material) => {
                          return (
                            <MenuItem key={material?.id} value={material?.id}>
                              {material?.material}
                            </MenuItem>
                          );
                        })}
                      </SelectField>
                      {/* <AddIcon sx={{color:'#27AD60',mt:2}} onClick={PostMaterialstoComponents}/> */}
                    </Box>
                    <SelectField
                      onOpen={getTypes}
                      value={controls.types}
                      renderValue={(selected) => {
                        return types?.results.find(
                          (type) => type?.id === selected
                        )?.name;
                      }}
                      onChange={(e) => setControl("types", e.target.value)}
                      isPending={ResponseGetTypes.isPending}
                      disabled={controls?.materials == "" ? true : false}
                      variant="outlined"
                      placeholder="   بلاستيك "
                      label="   نوع الماده الخام"
                      sx={{ width: { md: "94%", xs: "100%" } }}
                    >
                      {types?.results?.map((type) => {
                        return (
                          <MenuItem key={type?.id} value={type?.id}>
                            {type?.name}
                          </MenuItem>
                        );
                      })}
                    </SelectField>
                  </BoxRow>
                </BoxColumn>
              </Grid>
            </Grid>
          </Grid>

          <Box mt={"24px"}>
            {controls?.materials?.length > 0 || controls.component > 0 ? (
              <TableEdit
                // rows={controls?.materials?.map((item) => {
                // 	const selectedItem=materials?.results?.find((material)=>material?.id===item)
                // 	return selectedItem
                // })}
                rows={Data}
                columns={columns}
                editedRows={editedRows}
                onUpdate={(row) => handleRowUpdate(row)}
              />
            ) : (
              <></>
            )}
          </Box>
        </Form>
      </Container>
      {ResponsePostManufcturing.failAlert}
      {ResponsePostManufcturing.successAlert}
    </>
  );
};

export default AddNewManufcturing;
