/* eslint-disable no-undef */
import React, { useEffect } from "react";
import propTypes, { number } from "prop-types";
import {
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { BoxRow } from "../../../style/main-box";
import CheckedTable from "../../../icons/CheckedTable.svg";
import { Gallery, Item } from "react-photoswipe-gallery";
import SelectField from "./SelectField";
import { useState } from "react";

const TableEdit = ({
  rows = [],
  columns = [],
  viewData = [],
  openRequestView,
  count = 0,
  label,
  updatedRow,
  onEdit = (e, row) => {},
  onDelete = (e, row) => {},
  onView = (e, row) => {},
  onPaginate = (page) => {},
  RenderCell = () => {},
  editedRows,
  onUpdate,
}) => {
  const [editedRow, setEditedRow] = useState([]);

  const handleChange = (e, rowindex) => {
    const updatedValue = e.target.value;
    const updatedRows = [...editedRow];
    const updatedRow = {
      ...updatedRows[rowindex],
      [e.target.name]: updatedValue,
    }; // use square brackets for dynamic key
    updatedRows[rowindex] = updatedRow;

    setEditedRow(updatedRows);
    console.log(editedRow);
    onUpdate(updatedRows);
  };

  useEffect(() => {
    setEditedRow(rows);
  }, [rows]);
  return (
    <TableContainer
      component={Paper}
      sx={{
        width: "100%",
        borderRadius: "8px",
        // marginBlock: "1rem",
        boxShadow: "0px 1px 2px 0px #1018280F , 0px 1px 3px 0px #1018281A",

        padding: "24px",

        background:
          "linear-gradient(0deg, #FFFFFF, #FFFFFF),linear-gradient(0deg, #EAECF0, #EAECF0)",
        fontFamily: "Cairo",
      }}
    >
      {/* <Container maxWidth="xl"> */}
      {/* <Box
				sx={{
					display: "flex",
					justifyContent: "start",

					padding: "20px 24px 19px 24px",
					alignItems: "center",
					gap: "16px",
					alignSelf: "stretch",
				}}>
				<GridTitle>{title}</GridTitle>
				<GridBadged>
					<TextBadged>
						{length?.length} {length?.name}
					</TextBadged>
				</GridBadged>
			</Box> */}
      {/* </Container> */}
      {/* {chipFilter && (
				<Box
					sx={{
						padding: "20px",
						display: "flex",
						justifyContent: "start",
						alignItems: "center",
						gap: 2,
					}}></Box>
			)} */}

      <Table aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              " & th": {
                borderBottom: "1px solid  #EAECF0",
              },
            }}
          >
            <TableCell
              colSpan={10}
              sx={{
                // textAlign: "center",
                padding: "12px 24px",
                alignSelf: "stretch",
                borderBottom: " 1px solid  #EAECF0",
                background: "#F9FAFB",
              }}
            >
              <SelectField
                variant={"outlined"}
                placeholder="components"
                onOpen={openRequestView}
                sx={{ border: "none !important" }}
              >
                {viewData.map((col) => (
                  <MenuItem key={col}>{col.name}</MenuItem>
                ))}
              </SelectField>
            </TableCell>

            {/* {columns.map((ele, index) =>
                ()
             
            )} */}
          </TableRow>
        </TableHead>
        <TableBody>
          {editedRow?.length > 0 ? (
            editedRow?.map((row, rowindex) => (
              <TableRow
                key={rowindex}
                sx={{
                  // "&:last-child td, &:last-child th": {
                  // 	border: "1px solid #efefef",
                  // },
                  "& td, th": {
                    borderBottom: "1px solid  #EAECF0",
                  },
                }}
              >
                {columns?.map((col, index) =>
                  col.type === "text" ? (
                    <TableCell
                      key={`${index}-${col?.id}`}
                      style={{
                        height: "44px",
                        padding: "12px 24px",
                        textAlign: "center",
                        alignSelf: "stretch",
                        borderBottom: "1px solid  #EAECF0",
                        color: col.color || "#667085",
                        fontSize: col.size || "14px",

                        fontWeight: "400",
                        lineHeight: "20px",
                        background:
                          row[col?.name] === "Working on it"
                            ? "#FDAB3D"
                            : row[col.name] === "Done"
                            ? "#00C875"
                            : row[col?.name] === "Stuck"
                            ? "#E2445C"
                            : "#fff",
                      }}
                    >
                      {row[col?.name] && Array.isArray(row[col?.name])
                        ? row[col?.name].map((ele) => ele[col?.main]).join(",")
                        : row[col?.name] && typeof row[col?.name] === "object"
                        ? row[col?.name][col?.main]
                        : String(row[col?.name]).slice(0, 50)}
                    </TableCell>
                  ) : col.type === "checkbox" ? (
                    <TableCell align="center">
                      {row[col?.name] === true ? (
                        <Checkbox
                          {...label}
                          icon={
                            <Box
                              sx={{
                                width: "20px",
                                height: "20px",
                                borderRadius: "6px",
                                border: "1px solid  #D0D5DD",
                                background: " #FFF",
                              }}
                            />
                          }
                          checkedIcon={<img src={CheckedTable} alt="checked" />}
                        />
                      ) : (
                        <Checkbox
                          {...label}
                          icon={
                            <Box
                              sx={{
                                width: "20px",
                                height: "20px",
                                borderRadius: "6px",
                                border: "1px solid  #D0D5DD",
                                background: " #FFF",
                              }}
                            />
                          }
                          checkedIcon={<img src={CheckedTable} alt="checked" />}
                        />
                      )}
                    </TableCell>
                  ) : col.type === "editAble" ? (
                    (console.log(editedRow[1]),
                    (
                      <TableCell align="center" key={rowindex}>
                        <TextField
                          name={col?.name}
                          placeholder={row[col?.name]}
                          value={editedRow[rowindex][col?.name]}
                          onChange={(e) => handleChange(e, rowindex)} // Update the edited row state with the modified row object}
                          size="small"
                          sx={{
                            width: "96.33px",
                            height: "40px",
                            borderRadius: "4px",
                            border: "1px solid #E6EDFF",
                          }}
                        />
                      </TableCell>
                    ))
                  ) : col.type === "renderCell" ? (
                    <TableCell
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: 1,
                        width: "100%",
                      }}
                    >
                      {col.RenderCell && col.RenderCell(row)}
                    </TableCell>
                  ) : (
                    <TableCell align="center">
                      {/* <img src={person1} alt={row[col.name]} /> */}
                      {/* {console.log(row[col.name])} */}
                      {row[col.name] &&
                        row[col.name]?.map((test, index) => {
                          return (
                            <Gallery key={test.id}>
                              {/* <Image
                                key={test.id}
                                src={test?.image === null ? person1 : test?.image}
                                alt={test.id}
                                width={40}
                                height={40}
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  margin: "0 10px",
                                }}
                              /> */}

                              <Item
                                original={test?.image}
                                thumbnail={test?.image}
                                width={752}
                                height={450}
                              >
                                {({ ref, open }) => (
                                  <img
                                    ref={ref}
                                    onClick={open}
                                    src={test?.image}
                                    width={40}
                                    alt="img"
                                  />
                                )}
                              </Item>
                            </Gallery>
                          );
                        })}

                      <Typography
                        variant="span"
                        sx={{
                          m: "10px",
                          display: "inline-block",
                          color: col.color || "#282A3C",
                          fontSize: col.size || "16px",
                          fontFamily: "Tajawal",
                          fontWeight: "400",
                        }}
                      >
                        {/* {row[col.name]} */}
                      </Typography>
                    </TableCell>
                  )
                )}
              </TableRow>
            ))
          ) : (
            <TableCell colSpan={10}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  width: "100%",
                  height: "50vh",
                }}
              >
                <CircularProgress />
              </Box>
            </TableCell>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableEdit;

TableEdit.propTypes = {
  columns: propTypes.arrayOf(
    propTypes.shape({
      RenderCell: propTypes.func,
    })
  ),
  rows: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.oneOfType([propTypes.string, propTypes.number]),
    })
  ),
  onEdit: propTypes.func,
  onPaginate: propTypes.func,
};
