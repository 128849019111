import { configureStore } from "@reduxjs/toolkit";
import ComponentSlice from "./pages/componentSlice";
import MaterialsSlice from "./pages/MaterialsSlice";
import ProductsSlice from "./pages/productsSlice";
import ManufacturingSlice from "./pages/manufcturingSlice";
import AssemblySlice from "./pages/assemblySlice";
import TypesSlice from "./pages/typesSlice";
import userInfo from "./pages/userInfo";
import SourcesSlice from "./pages/sourcesSlice";
import ComponentTypesSlice from "./pages/componentTypesSlice";
import customersSlice from "./pages/clientsSlice";
import JobsSlice from "./pages/jobsSlice";
export const store = configureStore({
  reducer: {
    components: ComponentSlice,
    materials: MaterialsSlice,
    manufacturing: ManufacturingSlice,
    products: ProductsSlice,
    assembly: AssemblySlice,
    types: TypesSlice,
    userInfo: userInfo,
    sources: SourcesSlice,
    componenttypes: ComponentTypesSlice,
    customers: customersSlice,
    jobs: JobsSlice,
  },
});
