import React, { useEffect, useState } from "react";
import CustomContainer from "../../components/features/components/customContainer";
import BASEURL from "../../data/Api";
import useRequest from "../../hooks/useRequest";
import { useDispatch, useSelector } from "react-redux";
import useConfirmMessage from "./../../hooks/useConfirmMessage";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/details-veiw/Modal";

const columns = [
  { value: "الكود ", name: "id", type: "text", inputType: "text" },
  {
    value: "الوظيفة",
    name: "name",
    type: "text",
    inputType: "text",
  },
  { value: "الإجراءات", type: "option", name: "phone", inputType: "phone" },
];

const Jobs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState(false);

  const [selectedJob, setSelectedJob] = useState(null);

  const jobs = useSelector((state) => state.jobs?.value);

  const getJobs = () => {
    ReuestGetJobs({
      onSuccess: (res) => {
        dispatch({ type: "jobs/set", payload: res.data });
        console.log(jobs);
      },
    });
  };

  const [ReuestGetJobs, ResponseGetJobs] = useRequest({
    method: "Get",
    path: `${BASEURL}users/jobs`,
  });

  const [ReuestDeleteComponents, ResponseDeleteComponents] = useRequest({
    method: "DELETE",
    path: `${BASEURL}users/jobs`,
  });

  const deleteJob = (row) => {
    ReuestDeleteComponents({
      customPath: `users/jobs${row?.id}`,
      onSuccess: (res) => {
        dispatch({ type: "jobs/deleteItem", payload: { id: row?.id } });
      },
    });
  };
  const [handleDelete, deleteJobConfirmDialog] = useConfirmMessage({
    onConfirm: deleteJob,
    text: "هل انت متأكد من انك تريد حذف هذا المكون",
  });


  const handleView = (job) => {
    setSelectedJob(job);  // Set selected material
    setOpenModal(true);  // Open modal
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedJob(null);  
  };


  useEffect(() => {
    getJobs();
  }, []);

  return (
    <>
      <CustomContainer
        title={"الوظائف"}
        onDelete={(e, row) => handleDelete(row)}
        length={{ length: jobs?.results.length, name: "وظيفة" }}
        columns={columns}
        rows={jobs?.results}
        onEdit={(e,row) => {console.log("hell");  navigate(`/jobsemployees/addjob/${row.id}`)}}
        onView={(e, row) => handleView(row)}  // Add onView to trigger modal
      />
      {deleteJobConfirmDialog}
      <Modal title="تفاصيل الوظيفة" openModal={openModal} handleCloseModal={handleCloseModal} >
	       {selectedJob ? (
        <>
          <p><strong>الكود:</strong> {selectedJob.id}</p>
          <p><strong>اسم الوظيفة:</strong> {selectedJob.name}</p>
        </>
      ) : (
        <p>جاري تحميل البيانات...</p>
      )}
	 </Modal>
    </>
  );
};

export default Jobs;
