import styled from "@emotion/styled";
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    width: "50%",
    border: "1px solid #B9B9B9",
    fontFamily: "Cairo",
  },
});

const StyledTableRow = styled(TableRow)({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fff",
    fontFamily: "Cairo",
  },
});
const StyledTableCelleven = styled(TableCell)({
  "&:nth-of-type(odd)": {
    backgroundColor: "rgba(253 , 182 , 181 , 0.2)",
    fontFamily: "Cairo",
    // opacity: "20%",
  },
});



function createData(name, description) {
  return { name, description };
}

const rows = [
  createData("اسم المنتج", "شفشق كبه 1.5 لتر"),
  createData("الوزن  ", "52 جرام"),
  createData("السعر", 50),
  createData("الكود", 1325),
  createData("رقم المنتج على الرف", 88),
];

const DetailsTable = () => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCelleven component="th" scope="row" align="right">
                  {row.name}
                </StyledTableCelleven>
                <StyledTableCell align="right">
                  {row.description}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default DetailsTable;