import React, {useState} from "react";
import {Container, Tabs, Typography, Box} from "@mui/material";
import {TabPanelStyle} from "../../style/tab-panel";
import PropTypes from "prop-types";
import AllProducts from "./all-products";
import {Header, AddButton} from "../../components/header";
import {useNavigate} from "react-router-dom";
const Products = () => {
	const navigate = useNavigate();
	
	const [value, setValue] = useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	function TabPanel(props) {
		const {children, value, index, ...other} = props;

		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`vertical-tabpanel-${index}`}
				aria-labelledby={`vertical-tab-${index}`}
				{...other}>
				{value === index && (
					<Box sx={{p: 1}}>
						<Typography>{children}</Typography>
					</Box>
				)}
			</div>
		);
	}
	TabPanel.propTypes = {
		children: PropTypes.node,
		index: PropTypes.number.isRequired,
		value: PropTypes.number.isRequired,
	};
	return (
		<Container maxWidth="xl">
		
			<Box
				sx={{
					bgcolor: "background.paper",
					marginTop: "20px",
				}}>
				
			
					<AllProducts />
	
			</Box>
		</Container>
	);
};

export default Products;
