import React from "react";
import CustomContainer from "../features/components/customContainer";
import {useDispatch, useSelector} from "react-redux";
import useRequest from "../../hooks/useRequest";
import BASEURL from "../../data/Api";
import {useEffect} from "react";
import {Avatar, Box, Typography} from "@mui/material";
const GetAllComponent = () => {
	const dispatch = useDispatch();
	const components = useSelector((state) => state.components.value);
	const columns = [
		{value: "المسلسل", name: "id", type: "checkbox", inputType: "text"},
		{value: "المسلسل", name: "id", type: "text", inputType: "text"},
		{
			value: "المكون",
			name: "name",
			type: "renderCell",
			RenderCell: (row) => {
				return (
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-start",
							alignItems: "center",
							gap: 1,
						}}>
						<Avatar src={row?.main_image} alt="ima" />
						<Typography sx={{fontFamily: "Cairo"}}>{row?.name}</Typography>
					</Box>
				);
			},
		},
		{value: "المصدر ", name: "source", type: "text", inputType: "text"},
		{value: "الكود ", name: "code", type: "text", inputType: "text"},
		{value: "الكمية ", name: "quantity", type: "text", inputType: "text"},
		{value: "الإجراءات", type: "option", name: "phone", inputType: "phone"},
	];
	const [ReuestGetComponents, ResponseGetComponents] = useRequest({
		method: "Get",
		path: `${BASEURL}components/`,
	});

	const [ReuestDeleteComponents, ResponseDeleteComponents] = useRequest({
		method: "DELETE",
		path: `${BASEURL}components/`,
	});
	const getComponents = () => {
		ReuestGetComponents({
			onSuccess: (res) => {
				dispatch({type: "components/set", payload: res.data});
			},
		});
	};

	const DeleteComponents = (row) => {
		ReuestDeleteComponents({
			id: row?.id,
			onSuccess: (res) => {
				dispatch({type: "components/deleteItem", payload: {id: row?.id}});
			},
		});
	};

	useEffect(() => {
		getComponents();
	}, []);
	return (
		<>
			<CustomContainer
				title={"المكونات"}
				length={{length: components?.count, name: "مكون"}}
				columns={columns}
				rows={components?.results}
				onDelete={(e, row) => DeleteComponents(row)}
				onEdit={() => {}}
			/>
		</>
	);
};

export default GetAllComponent;
