import React, { useEffect } from "react";
import { Avatar, Container, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { Tabs } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { TabPanelStyle } from "../../style/tab-panel";
import { Header, AddButton } from "../../components/header";
import SearchComponent from "../../components/search-component";
import CustomContainer from "../../components/features/components/customContainer";
import { useDispatch, useSelector } from "react-redux";
import useConfirmMessage from "../../hooks/useConfirmMessage";
import { Link, useNavigate } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import BASEURL from "../../data/Api";
import axios from "axios";
// import { fetchUsers } from "../../store/pages/clientsSlice";
const Suppliers = () => {
  const customers = useSelector((state) => state.customers.value);

  const [ReuestGetCustomers, ResponseGetCustomers] = useRequest({
    method: "Get",
    path: `${BASEURL}users/users/?type=Supplier`,
  });

  const [ReuestDeleteCustomer, ResponseDeleteCustomer] = useRequest({
    method: "DELETE",
    path: `${BASEURL}users/users/?type=Supplier`,
  });

  const getCustomers = () => {
    ReuestGetCustomers({
      onSuccess: (res) => {
        dispatch({ type: "customers/set", payload: res?.data });
      },
    });
  };

  const DeleteCustomer = (row) => {
    console.log(row);
    ReuestDeleteCustomer({
      id: row?.id,
      onSuccess: (res) => {
        dispatch({ type: "products/deleteItem", payload: { id: row?.id } });
      },
    });
  };

  useEffect(() => {
    getCustomers();
  }, []);

  let formatedUsers = [];

  if (customers.length) {
    formatedUsers = customers?.map((user) => {
      return {
        id: user.user.id,
        // code: user.code,
        address: user.address,
        username: user.user.username,
        email: user.user.email,
        type: user.marchant_type,
        phone_number: user.user.user_phones[0]?.phone || "",
      };
    });
  }
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //   // dispatch(fetchUsers());
  // }, [dispatch]);

  const [handleDelete, deleteProductConfirmDialog] = useConfirmMessage({
    // onConfirm: DeleteProduct,
    text: "هل انت متأكد من انك تريد حذف هذا المنتج؟",
  });

  const columns = [
    { value: "select", name: "id", type: "checkbox", inputType: "text" },
    { value: "المسلسل", name: "id", type: "text", inputType: "text" },

    { value: "الاسم ", name: "username", type: "text", inputType: "text" },
    // {
    //   value: "الكود",
    //   name: "code",
    //   type: "text",
    //   inputType: "text",
    // },
    {
      value: "العنوان",
      name: "address",
      type: "text",
      inputType: "text",
    },
    {
      value: "البريد الالكتروني",
      name: "email",
      type: "text",
      inputType: "text",
    },
    {
      value: "رقم الهاتف",
      name: "phone_number",
      type: "text",
      inputType: "text",
    },
    {
      value: "نوع العميل",
      name: "type",
      type: "text",
      inputType: "text",
    },
    {
      value: "الاجراءات",
      name: "is_active",
      type: "option",
      inputType: "text",
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(0);
  };




  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  return (
    <Container maxWidth="xl">
      <Header
        name={"جميع العملاء"}
        buttonAdd={
          <AddButton
            name={"اضافه مورد"}
            click={() => {
              navigate("/customers/add-new-supplier");
            }}
          />
        }
      />

      <Box
        sx={{
          bgcolor: "background.paper",
          marginTop: "20px",
        }}
      >
        <Tabs
          sx={{ borderBottom: "4px solid #f0f0f0 " }}
          value={1}
          onChange={handleChange}
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable auto tabs example"
        >
          <Link to={"/customers"}>
            <TabPanelStyle label="عملاء" />
          </Link>
          <TabPanelStyle label="موردين" />
        </Tabs>

        <TabPanel value={value} index={0}></TabPanel>

        <TabPanel value={value} index={1}></TabPanel>
        <TabPanel value={value} index={2}></TabPanel>
      </Box>
      <SearchComponent />
      <CustomContainer
        title={"جميع الموردين"}
        length={{ length: formatedUsers?.length || 0, name: "مورد" }}
        columns={columns}
        rows={formatedUsers}
        onDelete={handleDelete}
        onView={(e,row) => row}
        // onView={(e,row) => handleView(row)}

        onEdit={(e,row) => row}
      />
    </Container>
  );
};

export default Suppliers;
