import React from "react";
import Grid from "@mui/material/Grid";
import ResponsiveDrawer from "../SidebarResponsive";

const Layout = ({children}) => {
	return (
		<>
			<Grid container>
				<Grid item md={2.5} sm={2.5} sx={{width: "100%", zIndex: 0}}>
					<ResponsiveDrawer />
				</Grid>
				<Grid item md={9.5} xs={12} pt={2}>
					{children}
				</Grid>
			</Grid>
		</>
	);
};

export default Layout;
