import { Route, Routes, useNavigate } from "react-router-dom";
import State from "./pages/state/state";
import Accounts from "./pages/accounts/accounts";
import Components from "./pages/the-components/components";
import Assembly from "./pages/assembly/assembly";
import Products from "./pages/products/products";
import Stores from "./pages/stores/stores";
import Customers from "./pages/customers/customers";
import Control from "./pages/control/control";
import Main from "./pages/main/main";
import Addnewcomponent from "./pages/the-components/addnewcomponent";
import AddNewManufcturing from "./pages/manufacturing/add-new-manufcturing";
import AddNewProduct from "./pages/products/add-new-product";
import AddNewAssembly from "./pages/assembly/add-new-assembly";
import ProfilePage from "./pages/profile-page/profile-page";
import JobsEmployees from "./pages/jobs-employees/jobs-employees";
import AddEmployee from "./pages/jobs-employees/add-employee";
import AddJob from "./pages/jobs-employees/add-job";
import Login from "./pages/login/login";
import Layout from "./components/layout/layout";
import ChangePassword from "./pages/change-password/change-password";
import DetailsVeiw from "./components/details-veiw/details-veiw";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Signup from "./pages/signup/signup";
import AddNewCustomer from "./pages/customers/add-new-customer";
import AddRawMaterial from "./pages/control/AddRawMaterial";
import { createTheme, ThemeProvider } from "@mui/material";
import theme from "./style/globals";
import Suppliers from "./pages/customers/suppliers";
import Inovices from "./pages/invoices/invoices";
import Manufacturing from "./pages/manufacturing/manufacturing";
import AddNewSupplier from "./pages/customers/add-new-supplier";
import { setToken, setUserInfo } from "./store/pages/userInfo";

function App() {
  const token = localStorage.getItem("userinfo");
  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    if (!token) {
      navigate("/signin");
    }

    // dispatch(setUserInfo());

    // userInfoGetRequest({
    //   onSuccess: (res) => {
    //     dispatch({ type: "userInfo/setUserInfo", payload: res.data });
    //   },
    // });
  }, [token]);
  console.log(token);
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Routes>
          {token ? (
            <>
              <Route
                path="/*"
                element={
                  <Layout>
                    <Main />
                  </Layout>
                }
              />
              <Route
                path="state"
                element={
                  <Layout>
                    <State />
                  </Layout>
                }
              />
              <Route
                path="accounts"
                element={
                  <Layout>
                    <Accounts />
                  </Layout>
                }
              />
              <Route
                path="components"
                element={
                  <Layout>
                    <Components />
                  </Layout>
                }
              />
              {/* <Route
					path="manufacturing"
					element={
						<Layout>
							<Manufacturing />
						</Layout>
					}
				/> */}
              <Route
                path="assembly"
                element={
                  <Layout>
                    <Assembly />
                  </Layout>
                }
              />
              <Route
                path="products"
                element={
                  <Layout>
                    <Products />
                  </Layout>
                }
              />
              <Route
                path="stores"
                element={
                  <Layout>
                    <Stores />
                  </Layout>
                }
              />
              <Route
                path="customers"
                element={
                  <Layout>
                    <Customers />
                  </Layout>
                }
              />
              <Route
                path="customers/suppliers"
                element={
                  <Layout>
                    <Suppliers />
                  </Layout>
                }
              />
              <Route
                path="customers/add-new-customer"
                element={
                  <Layout>
                    <AddNewCustomer />
                  </Layout>
                }
              />
              <Route
                path="customers/add-new-customer"
                element={
                  <Layout>
                    <AddNewCustomer />
                  </Layout>
                }
              />

              <Route
                path="control"
                element={
                  <Layout>
                    <Control />
                  </Layout>
                }
              />
              <Route
                path="control/add-new-raw"
                element={
                  <Layout>
                    <AddRawMaterial />
                  </Layout>
                }
              />
              <Route
                path="control/add-new-raw/:id"
                element={
                  <Layout>
                    <AddRawMaterial />
                  </Layout>
                }
              />
              <Route
                path="control/add-new-product"
                element={
                  <Layout>
                    <AddNewProduct />
                  </Layout>
                }
              />
               <Route
                path="control/add-new-product/:id"
                element={
                  <Layout>
                    <AddNewProduct />
                  </Layout>
                }
              />
              <Route
                path="components/addnewcomponent"
                element={
                  <Layout>
                    <Addnewcomponent />
                  </Layout>
                }
              />
              <Route
                path="manufacturing/"
                element={
                  <Layout>
                    <Manufacturing />
                  </Layout>
                }
              />
              <Route
                path="manufacturing/addnewmanufcturing"
                element={
                  <Layout>
                    <AddNewManufcturing />
                  </Layout>
                }
              />
              <Route
                path="products/addnewproduct"
                element={
                  <Layout>
                    <AddNewProduct />
                  </Layout>
                }
              />
              <Route
                path="assembly/addnewassembly"
                element={
                  <Layout>
                    <AddNewAssembly />
                  </Layout>
                }
              />
              <Route
                path="/invoices"
                element={
                  <Layout>
                    <Inovices />
                  </Layout>
                }
              />
              <Route
                path="profile"
                element={
                  <Layout>
                    <ProfilePage />
                  </Layout>
                }
              />
              <Route
                path="jobsemployees"
                element={
                  <Layout>
                    <JobsEmployees />
                  </Layout>
                }
              />
              <Route
                path="jobsemployees/addempolyee"
                element={
                  <Layout>
                    <AddEmployee />
                  </Layout>
                }
              />
                <Route
                path="jobsemployees/addjob/:id"
                element={
                  <Layout>
                    <AddJob />
                  </Layout>
                }
              />
              <Route
                path="jobsemployees/addjob"
                element={
                  <Layout>
                    <AddJob />
                  </Layout>
                }
              />
              <Route
                path="profile/change-password"
                element={
                  <Layout>
                    <ChangePassword />
                  </Layout>
                }
              />
              <Route
                path="details-veiw"
                element={
                  <Layout>
                    <DetailsVeiw />
                  </Layout>
                }
              />
            </>
          ) : (
            <>
              <Route path="signin" element={<Login />} />
              <Route path="signup" element={<Signup />} />
              {/* <Route path="/" element={<Login />} /> */}
            </>
          )}
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
