import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SearchComponent from "../../components/search-component";
import CustomContainer from "../../components/features/components/customContainer";
import useRequest from "../../hooks/useRequest";
import useConfirmMessage from "../../hooks/useConfirmMessage";
import BASEURL from "../../data/Api";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@mui/material"; // Import necessary components for modal
import Modal from "../../components/details-veiw/Modal";

const Materials = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const materials = useSelector((state) => state.materials.value);

  // Modal state to manage the visibility of the material details modal
  const [openModal, setOpenModal] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState(null);

  // Column configuration
  const columns = [
    { label: "الخامة", name: "material", type: "text" },
    { label: "الكود", name: "id", type: "text" },
    { label: "الوزن", name: "weight", type: "text" },
    { label: "الإجراءات", type: "option", name: "actions" },
  ];

  // API request hooks
  const [getMaterialsRequest, getMaterialsResponse] = useRequest({
    method: "GET",
    path: "products/materials",
  });

  const [deleteMaterialRequest, deleteMaterialResponse] = useRequest({
    method: "DELETE",
    path: "products/materials",
  });

  // Fetch materials list
  const fetchMaterials = () => {
    getMaterialsRequest({
      onSuccess: (response) => {
        dispatch({ type: "materials/set", payload: response.data });
      },
    });
  };

  // Delete material handler
  const deleteMaterial = (material) => {
    deleteMaterialRequest({
      customPath: `products/materials/${material.id}`,
      onSuccess: () => {
        dispatch({ type: "materials/deleteItem", payload: { id: material.id } });
      },
    });
  };


   // Handle view material details in the modal
   const handleView = (material) => {
    setSelectedMaterial(material);  // Set selected material
    setOpenModal(true);  // Open modal
  };

  // Close the modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedMaterial(null);  
  };

  // Confirmation dialog hook for delete action
  const [handleDelete, deleteConfirmationDialog] = useConfirmMessage({
    onConfirm: deleteMaterial,
    text: "هل انت متأكد من انك تريد حذف هذه المادة الخام",
  });

  // Fetch materials on component mount
  useEffect(() => {
    fetchMaterials();
  }, []);

 

  return (
    <>
      <SearchComponent />
      <CustomContainer
        title="الخامات"
        length={{ length: materials.results?.length, name: "خامة" }}
        columns={columns}
        rows={materials?.results}
        onDelete={(e, row) => handleDelete(row)}
        onEdit={(e, row) => navigate(`/control/add-new-raw/${row.id}`)}
        onView={(e, row) => handleView(row)}  // Add onView to trigger modal
      />
      {deleteConfirmationDialog}

     <Modal title={"تفاصيل الوظيفة"} openModal={openModal} handleCloseModal={handleCloseModal} >
	 {selectedMaterial ? (
        <>
          <p><strong>اسم المادة:</strong> {selectedMaterial.material}</p>
          <p><strong>الكود:</strong> {selectedMaterial.id}</p>
          <p><strong>الوزن:</strong> {selectedMaterial.weight}</p>
        </>
      ) : (
        <p>جاري تحميل البيانات...</p>
      )}
	 </Modal>
    </>
  );
};

export default Materials;
