import React, {useState} from "react";
import styled from "@emotion/styled";
import {
	Grid,
	IconButton,
	Typography,
	Box,
	Drawer,
	Button,
	Tabs,
	Tab,
	Container,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DetailsTable from "./details-table";
import {BoxColumn, BoxProfile, BoxRow} from "../../style/main-box";
import PropTypes from "prop-types";
import testImage from ".././../images/wood.jpg";
import LogDetails from "./log-details";
import ProductComponents from "./product-components";
const DetailsVeiw = ({open,onClose}) => {
	const [state, setState] = useState({
		right: false,
	});
	// const [open, setOpen] = useState(false);
	const [value, setValue] = useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	function TabPanel(props) {
		const {children, value, index, ...other} = props;
		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`vertical-tabpanel-${index}`}
				aria-labelledby={`vertical-tab-${index}`}
				{...other}>
				{value === index && (
					<Box sx={{p: 1}}>
						<Typography>{children}</Typography>
					</Box>
				)}
			</div>
		);
	}

	TabPanel.propTypes = {
		children: PropTypes.node,
		index: PropTypes.number.isRequired,
		value: PropTypes.number.isRequired,
	};
	const toggleDrawer = (anchor, open) => (event) => {
		if (
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}

		setState({[anchor]: open});
	};
	const DrawerHeader = styled("div")({
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
	});
	const handleDrawerOpen = () => {
		// setOpen(true);
	};

	const handleDrawerClose = () => {
		// setOpen(false);
	};

	// drawer data views
	const list = (anchor) => (
		<Box
			sx={{width: "100%"}}
			role="presentation"
			// onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}>
				<Container maxWidth="xl">
			<DrawerHeader>
				<Grid
					container
					justifyContent={"space-between"}
					alignItems={"center"}
					marginBottom={3}>
					<Grid item md={8} xs={8}>
						{" "}
						<Typography
							sx={{fontFamily: "Cairo", fontSize: "20px", fontWeight: "600"}}>
							شفشق كبه 1.5 لتر
						</Typography>
					</Grid>
					<Grid item md={4} xs={4}>
						<Box
						onClick={onClose}
							sx={{
								display: "flex",
								justifyContent: "flex-end",
								alignItems: "center",
							}}>
							 <IconButton sx={{":hover":{background:'transparent'}}}>
								<Typography
									sx={{
										fontFamily: "Cairo",
										fontSize: "20px",
										fontWeight: "500",
										marginLeft: 1,
										color: "#1D2129 ",
									}}>
									رجوع للخلف
								</Typography>
								<ChevronLeftIcon />
							</IconButton>
						</Box>
					</Grid>
				</Grid>
			</DrawerHeader>
			<Grid container spacing={2}>
			
					<Grid item md={4} xs={12} >
						<BoxProfile
							sx={{
								background: "#fff !important",
								marginTop: "0 !important",
								height: "100% !important",
							}}>
							<BoxColumn>
								<BoxRow
									sx={{
										padding: "12px 0 ",
										justifyContent: "center",
										alignItems: "center",
										maxWidth: "20%",
									}}>
									<img src={testImage} alt="product" width={220} height={220} />
								</BoxRow>
							</BoxColumn>
						</BoxProfile>
					</Grid>
					<Grid item md={8} xs={12}>
						<DetailsTable />
					</Grid>
				
			</Grid>
			<Box
				sx={{
					bgcolor: "background.paper",
					marginTop: "20px",
				}}>
				<Tabs
					sx={{borderBottom: "4px solid #f0f0f0 "}}
					value={value}
					onChange={handleChange}
					scrollButtons
					allowScrollButtonsMobile
					aria-label="scrollable auto tabs example">
					<Tab
						label=" المكونات"
						sx={{
							"&.MuiButtonBase-root": {
								color: "var(--black-color, #202124)",
								fontFamily: "Cairo",
							},
						}}
					/>
					<Tab
						label="السجل"
						sx={{
							"&.MuiButtonBase-root": {
								color: "var(--black-color, #202124)",
								fontFamily: "Cairo",
							},
						}}
					/>
				</Tabs>
				<TabPanel value={value} index={0}>
					<ProductComponents />
				</TabPanel>
				<TabPanel value={value} index={1}>
					<LogDetails />
				</TabPanel>
			</Box>
			</Container>
		</Box>
	);
	return (
			<Drawer
			variant="temporary"
				sx={{
					"& .MuiDrawer-paper": {
						width: "75%",
						transition: 'background-color 2s ease'
					},
				}}
				
				anchor={"right"}
				open={open}
				onClose={toggleDrawer("right", false)}>
				{list("right")}
			</Drawer>
	);
};

export default DetailsVeiw;
