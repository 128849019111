import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'

export default function Modal({openModal ,handleCloseModal , title = "التفاصيل", children}) {
  return (
    <Dialog open={openModal} onClose={handleCloseModal} fullWidth>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
    {children}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseModal} color="primary">
        إغلاق
      </Button>
    </DialogActions>
  </Dialog>
  )
}
