import {Container} from "@mui/material";
import React from "react";
import CustomContainer from "../../components/features/components/customContainer";

const columns = [
	{ value: "select", name: "id", type: "checkbox", inputType: "text" },
	{ value: "المسلسل", name: "id", type: "text", inputType: "text" },
	{ value: "الكود ", name: "code", type: "text", inputType: "text" },
	{
	  value: "الموظف",
	  name: "job",
	  type: "text",
	  inputType: "text",
	},
	{
	  value: "الهاتف المحمول",
	  name: "phoneNumber",
	  type: "text",
	  inputType: "text",
	},
	{
	  value: "البريد الالكتروني",
	  name: "email",
	  type: "text",
	  inputType: "text",
	},
	{
		value: "الوظيفة",
		name: "job",
		type: "text",
		inputType: "text",
	  },
	  {
		value: "تاريخ الانشاء",
		name: "data",
		type: "text",
		inputType: "text",
	  },
	  {
		value: "الاجراءات",
		name: "data",
		type: "options",
		inputType: "text",
	  }, 
  ];





const Employees = () => {
	return <Container maxWidth={"xl"}>
		<CustomContainer
		title={"جميع الموظفين"}
		columns={columns}
		
		
		 />
	</Container>;
};

export default Employees;
