import React from "react";
import {IconButton, InputAdornment, TextField} from "@mui/material";
import {useState} from "react";
import {SubTitle} from "../../../style/buttom-style";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
const PasswordField = ({
	variant = null,
	onChange,
	label = "",
	value,
	placeholder = "",
	InputProps,
	...rest
}) => {
	const [visible, setVisible] = useState(false);
	const handleToggleVisibility = () => {
		setVisible((prevVisible) => !prevVisible);
	  };
	return (
		<>
			<SubTitle sx={{fontSize: "14px !important"}}>{label}</SubTitle>
			<TextField
				type={visible ? "text" : "password"}
				variant={variant ? variant : "standard"}
				placeholder={placeholder}
				onChange={onChange}
				value={value}
				// inputProps={{autoComplete: "new-password"}}
				InputProps={{
					
					endAdornment: (
						<InputAdornment position="end" sx={{margin: 0}}>
							<IconButton  onClick={handleToggleVisibility}>
								{visible ? (
									<VisibilityOffOutlinedIcon />
								) : (
									<VisibilityOutlinedIcon />
								)}
							</IconButton>
						</InputAdornment>
					),
				}}
				{...rest}
				sx={{
					width: "100%",
					"& .MuiOutlinedInput-root": {
						height: "32px !important",
						background: "#F7F8FA",
						borderColor: "#C9CDD4",
						":hover": {
							border: "1px solid #1B53C5 !important",
						},
						":focus-within": {
							border: "1px solid #1B53C5 !important",
							background: "#fff",
						},
					},
					"& .MuiOutlinedInput-root.Mui-disabled": {
						":hover": {
							border: "1px solid #1B53C5 !important",
							boxShadow: "none",
						},
					},
					"& .MuiOutlinedInput-notchedOutline": {
						border: "1px solid #C9CDD4",
					},
					input: {
						':-webkit-autofill': {
							WebkitBoxShadow: '0 0 0 1000px #fff inset',
							backgroundColor: 'transparent !important'
						}
					  },
					  ...rest
				}}
			/>
		</>
	);
};

export default PasswordField;
