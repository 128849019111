import React from "react";

import {Box, TextField, Typography} from "@mui/material";
import {BoxColumn} from "../../../style/main-box";
import {SubTitle} from "../../../style/buttom-style";

const InputField = ({
	variant = null,
	onChange,
	label = "",
	value,
	placeholder = "",
	type,
	...rest
}) => {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "start",
				width: "100%",
			}}>
			<SubTitle>{label}</SubTitle>
			<TextField
				type={type ? type : "text"}
				variant={variant ? variant : "standard"}
				hiddenLabel
				placeholder={placeholder}
				onChange={onChange}
				value={value}
				inputProps={{inputMode: "text", pattern: "[A-Za-z]+"}}
				error={!/^[A-Za-z]+$/.test(value)}
				{...rest}
				sx={{
					width: "100%",
					"& .MuiOutlinedInput-root": {
						height: "32px !important",
						background: "#F7F8FA",
						borderColor: "#C9CDD4",
						":hover": {
							border: "1px solid #1B53C5 !important",
						},
						":focus-within": {
							border: "1px solid #1B53C5 !important",
							background: "#fff",
						},
					},
					"& .MuiOutlinedInput-root.Mui-disabled": {
						":hover": {
							border: "1px solid #1B53C5 !important",
							boxShadow: "none",
						},
					},
					"& .MuiOutlinedInput-notchedOutline": {
						border: "1px solid #C9CDD4",
					},
					root: {
						':-webkit-autofill': {
							WebkitBoxShadow: '0 0 0 1000px white inset',
							backgroundColor: 'red !important'
						}
					  },
					  input: {
						':-webkit-autofill': {
							WebkitBoxShadow: '0 0 0 1000px white inset',
							backgroundColor: 'red !important'
						}
					  },
					  ...rest
					// "& .MuiOutlinedInput-root": {
					//   "&.Mui-focused fieldset": {
					//     color:'#272E3B',
					//     background:'#fff'
					//   },

					// },

					// '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input':{
					//   height:'32px !important',p:'0 8px !important',background:'#F7F8FA',borderColor:'#C9CDD4',
					// }
				}}
			/>
		</Box>
	);
};

export default InputField;
