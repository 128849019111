import React, {useState} from "react";
import {Container, Tabs} from "@mui/material";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import {Typography} from "@mui/material";
import Materials from "./materials";
import SpareParts from "./spare-parts";
import FinalProducts from "./final-products";
import {TabPanelStyle} from "../../style/tab-panel";
import {Header, AddButton} from "../../components/header";
import Products from "../products/products";
import { useNavigate } from "react-router-dom";
const Stores = () => {

	const navigate = useNavigate()
	const getAddButtonText = (value) => {
		switch (value) {
		  case 0:
			return { text: "اضافه مادة خام", url: "/control/add-new-raw" }; // Replace with actual URL
		  case 1:
			return { text: "اضافة منتج", url: "/control/add-new-product" }; // Replace with actual URL
		  default:
			return { text: "اضافة", url: "/add-default" }; // Default URL
		}
	  };
	  
	 
	  
	  
	const [value, setValue] = React.useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	function TabPanel(props) {
		const {children, value, index, ...other} = props;
		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`vertical-tabpanel-${index}`}
				aria-labelledby={`vertical-tab-${index}`}
				{...other}>
				{value === index && (
					<Box sx={{p: 1}}>
						<Typography>{children}</Typography>
					</Box>
				)}
			</div>
		);
	}

	TabPanel.propTypes = {
		children: PropTypes.node,
		index: PropTypes.number.isRequired,
		value: PropTypes.number.isRequired,
	};
	return (
		<Container maxWidth="xl">
	 <Header 
		name={"المخازن"} 
		buttonAdd={
		  <AddButton 
		  name={getAddButtonText(value).text} 
			click={() => {
              navigate(getAddButtonText(value).url);
            }}
		
		
		  />
		}
	  />


				
			<Box
				sx={{
					maxWidth: {md: "100%", xs: 320, sm: 480},
					bgcolor: "background.paper",
					marginTop: "20px",
				}}>
				<Tabs
					value={value}
					onChange={handleChange}
					scrollButtons
					allowScrollButtonsMobile
					aria-label="visible arrows tabs example">
					<TabPanelStyle label=" مخزن الخامات" />
					<TabPanelStyle label=" مخزن المنتجات (النهائية)" />
				</Tabs>
				<TabPanel value={value} index={0}>
					<Materials />
				</TabPanel>
			
				<TabPanel value={value} index={1}>
					<Products />
				</TabPanel>
			</Box>
		</Container>
	);
};

export default Stores;
