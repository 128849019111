import React from "react";
import SearchComponent from "../../components/search-component";

const AccountsState = () => {
	return (
		<>
			<SearchComponent />
		</>
	);
};

export default AccountsState;
