import React, { useState } from "react";
import { Tabs, Typography, Box, Container } from "@mui/material";
import PropTypes from "prop-types";
import CustomerAccounts from "./customer-accounts";
import PaymentsAccounts from "./payments-accounts";
import RevenueAccounts from "./revenue-accounts";
import { TabPanelStyle } from "../../style/tab-panel";
import { Header, AddButton } from "../../components/header";





const Accounts = () => {
  // state
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  return (
    <Container maxWidth="xl">
      <Box sx={{ mt: { md: 0, xs: 7 }, width: "100%" }}>
        <Header name={"الحسابات"} buttonAdd={<AddButton name={"اضافه"} />} />
      </Box>
      <Box
        sx={{
          bgcolor: "background.paper",
          marginTop: "20px",
        }}
      >
        <Tabs
          sx={{ borderBottom: "4px solid #f0f0f0 " }}
          value={value}
          onChange={handleChange}
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable auto tabs example"
        >
          <TabPanelStyle label="حسابات العملاء" />
          <TabPanelStyle label="المدفوعات" />
          <TabPanelStyle label="الايرادات" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <CustomerAccounts />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PaymentsAccounts />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <RevenueAccounts />
        </TabPanel>
      </Box>
    </Container>
  );
};

export default Accounts;
