import React, { useState } from "react";
import { Container, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import AllComponents from "./all-components";
import Imported from "./imported";
import Local from "./local";
import { TabPanelStyle } from "../../style/tab-panel";
import { useNavigate } from "react-router-dom";
import { AddButton, Header } from "../../components/header";

const Components = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const navigate = useNavigate();
  return (
    <Container maxWidth="xl">
      <Header
        name={"الخلطات"}
        buttonAdd={
          <AddButton
            click={() => navigate("addnewcomponent")}
            name={"اضافه خلطة"}
          />
        }
      />

      <Box
        sx={{
          bgcolor: "background.paper",
          marginTop: "20px",
        }}
      >
        <Tabs
          sx={{ borderBottom: "4px solid #f0f0f0 " }}
          value={value}
          onChange={handleChange}
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable auto tabs example"
        >
          <TabPanelStyle label="جميع الخلطات" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <AllComponents />
        </TabPanel>
      </Box>
    </Container>
  );
};

export default Components;
