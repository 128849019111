import React, {useEffect, useState} from "react";
import {Box, Container, Grid, Tabs, Typography} from "@mui/material";
import {MainTitle} from "../../style/buttom-style";
import Breadcrumbs from "../../components/features/components/Breadcrumbs";
import {TabPanelStyle} from "../../style/tab-panel";
import PropTypes from "prop-types";
import ProductData from "./product-data";
import ProductComponents from "./product-components";
import BASEURL from "../../data/Api";
import useRequest from "../../hooks/useRequest";
import {  useNavigate, useParams } from "react-router-dom";

import useControls from "../../hooks/useControls";
import InputField from "../../components/features/components/InputField";
import NumberField from "../../components/features/components/NumberField";
import { BoxColumn, BoxRow } from "../../style/main-box";
import Form from "../../components/features/components/Form";
const AddNewProduct = () => {
	const params = useParams();
	const [productData, setProductData] = useState(null);
	const navigate = useNavigate()

	const [
		{ controls, invalid, required },
		{ setControl, resetControls, setInvalid, validate },
	  ] = useControls([
		{ control: "id", value: "", isRequired: true },
		{ control: "name", value: "", isRequired: true },
		{ control: "weight", value: "", isRequired: true },
	  ]);


	const [ReuestPostProducts, ResponsePostProducts] = useRequest({
		method: "POST",
		path: `${BASEURL}products/products/`,
		successMessage: "تم إضافة مادة خام جديد بنجاح",
	  });
	  const [RequestGetProduct, ResponseGetProduct] = useRequest({
		path: `${BASEURL}products/products/${params.id}`,
		method: "Get",
	  });
	  
	  const [RequestPatchProduct, ResponePatchProduct] = useRequest({
		path: `${BASEURL}products/products/${params.id}/`,
		method: "PATCH",
	  });
	  useEffect(() => {
		if (params.id) {
		  handleViewProduct(params.id);
		}
	  }, [params.id]);
	  
	  
  const handleViewProduct = (id) => {
    RequestGetProduct({
      onSuccess: (res) => {
        console.log(res);
        setProductData(res?.data); 
        setControl("id", res?.data?.id);
        setControl("name", res?.data?.name);
        setControl("weight", res?.data?.weight);
      },
      onError: (err) => {
        console.log(err.error);
      },
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    validate().then(({ isOk }) => {
      if (!isOk) return;

      const requestBody = {
        id: controls.id,
        name: controls.name,
        weight: controls.weight,
      };

      // If fields are invalid, do not submit
      if (!requestBody.id || !requestBody.material || !requestBody.weight) {
        return;
      }

    

      // Submit either for add or update based on presence of material data
      if (productData) {
        ReuestPostProducts({
          body: requestBody,
          onSuccess: (res) => {
            console.log("product updated successfully:", res);
            navigate("/products"); // Redirect after update
          },
        });
      } else {
        // Add new material (new mode)
        ReuestPostProducts({
          body: requestBody,
          onSuccess: (res) => {
            console.log("product added successfully:", res);
            navigate("/products"); // Redirect after adding
          },
        }).then((res) => {
          const response = res?.response?.data;
          setInvalid({
            id: response?.id,
            material: response?.material,
            weight: response?.weight,
          });
        });
      }
    });
  };


	return (
		<Container maxWidth="xl">
		<Box>
		  <Breadcrumbs path={["مواد", "إضافة مادة خام جديد"]} />
		  <Box sx={{ mb: "32px" }}>
			<MainTitle sx={{ fontSize: "24px" }}>
			  {productData ? "تعديل منتج" : "إضافة منتج جديد"}
			</MainTitle>
		  </Box>
  
		  <Form
			childrenProps={{
			  saveBtn: {
				onClick: handleSubmit,
				children:`${productData ? "تعديل " :"إضافة"}`,
				disabled: ResponsePostProducts.isPending,
			
			  },
			  closeBtn: {
				onClick: resetControls,
				disabled: ResponsePostProducts.isPending,
			  },
			}}
		  >
			<Grid container spacing={{ md: 4, xs: 0 }}>
			  <Grid item md={12} xs={12}>
				<BoxColumn gap={2} width={"100%"}>
				  <BoxRow gap={"26px"} flexDirection={{ xs: "column", md: "row" }}>
					<InputField
					  variant="outlined"
					  label="الكود*"
					  value={controls.id}
					  onChange={(e) => setControl("id", e.target.value)}
					  required={required.includes("id")}
					  error={Boolean(invalid?.id)}
					  helperText={invalid?.id}
					  sx={{ width: "100%" }}
					/>
  
					<InputField
					  variant="outlined"
					  label="اسم المادة الخام*"
					  value={controls.name}
					  onChange={(e) => setControl("name", e.target.value)}
					  required={required.includes("name")}
					  error={Boolean(invalid?.name)}
					  helperText={invalid?.name}
					  sx={{ width: "100%" }}
					/>
				  </BoxRow>
				  <BoxRow gap={"26px"} flexDirection={{ xs: "column", md: "row" }}>
					<Box width={"100%"}>
					  <InputField
						variant="outlined"
						label="وزن الرصيد الافتتاحي*"
						value={controls.weight}
						onChange={(e) => setControl("weight", e.target.value)}
						required={required.includes("weight")}
						error={Boolean(invalid?.weight)}

						helperText={invalid?.weight}
						sx={{ width: "100%" }}
					  />
					</Box>
					<Box width={"100%"}></Box>
				  </BoxRow>
				</BoxColumn>
			  </Grid>
			</Grid>
		  </Form>
		</Box>
		{ResponsePostProducts.failAlert}
		{ResponsePostProducts.successAlert}
		{ResponePatchProduct.successAlert}
		{ResponePatchProduct.failAlert}
	  </Container>
	);
};

export default AddNewProduct;
