import React from "react";
import Box from "@mui/material/Box";
import {IconButton, InputBase} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const SearchComponent = ({handleSearch}) => {
	return (
		<>
			<Box
				my={2}
				display={"flex"}
				border={"1px solid #E6EDFF"}
				borderRadius={"4px"}
				padding={"3px"}
				width={{md:"40%",xs:'100%'}}>
				<IconButton type="button" sx={{p: 1}}>
					<SearchIcon />
				</IconButton>
				<InputBase
					sx={{
						flex: 1,
						color: "#202124",
						"&::placeholder": {
							bgColor: "#A9AEB8",
						},
					}}
					onChange={handleSearch}
					placeholder="بحث"
				/>
			</Box>
		</>
	);
};

export default SearchComponent;
