import {Box, Container, Tabs, Typography} from "@mui/material";
import React from "react";
import {AddButton, Header} from "../../components/header";
import {useState} from "react";
import PropTypes from "prop-types";
import {TabPanelStyle} from "../../style/tab-panel";
import Jobs from "./jobs";
import Employees from "./employees";
import {useNavigate} from "react-router-dom";
const JobsEmployees = () => {
	const [type, setType] = useState("");
	const [value, setValue] = useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const navigate = useNavigate();
	const navigatePage =
		type === "empolyee"
			? "addempolyee"
			: type === ""
			? "addjob"
			: "addempolyee";

	function TabPanel(props) {
		const {children, value, index, ...other} = props;
		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`vertical-tabpanel-${index}`}
				aria-labelledby={`vertical-tab-${index}`}
				{...other}>
				{value === index && (
					<Box sx={{p: 1}}>
						<Typography>{children}</Typography>
					</Box>
				)}
			</div>
		);
	}

	TabPanel.propTypes = {
		children: PropTypes.node,
		index: PropTypes.number.isRequired,
		value: PropTypes.number.isRequired,
	};
	return (
		<Container maxWidth="xl">
			<Header
				buttonAdd={
					<AddButton
						name={
							type === "empolyee"
								? "اضافه موظف"
								: type === "jobs"
								? "اضافه وظيفه"
								: "اضافه موظف"
						}
						click={() => {
							type === "empolyee"
								? navigate("addempolyee")
								: type === "jobs"
								? navigate("addjob")
								: navigate("addempolyee");
						}}
					/>
				}></Header>
			<Box
				sx={{
					bgcolor: "background.paper",
					marginTop: "20px",
				}}>
				<Tabs
					sx={{borderBottom: "4px solid #f0f0f0 "}}
					value={value}
					onChange={handleChange}
					scrollButtons
					allowScrollButtonsMobile
					aria-label="scrollable auto tabs example">
					<TabPanelStyle
						label=" الموظفين"
						onClick={() => {
							setType("empolyee");
						}}
					/>
					<TabPanelStyle
						label="الوظائف"
						onClick={() => {
							setType("jobs");
						}}
					/>
				</Tabs>
				<TabPanel value={value} index={0}>
					<Employees />
				</TabPanel>
				<TabPanel value={value} index={1}>
					<Jobs />
				</TabPanel>
			</Box>
		</Container>
	);
};

export default JobsEmployees;
