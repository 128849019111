import { Box, Button, Container, Grid, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import NumberField from "../../components/features/components/NumberField";
import { MainTitle } from "../../style/buttom-style";
import useControls from "../../hooks/useControls";
import InputField from "../../components/features/components/InputField";
import { BoxColumn, BoxRow } from "../../style/main-box";
import Breadcrumbs from "../../components/features/components/Breadcrumbs";
import Form from "../../components/features/components/Form";
import SelectField from "../../components/features/components/SelectField";
import useRequest from "../../hooks/useRequest";
import BASEURL from "../../data/Api";
import { useDispatch, useSelector } from "react-redux";
import { CiCircleMinus, CiCirclePlus } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

const Addnewcomponent = ({mode}) => {
  const materials = useSelector((state) => state.materials.value);
  // const [add, setAdd] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Request hooks
  const [ReuestPostComponent, ResponsePostComponent] = useRequest({
    method: "POST",
    path: `${BASEURL}products/mixtures/`,
    successMessage: "تم إضافة مكون جديد بنجاح",
  });

  const [ReuestGetMaterials, ResponseGetMaterials] = useRequest({
    method: "Get",
    path: `${BASEURL}products/materials/`,
  });

  // Form controls setup
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    { control: "code", value: "", isRequired: true },
    { control: "name", value: "", isRequired: true },
    { control: "weight", value: "", isRequired: false },
    {
      control: "mixture_materials",
      value: [{ id: 1, material: "", weight: "" }],
      isRequired: false,
    },
  ]);

  // Handle Add New Material
  const handleAddnewMaterial = () => {
    setControl("mixture_materials", [
      ...controls.mixture_materials,
      { id: controls.mixture_materials.length + 1, material: "", weight: "" },
    ]);
  };

  // Handle Delete Material
  const handleDeleteMaterial = (id) => {
    const filteredMaterials = controls.mixture_materials.filter(
      (item) => item.id !== id
    );
    setControl("mixture_materials", filteredMaterials);
  };

  // Get materials
  const getMaterials = () => {
    // dispatch({
    //   type: "materials/set",
    //   payload: [{ id: 1, material: "ahmed hamdy" }],
    // });
    ReuestGetMaterials({
      onSuccess: (res) => {
        dispatch({ type: "materials/set", payload: res.data });
      },
    });
  };

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    validate().then(({ isOk }) => {
      if (!isOk) return;

      const requestBody = {
        id: controls.code,
        name: controls.name,
        // weight: controls.weight,
        mixture_materials: controls.mixture_materials,
      };

      // If fields are invalid, do not submit
      if (
        !requestBody.id ||
        !requestBody.name ||
        // !requestBody.weight ||
        !requestBody.mixture_materials.length
      ) {
        return;
      }

      ReuestPostComponent({
        body: requestBody,
        method: "POST",
        onSuccess: (res) => {
          console.log("Component added successfully:", res);
        },
      }).then((res) => {
        const response = res?.response?.data;
        setInvalid({
          code: response?.code?.join(""),
          name: response?.name?.join(""),
          // weight: response?.weight?.join(""),
          mixture_materials: response?.mixture_materials,
        });
      });
    });
  };

  useEffect(() => {
    getMaterials();
  }, []);

  return (
    <Container maxWidth="xl">
      <Box>
        <Breadcrumbs path={["خلطات", "إضافة خلطة"]} />
        <Box sx={{ mb: "32px" }}>
          <MainTitle sx={{ fontSize: "24px" }}>إضافة خلطة جديدة</MainTitle>
        </Box>

        <Form
          childrenProps={{
            saveBtn: {
              onClick: handleSubmit,
              disabled: ReuestPostComponent.isPending,
            },
            closeBtn: {
              onClick: resetControls,
              disabled: ResponsePostComponent.isPending,
            },
          }}
        >
          <Grid container spacing={{ md: 4, xs: 0 }}>
            <Grid item md={12} xs={12}>
              <BoxColumn gap={2} width={"100%"}>
                <BoxRow
                  gap={"26px"}
                  flexDirection={{ xs: "column", md: "row" }}
                >
                  <InputField
                    variant="outlined"
                    label="الكود*"
                    value={controls.code}
                    onChange={(e) => setControl("code", e.target.value)}
                    required={required.includes("code")}
                    error={Boolean(invalid?.code)}
                    helperText={invalid?.code}
                    sx={{ width: "100%" }}
                  />

                  <InputField
                    variant="outlined"
                    label="اسم الخلطة*"
                    value={controls.name}
                    onChange={(e) => setControl("name", e.target.value)}
                    required={required.includes("name")}
                    error={Boolean(invalid?.name)}
                    helperText={invalid?.name}
                    sx={{ width: "100%" }}
                  />
                </BoxRow>

                {controls.mixture_materials.map((item, index) => (
                  <>
                    <BoxRow
                      position={"relative"}
                      gap={"26px"}
                      width={"100%"}
                      display={"flex"}
                      justifyItems={"center"}
                      alignItems={"center"}
                    >
                      <SelectField
                        key={item.id}
                        variant="outlined"
                        value={item.material}
                        label="اسم المادة الخام"
                        onOpen={getMaterials}
                        onChange={(e) => {
                          setControl(
                            "mixture_materials",
                            controls.mixture_materials.map((mat, idx) => {
                              if (idx === index) {
                                return { ...mat, material: e.target.value }; // Ensure we return a new object
                              }
                              return mat; // Return the unchanged item
                            })
                          );
                        }}
                        error={Boolean(invalid?.mixture_materials)}
                        helperText={invalid?.mixture_materials}
                        sx={{ width: "100%" }}
                      >
                        {materials.results.map((material) => (
                          <MenuItem key={material.id} value={material.id}>
                            {material?.material}
                          </MenuItem>
                        ))}
                      </SelectField>

                      <NumberField
                        variant="outlined"
                        label="النسبة"
                        value={item.weight} // Bind value to item.weight
                        onChange={(e) => {
                          // Update the weight of the selected item
                          setControl(
                            "mixture_materials",
                            controls.mixture_materials.map((mat, idx) => {
                              if (idx === index) {
                                mat.weight = e.target.value; // Update weight value
                              }
                              return mat;
                            })
                          );
                        }}
                      />

                      <Box
                        position={"absolute"}
                        left={
                          controls.mixture_materials.length > 1
                            ? "-85px"
                            : "-45px"
                        }
                        display={"flex"}
                        marginTop={"26px"}
                        alignItems={"center"}
                      >
                        {controls.mixture_materials.length > 1 && (
                          <Button
                            onClick={() => handleDeleteMaterial(item.id)}
                            sx={{ minWidth: 35 }}
                          >
                            <CiCircleMinus style={{ color: "red" }} size={20} />
                          </Button>
                        )}
                        <Button
                          onClick={handleAddnewMaterial}
                          sx={{ minWidth: 35 }}
                        >
                          <CiCirclePlus size={20} />
                        </Button>
                      </Box>
                    </BoxRow>
                  </>
                ))}
              </BoxColumn>
            </Grid>
          </Grid>
        </Form>
      </Box>
      {ResponsePostComponent.failAlert}
      {ResponsePostComponent.successAlert}
    </Container>
  );
};

export default Addnewcomponent;
