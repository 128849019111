import styled from "@emotion/styled";
import {Tab} from "@mui/material";
export const TabPanelStyle = styled(Tab)({
	"&.MuiButtonBase-root": {
		color: "var(--black-color, #202124)",
		fontFamily: "Cairo",
	},
	"&.MuiButtonBase-root.Mui-selected": {
		background: "var(--secondary-color, #FDB6B6)",
		borderRadius: "3px",
	},
});
