import React from "react";
import SearchComponent from "../../components/search-component";
import CustomContainer from "../../components/features/components/customContainer";

const columns = [
  { value: "select", name: "id", type: "checkbox", inputType: "text" },
  { value: "المسلسل", name: "id", type: "text", inputType: "text" },

  { value: "الكود ", name: "code", type: "text", inputType: "text" },
  {
    value: "الوظيفة",
    name: "job",
    type: "text",
    inputType: "text",
  },
  {
    value: "تاريخ الانشاء",
    name: "date",
    type: "text",
    inputType: "text",
  },
  { value: "الإجراءات", type: "option", name: "phone", inputType: "phone" },
];

const CustomerAccounts = () => {
  return (
    <>
      <SearchComponent />
      <CustomContainer
        title={"الحسابات"}
        //  length={{ length: users?.length, name: "عميل" }}
        columns={columns}
        // rows={users.map(user => user.user)}
        // onDelete={handleDelete}
        onEdit={() => {}}
      />
    </>
  );
};

export default CustomerAccounts;
