import {Box, Container, Grid} from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/features/components/Breadcrumbs";
import {MainTitle} from "../../style/buttom-style";
import Form from "../../components/features/components/Form";
import {BoxColumn, BoxRow} from "../../style/main-box";
import InputField from "../../components/features/components/InputField";
import NumberField from "../../components/features/components/NumberField";
import useControls from "./../../hooks/useControls";
import BASEURL from "../../data/Api";
import useRequest from "../../hooks/useRequest";
import { useNavigate, useParams } from "react-router-dom";

const AddJob = () => {
	const params = useParams();
	const [jobData, setJobData] = useState(null);
	const navigate = useNavigate();


	const [
		{controls, invalid, required},
		{setControl, resetControls, setInvalid, validate},
	] = useControls([
		{
			control: "name",
			value: "",
			isRequired: true,
		},
		{
			control: "id",
			value: "",
			isRequired: true,
		},
	]);



	const [RequestGetJob, ResponseGetJob] = useRequest({
		path: `${BASEURL}users/jobs/${params.id}`,
		method: "Get",
	  });

	const [ReuestPostJob, ResponsePostJob] = useRequest({
		method: "POST",
		path: `${BASEURL}users/jobs/`,
		successMessage: "تم إضافة مكون جديد بنجاح",
	  });
	  const [RequestPatchJob, ResponePatchJob] = useRequest({
		path: `${BASEURL}users/jobs/${params.id}/`,
		method: "PATCH",
	  });


	  useEffect(() => {
		if (params.id) {
		  handleViewJob(params.id);
		}
	  }, [params.id]);

	  const handleViewJob = (id) => {
		RequestGetJob({
		  onSuccess: (res) => {
			console.log(res);
			setJobData(res?.data); 
			setControl("id", res?.data?.id);
			setControl("name", res?.data?.name);

		  },
		  onError: (err) => {
			console.log(err.error);
		  },
		});
	  };
	

	  const handleSubmit = (e) => {
		e.preventDefault();
		validate().then(({ isOk }) => {
		  if (!isOk) return;
	
		  const requestBody = {
			id: controls.id,
			name: controls.name,
		  };
	
		  // If fields are invalid, do not submit
		  if (
			!requestBody.id ||
			!requestBody.name 
		  ) {
			return;
		  }
		  if(jobData){
			RequestPatchJob({
				body: requestBody,
				onSuccess: (res) => {
				  console.log("Component added successfully:", res);
				},
			  }).then((res) => {
				const response = res?.response?.data;
				setInvalid({
				  id: response?.id,
				  name: response?.name
				});
			  });
		  }
		  else{
			ReuestPostJob({
				body: requestBody,
				method: "POST",
				onSuccess: (res) => {
				  console.log("Component added successfully:", res);
				},
			  }).then((res) => {
				const response = res?.response?.data;
				setInvalid({
				  id: response?.id,
				  name: response?.name
				});
			  });
		  }
	
		});
	  };





	return (
		<Container maxWidth={"xl"}>
			<Breadcrumbs path={["الموظفين والوظائف",jobData ? "تعديل وظيفة" :" إضافة وظيفة"]} />
			<Box sx={{mb: 4}}>
				<MainTitle sx={{fontSize: "24px"}}>{jobData ? "تعديل وظيفة" :"إضافة وظيفة"}</MainTitle>
			</Box>
			<Form
				childrenProps={{
					saveBtn: {
						onClick: handleSubmit,
						disabled: ReuestPostJob.isPending,
						children:jobData ? "تعديل" :"إضافة"
					},
					closeBtn: {
						onClick: resetControls,
						disabled: ReuestPostJob.isPending,
					},
				}}
				sx={{height: "100%"}}>
				{" "}
				<Grid item>
					<Grid container gap={{md: 4, xs: 0}}>
						<Grid item md={9} xs={12}>
							<BoxColumn gap={2} width={"100%"}>
								<BoxRow gap={4} flexDirection={{xs: "column", md: "row"}}>
									<InputField
										variant={"outlined"}
										label="   اسم الوظيفه"
										placeholder=" اسم الوظيفه"
										value={controls.name}
										onChange={(e) => {
											let text = /^(?:[A-Za-z\u0600-\u06ff\s]*)$/;

											let match = text.test(e.target.value);

											if (match) {
												setControl("name", e.target.value);
											}
										}}
										required={required.includes("name")}
										textHelper={controls.name}
										error={Boolean(invalid.name)}
										helperText={invalid.name}
										sx={{width: "100%"}}
									/>
									<NumberField
										variant="outlined"
										placeholder="الكود"
										label="الكود*"
										value={controls.id}
										onChange={(e) => setControl("id", e.target.value)}
										required={required.includes("id")}
										textHelper={controls.id}
										error={Boolean(invalid.id)}
										helperText={invalid.id}
										sx={{width: "100%"}}
									/>
								</BoxRow>
							</BoxColumn>
						</Grid>
					</Grid>
				</Grid>
			</Form>
			{ResponsePostJob.failAlert}
			{ResponsePostJob.successAlert}
		</Container>
	);
};

export default AddJob;
