/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import {Box} from "@mui/system";
import {useRef} from "react";
import {useState} from "react";
import {ButtonAdd, SubTitle} from "../../../style/buttom-style";
import defaultImage from "../../../images/defaultImage/defaultImage.png";
const PictureField = ({
	description = "الحد الأقصى لحجم الملف : 5 ميجابايت",
	picture = "",
	paperProps = {},
	onChange = () => {},
	error = false,
	...rest
}) => {
	const [picturePath, setPicturePath] = useState("");

	const inputRef = useRef();

	const handlePaperClick = (e) => {
		inputRef.current.click();
	};

	const handleFileChange = (e) => {
		if (e?.target?.files[0]) {
			const path = URL?.createObjectURL(e?.target?.files[0]);
			setPicturePath(path);
			onChange(e?.target?.files[0]);
		}
	};
	return (
		<Box>
			<Box
				sx={{
					width: "100%",
					borderRadius: "4px",
					boxShadow: " 0px 2px 4px -2px #1018280F",
				}}>
				{/* <input
					type="file"
					accept="/*"
					ref={inputRef}
					onChange={handleFileChange}
					style={{display: "none"}}
				/> */}

				<Box
					sx={{
						width: "100%",
						height: "187px",
						borderRadius: "4px",
						boxShadow: " 0px 2px 4px -2px #1018280F",
						mb: "19px",
					}}>
					<input
						type="file"
						accept="image/png, image/gif, image/jpeg"
						ref={inputRef}
						onChange={handleFileChange}
						style={{display: "none"}}
					/>

					{Boolean(picturePath || defaultImage) && (
						<img
							src={picturePath || defaultImage}
							style={{
								width: "100%",
								height: "100%",
								objectFit: "cover",
								objectPosition: "center",
								backgroundColor: "white",
								borderRadius: "4px",
							}}
							alt="image components"
						/>
					)}
				</Box>
				<Box
					sx={{
						width: "100%",
						display: "flex",
						flexDirection: "column",
						gap: 1,
					}}>
					<ButtonAdd
						onClick={handlePaperClick}
						sx={{
							padding: "6px 16px 6px 16px !important",
							"&.MuiButton-root": {width: "100%"},
							fontFamily: "cairo",
						}}>
						{" "}
						اختيار من ملف
					</ButtonAdd>
					<SubTitle color={"#7F7F7F !important"} textAlign={"center"}>
						{description}
					</SubTitle>
				</Box>
			</Box>
		</Box>
	);
};

export default PictureField;
