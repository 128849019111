/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "../../../icons/EditIcon.svg";
import viewIcon from "../../../icons/viewIcon.svg";
import DeleteIcon from "../../../icons/DeleteIcon.svg";
import {
  Typography,
  Box,
  Link,
  MenuItem,
  FormControl,
  IconButton,
  Tooltip,
  CircularProgress,
  Container,
  Avatar,
} from "@mui/material";
import Select from "@mui/material/Select";
// import MenuFilter from "./menuFilter";
// import CustomModal from "./customModal";

import Checkbox from "@mui/material/Checkbox";

import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
// import CustomDatePicker from "./customDatePicker";
import { useDispatch, useSelector } from "react-redux";
import propTypes, { number } from "prop-types";
import MessageIcon from "@mui/icons-material/Message";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import EmailIcon from "@mui/icons-material/Email";

import { Gallery, Item } from "react-photoswipe-gallery";
import ReactPaginate from "react-paginate";
import { Stars } from "@mui/icons-material";
import {
  GridBadged,
  GridTitle,
  PaginationButton,
  TextBadged,
} from "../../../style/main";
import SortIcon from "../../../icons/SortIcon.svg";
import CheckedTable from "../../../icons/CheckedTable.svg";
import { BoxRow } from "../../../style/main-box";
import "../../../App.css";
import DetailsVeiw from "../../details-veiw/details-veiw";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const CustomContainer = ({
  title,
  length,
  chipFilter = false,
  rows = [],
  columns = [],
  count = 0,
  onEdit = (e, row) => {},
  onDelete = (e, row) => {},
  onView = (e, row) => {},
  onPaginate = (page) => {},
  RenderCell = () => {},
  addTask = false,
  table,
}) => {
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [rowData, setRowData] = React.useState(null);
  const [filter, setFilter] = React.useState("");
  const [filterIncome, setFilterIncom] = React.useState("");
  const [filterDate, setFilterDate] = React.useState(false);
  const [employee, setemployee] = React.useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openCareer, setOpenCareer] = useState(false);
  const [openEmployee, setOpenEmployee] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [isOpenLight, setIsOpenLight] = useState(false);
  const [expandedText, setExpandedText] = useState({
    status: false,
    index: "",
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: "16px 16px 0px 0px",
        marginBlock: "1rem",
        border: " 0.5px solid #D9D9D9",
        boxShadow: "0px 3.5px 5.5px 0px #00000005",
        background:
          "linear-gradient(0deg, #FFFFFF, #FFFFFF),linear-gradient(0deg, #EAECF0, #EAECF0)",
        fontFamily: "Cairo",
      }}
    >
      {/* <Container maxWidth="xl"> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",

          padding: "20px 24px 19px 24px",
          alignItems: "center",
          gap: "16px",
          alignSelf: "stretch",
        }}
      >
        <GridTitle>{title}</GridTitle>
        <GridBadged>
          <TextBadged>
            {length?.length} {length?.name}
          </TextBadged>
        </GridBadged>
      </Box>
      {/* </Container> */}
      {chipFilter && (
        <Box
          sx={{
            padding: "20px",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: 2,
          }}
        ></Box>
      )}

      <Table aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              " & th": {
                borderBottom: "1px solid  #EAECF0",
              },
            }}
          >
            {columns.map((ele, index) =>
              ele.type === "checkbox" ? (
                <TableCell
                  key={index}
                  align="center"
                  sx={{
                    background: "#F9FAFB",
                    borderBottom: " 1px solid  #EAECF0",
                    padding: "12px 24px",
                    width: "44px",
                  }}
                >
                  <Checkbox
                    {...label}
                    icon={
                      <Box
                        sx={{
                          width: "20px",
                          height: "20px",
                          borderRadius: "6px",
                          border: "1px solid  #D0D5DD",
                          background: " #FFF",
                        }}
                      />
                    }
                    checkedIcon={<img src={CheckedTable} alt="checked" />}
                  />
                </TableCell>
              ) : (
                <TableCell
                  key={index}
                  sx={{
                    // textAlign: "center",
                    padding: "12px 24px",
                    color: "var(--mp-red)",
                    alignSelf: "stretch",
                    borderBottom: " 1px solid  #EAECF0",
                    background: "#F9FAFB",
                  }}
                >
                  <BoxRow
                    gap={"5px"}
                    justifyContent={
                      ele.name === "name" ? "flex-start" : "center"
                    }
                  >
                    <img src={SortIcon} alt="sort" />
                    <Typography sx={{ fontFamily: "Cairo" }}>
                      {ele.value}
                    </Typography>
                  </BoxRow>
                </TableCell>
              )
            )}
            {/* {columns.map((ele, index) =>
                ()
             
            )} */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 ? (
            rows?.map((row, rowindex) => (
              <TableRow
                key={rowindex}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: "1px solid #efefef",
                  },
                  "& td, th": {
                    borderBottom: "1px solid  #EAECF0",
                  },
                }}
              >
                {columns.map((col, index) =>
                  col.type === "text" ? (
                    <TableCell
                      key={`${index}-${col.id}`}
                      style={{
                        height: "44px",
                        padding: "12px 24px",
                        textAlign: "center",
                        alignSelf: "stretch",
                        borderBottom: "1px solid  #EAECF0",
                        color: col.color || "#667085",
                        fontSize: col.size || "14px",

                        fontWeight: "400",
                        lineHeight: "20px",
                        background:
                          row[col.name] === "Working on it"
                            ? "#FDAB3D"
                            : row[col.name] === "Done"
                            ? "#00C875"
                            : row[col.name] === "Stuck"
                            ? "#E2445C"
                            : "#fff",
                      }}
                    >

                      {row[col?.name] && Array.isArray(row[col?.name])
                        ? row[col?.name].map((ele) => ele[col?.main]).join(",")
                        : row[col?.name] && typeof row[col?.name] === "object"
                        ? row[col?.name][col?.main]
                        : String(row[col?.name]).slice(0, 50)}
                      {col.name === "summary" &&
                        expandedText.status &&
                        expandedText.index == rowindex &&
                        row[col.name].slice(51, 500)}
                      {col.name === "summary" && (
                        <Typography
                          sx={{ cursor: "pointer" }}
                          onClick={(e) =>
                            setExpandedText({
                              status: !expandedText.status,
                              index: rowindex,
                            })
                          }
                        >
                          {expandedText.status && expandedText.index == rowindex
                            ? "...less more"
                            : "...see more"}
                        </Typography>
                      )}
                    </TableCell>
                  ) : col.type === "object" ? (
                    <TableCell
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 2,
                        color: col.color || "#282A3C",
                        fontSize: col.size || "16px",
                        fontFamily: "Tajawal",
                        fontWeight: "400",
                        background:
                          row[col.name] === "Working on it"
                            ? "#FDAB3D"
                            : row[col.name] === "Done"
                            ? "#00C875"
                            : row[col.name] === "Stuck"
                            ? "#E2445C"
                            : "",
                      }}
                      align="center"
                    >
                      <object data={row[col?.name]} type="application/pdf" />
                      <a href={row[col?.name]} target="_blank">
                        <CloudUploadIcon />
                      </a>

                      <EmailIcon
                        onClick={(e) => {
                          setOpenEmail(true);
                          setRowData({ ...row });
                        }}
                      />
                    </TableCell>
                  ) : col.type === "rating" ? (
                    <TableCell align="center">
                      <Typography
                        variant="span"
                        sx={{
                          m: "10px",
                          display: "inline-block",
                          color: col.color || "#ffcb21",
                          fontSize: col.size || "16px",
                          fontFamily: "Tajawal",
                          fontWeight: "400",
                        }}
                      >
                        <Stars stars={row[col.name]} />
                      </Typography>
                    </TableCell>
                  ) : col.type === "link" ? (
                    <TableCell align="center">
                      {row[col.name]?.image && (
                        // <img
                        //   src="https://source.unsplash.com/gySMaocSdqs/180x180"
                        //   alt={row[col.name]}
                        //   m="0 10px"
                        //   width="43px"
                        //   height="43"
                        //   className="rounded-circle"
                        // />
                        <img
                          src={
                            "https://source.unsplash.com/gySMaocSdqs/180x180"
                          }
                          alt={row[col.name]}
                          width="46"
                          height="43"
                          className="rounded-circle"
                        />
                      )}
                      {row[col.name]?.name && (
                        <Link
                          variant="span"
                          sx={{
                            m: "10px",
                            display: "inline-block",
                            color: col.color || "#282A3C",
                            fontSize: col.size || "16px",
                            fontFamily: "Tajawal",
                            fontWeight: "400",
                          }}
                          href={row[col.name].to}
                        >
                          {row[col.name].name}
                        </Link>
                      )}
                    </TableCell>
                  ) : col.type === "checkbox" ? (
                    <TableCell align="center">
                      {row[col.name] === true ? (
                        <Checkbox
                          {...label}
                          icon={
                            <Box
                              sx={{
                                width: "20px",
                                height: "20px",
                                borderRadius: "6px",
                                border: "1px solid  #D0D5DD",
                                background: " #FFF",
                              }}
                            />
                          }
                          checkedIcon={<img src={CheckedTable} alt="checked" />}
                        />
                      ) : (
                        <Checkbox
                          {...label}
                          icon={
                            <Box
                              sx={{
                                width: "20px",
                                height: "20px",
                                borderRadius: "6px",
                                border: "1px solid  #D0D5DD",
                                background: " #FFF",
                              }}
                            />
                          }
                          checkedIcon={<img src={CheckedTable} alt="checked" />}
                        />
                      )}
                    </TableCell>
                  ) : col.type === "option" ? (
                    <TableCell
                      align="center"
                      sx={{ display: "flex", justifyContent: "center", gap: 1 }}
                    >
                      {onView && (
                        <IconButton
                          sx={{
                            backgroundColor: "none",
                            color: "RemoveRedEyeIcon",
                          }}
                          onClick={(event) => {
                            event.stopPropagation();
                            onView(event, row);
                            // setRowData({ ...row });
                            // setOpen(true);
                          }}
                        >
                          <Tooltip arrow title="view" placement="top-start">
                            <img
                              src={viewIcon}
                              alt="alt"
                              sx={{ width: 20, height: 20 }}
                            />
                          </Tooltip>
                        </IconButton>
                      )}
                      {onEdit && (
                        <IconButton
                          sx={{ backgroundColor: "none", color: "#000" }}
                          onClick={(event) => {
                            event.stopPropagation();
                            setRowData({ ...row });
                            // eslint-disable-next-line no-lone-blocks
                            {
                              table === "career"
                                ? setOpenCareer(true)
                                : table === "employee"
                                ? setOpenEmployee(true)
                                : onEdit(event, row)
                                  setOpenDialog(true);
                            }
                          }}
                        >
                          <Tooltip arrow title="edit" placement="top-start">
                            <img
                              src={EditIcon}
                              sx={{ width: 20, height: 20 }}
                            />
                          </Tooltip>
                        </IconButton>
                      )}

                      {onDelete && (
                        <IconButton
                          sx={{ backgroundColor: "none", color: "red" }}
                          onClick={(event) => {
                            event.stopPropagation();
                            onDelete(event, row);
                          }}
                        >
                          <Tooltip arrow title="Delete" placement="top-start">
                            <img
                              src={DeleteIcon}
                              alt="delete"
                              sx={{ width: 20, height: 20 }}
                            />
                          </Tooltip>
                        </IconButton>
                      )}

                      {addTask && (
                        <IconButton
                          sx={{ backgroundColor: "#F3E6D8", color: "red" }}
                          onClick={(event) => {
                            event.stopPropagation();
                            setRowData({ ...row });
                            setOpenModal(true);
                          }}
                        >
                          <Tooltip arrow title="Comment" placement="top-start">
                            <MessageIcon sx={{ width: 20, height: 20 }} />
                          </Tooltip>
                        </IconButton>
                      )}
                    </TableCell>
                  ) : col.type === "renderCell" ? (
                    <TableCell
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: 1,
                        width: "200px",
                      }}
                    >
                      {col.RenderCell && col.RenderCell(row)}
                    </TableCell>
                  ) : (
                    <TableCell align="center">
                      {/* <img src={person1} alt={row[col.name]} /> */}
                      {row[col.name] &&
                        row[col.name]?.map((test, index) => {
                          return (
                            <Gallery key={test.id}>
                              {/* <Image
                                key={test.id}
                                src={test?.image === null ? person1 : test?.image}
                                alt={test.id}
                                width={40}
                                height={40}
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  margin: "0 10px",
                                }}
                              /> */}

                              <Item
                                original={test?.image}
                                thumbnail={test?.image}
                                width={752}
                                height={450}
                              >
                                {({ ref, open }) => (
                                  <img
                                    ref={ref}
                                    onClick={open}
                                    src={test?.image}
                                    width={40}
                                  />
                                )}
                              </Item>
                            </Gallery>
                          );
                        })}

                      <Typography
                        variant="span"
                        sx={{
                          m: "10px",
                          display: "inline-block",
                          color: col.color || "#282A3C",
                          fontSize: col.size || "16px",
                          fontFamily: "Tajawal",
                          fontWeight: "400",
                        }}
                      >
                        {row[col.name]}
                      </Typography>
                    </TableCell>
                  )
                )}
              </TableRow>
            ))
          ) : (
            <TableCell colSpan={10}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  width: "100%",
                  height: "50vh",
                }}
              >
                <CircularProgress />
              </Box>
            </TableCell>
          )}
          <TableRow
            centered
            // sx={{
            //   "&:last-child td, &:last-child th": {
            //     border: "1px solid #efefef",
            //   },
            //   "& td, th": {
            //     border: "1px solid #efefef",
            //     color: "red",
            //   },
            // }}
          >
            <TableCell colSpan={10}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <TablePagination
                  onChange={(page) => {
                    onPaginate(page);
                  }}
                  rows={rows}
                  count={count}
                />
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {addTask && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Box sx={{ padding: "20px", cursor: "pointer" }} onClick={handleOpen}>
            <Typography
              variant="span"
              sx={{
                background: "#ED1B241A",
                borderRadius: "5px",
                padding: "0 5px",
                display: "inline-block",
              }}
            >
              {/* <img src={plus} alt="square plus" /> */}
              <img src={""} alt="square plus" width="" height="" />
              <Typography
                variant="span"
                sx={{ display: "inline-block", margin: "2px 10px" }}
              >
                Add Task
              </Typography>
            </Typography>
          </Box>
        </Box>
      )}
      <DetailsVeiw  rowData={rowData}   rows={rows} open={open} onClose={handleClose} />
    </TableContainer>
  );
};

export default CustomContainer;
CustomContainer.propTypes = {
  columns: propTypes.arrayOf(
    propTypes.shape({
      RenderCell: propTypes.func,
    })
  ),
  rows: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.oneOfType([propTypes.string, propTypes.number]),
    })
  ),
  onEdit: propTypes.func,
  onDelete: propTypes.func,
  onView: propTypes.func,
  onPaginate: propTypes.func,
};

const TablePagination = ({ current = 1, rows, count, onChange = () => {} }) => {
  const [page, setPage] = useState(current);
  const [filterData, setFilterData] = useState();
  const n = 9;
  const handlePageChange = (selected) => {
    const newPage = selected.selected + 1;
    setPage(newPage);
  };
  useEffect(() => {
    onChange(page);

    setFilterData(
      rows?.filter((item, index) => {
        return (index >= page * n) & (index < (page + 1) * n);
      })
    );
  }, [page]);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
          flexDirection: "row",
        }}
      >
        {/* <ul style={{ listStyle: "none", listStyleType: "none", display: "inline-flex", margin: "0", padding: "0" }}>
    {filterData &&
      filterData.map((item, index) => (
        <li key={index} style={{ marginRight: "10px" }}>
          {item}
        </li>
      ))}
  </ul> */}
        {/* Math?.ceil(count / n) */}
        <ReactPaginate
          containerClassName={"pagination"}
          pageClassName={"page-item"}
          activeClassName={"active"}
          onPageChange={handlePageChange}
          pageCount={2}
          nextAriaLabel="من"
          breakLabel={"إلي"}
          breakAriaLabels={{ forward: "من", backward: "الى" }}
          previousLabel={<PaginationButton>السابق</PaginationButton>}
          nextLabel={<PaginationButton>التالي</PaginationButton>}
          marginPagesDisplayed={1}
          forcePage={page - 1}
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
};
