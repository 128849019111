import React, { useEffect } from "react";
import SearchComponent from "../../components/search-component";
import CustomContainer from "../../components/features/components/customContainer";
import useRequest from "../../hooks/useRequest";
import BASEURL from "../../data/Api";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, Typography } from "@mui/material";
import useConfirmMessage from "../../hooks/useConfirmMessage";
import { useNavigate } from "react-router-dom";

const AllComponents = () => {
  const dispatch = useDispatch();
  const components = useSelector((state) => state.components.value.results);
  const token = useSelector((state) => state.userInfo.value.access);
  const navigate = useNavigate();
  const columns = [
    { value: "المسلسل", name: "id", type: "checkbox", inputType: "text" },
    { value: "الكود", name: "id", type: "text", inputType: "text" },
    { value: "اسم الخلطة", name: "name", type: "text", inputType: "text" },
    { value: "الوزن", name: "weight", type: "text", inputType: "text" },
    { value: "الإجراءات", type: "option", name: "phone", inputType: "phone" },
  ];

  const [ReuestGetComponents, ResponseGetComponents] = useRequest({
    method: "Get",
    path: `${BASEURL}products/mixtures`,
    Authorization: `JWT ${token}`,
  });

  const [ReuestDeleteComponents, ResponseDeleteComponents] = useRequest({
    method: "DELETE",
    path: `${BASEURL}products/mixtures`,
  });

  const getComponents = () => {
    ReuestGetComponents({
      onSuccess: (res) => {
        dispatch({ type: "components/set", payload: res.data });
      },
    });
  };

  const DeleteComponents = (row) => {
    ReuestDeleteComponents({
      customPath: `products/mixtures/${row?.id}`,
      onSuccess: (res) => {
        dispatch({ type: "components/deleteItem", payload: { id: row?.id } });
      },
    });
  };

  // delete popup message before delete
  const [handleDelete, deleteComponentConfirmDialog] = useConfirmMessage({
    onConfirm: DeleteComponents,
    text: "هل انت متأكد من انك تريد حذف هذا المكون",
  });

  const handleEdit = (row) => {
    navigate("/components/addnewcomponent", {
      state: {
        keys: {
          id: row?.id,
        },
      },
    });
  };

  useEffect(() => {
    getComponents();
  }, []);

  let formatedComponents = [];

  if (components.length) {
    formatedComponents = components?.map((component) => {
      return {
        id: component.id,
        name: component.name,
        weight: component?.mixture_materials[0]?.weight,
      };
    });
  }
  return (
    <>
      <SearchComponent />
      <CustomContainer
        title={"المكونات"}
        length={{ length: formatedComponents?.length, name: "مكون" }}
        columns={columns}
        rows={formatedComponents}
        onDelete={(e, row) => handleDelete(row)}
        onEdit={(e, row) => handleEdit(row)}
        onView={(e,row) => console.log(row)}
      />
      {deleteComponentConfirmDialog}
    </>
  );
};

export default AllComponents;
