import React, { useState } from "react";
import useControls from "../../hooks/useControls";
import InputField from "../../components/features/components/InputField";
import { BoxColumn, BoxRow } from "../../style/main-box";
import NumberField from "../../components/features/components/NumberField";
import { Box, Container, Divider, Grid, MenuItem } from "@mui/material";
import PictureField from "../../components/features/components/PictureField";
import Form from "../../components/features/components/Form";
import ImageAlbums from "../../components/features/components/imageAlbums";
import { MainTitle } from "../../style/buttom-style";
import Breadcrumbs from "../../components/features/components/Breadcrumbs";
import SelectValue from "../../components/features/components/SelectValue";
import SelectField from "./../../components/features/components/SelectField";
import { AccessTimeIcon } from "@mui/icons-material/AccessTime";
import BASEURL, { PRODUCT, PRODUCTS } from "../../data/Api";
import useRequest from "../../hooks/useRequest";
import { useDispatch, useSelector } from "react-redux";
import filter from "../../utils/ClearNull";
import { useNavigate } from "react-router-dom";
import TableEdit from "../../components/features/components/TableEdit";
import { useEffect } from "react";
const AddNewAssembly = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const components = useSelector((state) => state.components.value);
  const products = useSelector((state) => state.products.value);
  const [editedRows, setEditedRows] = useState([]);
  const [Data, setData] = useState([]);

  // Function to receive the updated row from the child component
  const handleRowUpdate = (updatedRow) => {
    setEditedRows(updatedRow);
  };
  // get component
  const [RequestGetComponents, ResponseGetComponents] = useRequest({
    method: "GET",
    path: `${BASEURL}components/`,
  });
  const getComponents = () => {
    RequestGetComponents({
      onSuccess: (res) => {
        dispatch({ type: "components/set", payload: res.data });
      },
    });
  };

  // get products
  const [RequestGetProducts, ResponseGetProducts] = useRequest({
    method: "GET",
    path: PRODUCTS,
  });
  const getProducts = () => {
    RequestGetProducts({
      onSuccess: (res) => {
        dispatch({ type: "products/set", payload: res.data });
      },
    });
  };
  // post new product
  const [RequestPostProduct, ResponsePostProduct] = useRequest({
    method: "PATCH",
    path: PRODUCTS,
  });
  const [RequestPostProductComponent, ResponsePostProductComponent] =
    useRequest({
      method: "POST",
      path: PRODUCT,
    });

  const columns = [
    { name: "id", type: "text", inputType: "text" },
    {
      name: "name",
      type: "text",
    },
    { name: "code", type: "text", inputType: "text" },
    { name: "weight", type: "editAble", inputType: "text" },
    { name: "quantity", type: "editAble", inputType: "text" },
  ];

  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "products",
      value: "",
      isRequired: false,
    },
    {
      control: "name",
      value: "",
      isRequired: false,
    },
    {
      control: "code",
      value: 0,
      isRequired: false,
    },
    {
      control: "quantity",
      value: 0,
      isRequired: false,
    },
    {
      control: "components",
      value: [],
      isRequired: false,
    },
    {
      control: "product",
      value: "",
      isRequired: false,
    },
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    validate().then(({ isOk }) => {
      if (!isOk) return;

      const requestBody = filter({
        obj: {
          code:
            controls.code ||
            products?.results?.find(
              (product) => product?.id === controls?.product
            )?.code,
          name:
            controls.name ||
            products?.results?.find(
              (product) => product?.id === controls?.product
            )?.name,
          quantity:
            controls.quantity ||
            products?.results?.find(
              (product) => product?.id === controls?.product
            )?.quantity,
        },
        output: "object",
      });

      if (
        Object.keys(
          filter({
            obj: {
              code:
                controls.code ||
                products?.results?.find(
                  (product) => product?.id === controls?.product
                )?.code,
              name:
                controls.name ||
                products?.results?.find(
                  (product) => product?.id === controls?.product
                )?.name,
              quantity:
                controls.quantity ||
                products?.results?.find(
                  (product) => product?.id === controls?.product
                )?.quantity,
            },
          })
        ).length <= 1
      )
        return;

      RequestPostProduct({
        id: controls?.product,
        body: requestBody,
        onSuccess: (res) => {
          console.log(res);
          RequestPostProductComponent({
            id: res?.data?.id + "/components",
            body: filter({
              obj: {
                components: Data?.map((obj) => ({
                  component: obj?.id,
                  quantity: obj?.quantity,
                  weight: obj?.weight,
                })),
              },
              output: "object",
            }),
            onSuccess: () => {
              resetControls();
              navigate("/products");
            },
          });
        },
      }).then((res) => {
        const response = res?.response?.data;

        const responseBody = filter({
          obj: {
            code: response?.code?.join(""),
            name: response?.name?.join(""),
            source: response?.source?.join(""),
          },
        });
        setInvalid(responseBody);
      });
    });
  };

  useEffect(() => {
    setData(
      products?.results?.find((obj) => obj.id === controls.product)
        ?.product_component
    );
  }, [controls.product]);

  useEffect(() => {
    setData([
      ...Data,
      ...products?.results?.filter((obj) =>
        controls.components.includes(obj.id)
      ),
    ]);
  }, [controls.components]);
  console.log(Data);
  return (
    <Container maxWidth="xl" sx={{ mt: { md: 0, xs: 4 } }}>
      <Breadcrumbs path={["التجميع", " تجميع جديد"]} />
      <Box sx={{ mb: 2 }}>
        <MainTitle sx={{ fontSize: "24px" }}> تجميع منتج جديد</MainTitle>
      </Box>
      <Form
        childrenProps={{
          saveBtn: {
            onClick: handleSubmit,
            disabled: ResponsePostProduct.isPending,
          },
          closeBtn: {
            onClick: resetControls,
            disabled: ResponsePostProduct.isPending,
          },
        }}
        sx={{ height: "100%" }}
      >
        <Grid item>
          <Grid container spacing={{ md: 4, xs: 0 }}>
            <Grid item md={12} xs={12}>
              <BoxColumn gap={2} width={"100%"}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignSelf: "flex-start",
                    width: { md: "60%", xs: "100%" },
                  }}
                >
                  <SelectField
                    variant="outlined"
                    placeholder=" اختر"
                    label="سبق تجميعها"
                    sx={{ width: { md: "51%", xs: "100%" } }}
                    onOpen={getProducts}
                    value={controls.product}
                    renderValue={(selected) => {
                      return products.results.find(
                        (products) => products.id === selected
                      )?.name;
                    }}
                    onChange={(e) => setControl("product", e.target.value)}
                    isPending={ResponseGetProducts.isPending}
                  >
                    {products?.results?.map((product) => {
                      return (
                        <MenuItem key={product?.id} value={product?.id}>
                          {product?.name}
                        </MenuItem>
                      );
                    })}
                  </SelectField>
                </Box>
                <BoxRow gap={4} flexDirection={{ xs: "column", md: "row" }}>
                  <InputField
                    variant={"outlined"}
                    label=" اسم المنتج* "
                    value={
                      controls.name ||
                      products?.results?.find(
                        (product) => product?.id === controls?.product
                      )?.name
                    }
                    onChange={(e) => {
                      // let text = /^(?:[A-Za-z\u0600-\u06ff\s]*)$/;

                      // let match = text.test(e.target.value);

                      // if (match) {
                      setControl("name", e.target.value);
                      // }
                    }}
                    required={required.includes("name")}
                    textHelper={controls.name}
                    error={Boolean(invalid.name)}
                    helperText={invalid.name}
                    sx={{ width: "100%" }}
                  />
                  <NumberField
                    variant="outlined"
                    placeholder="الكود"
                    label="الكود"
                    thousandSeparator
                    value={
                      controls.code ||
                      products?.results?.find(
                        (product) => product?.id === controls?.product
                      )?.code
                    }
                    onChange={(e) => setControl("code", e.target.value)}
                    required={required.includes("code")}
                    textHelper={controls.code}
                    error={Boolean(invalid.code)}
                    helperText={invalid.code}
                    sx={{ width: "100%" }}
                  />

                  <NumberField
                    variant="outlined"
                    placeholder="العدد"
                    label="العدد*"
                    thousandSeparator
                    value={controls.quantity}
                    onChange={(e) => setControl("quantity", e.target.value)}
                    required={required.includes("quantity")}
                    textHelper={controls.quantity}
                    error={Boolean(invalid.quantity)}
                    helperText={invalid.quantity}
                    sx={{ width: "100%" }}
                  />
                </BoxRow>
              </BoxColumn>
              <Box sx={{ marginTop: "26px" }}>
                <Divider />
              </Box>
            </Grid>
            <Grid md={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignSelf: "flex-start",
                  width: { md: "60%", xs: "100%" },
                }}
              >
                <SelectField
                  multiple
                  isPending={ResponseGetComponents.isPending}
                  variant="outlined"
                  placeholder="اختر المكون "
                  label="المكون"
                  thousandSeparator
                  onOpen={getComponents}
                  value={controls.components}
                  renderValue={(selected) => {
                    const selectedComponets = selected?.map((id) =>
                      components?.results.find(
                        (component) => component?.id === id
                      )
                    );
                    return selectedComponets
                      ?.map((component) => component?.name)
                      .join(",");
                  }}
                  onChange={(e) => {
                    setControl("components", e.target.value);
                    console.log(e.target.value);
                  }}
                  required={required.includes("components")}
                  error={Boolean(invalid.components)}
                  helperText={invalid.components}
                  sx={{ width: { md: "50%", xs: "100%" } }}
                >
                  {components?.results?.map((component) => (
                    <MenuItem key={component?.id} value={component.id}>
                      {component?.name}
                    </MenuItem>
                  ))}
                </SelectField>
              </Box>
              <Box mt={"24px"}>
                {controls?.components?.length > 0 || controls.product !== "" ? (
                  <TableEdit
                    // rows={controls?.materials?.map((item) => {
                    // 	const selectedItem=materials?.results?.find((material)=>material?.id===item)
                    // 	return selectedItem
                    // })}
                    rows={Data}
                    columns={columns}
                    editedRows={editedRows}
                    onUpdate={(row) => handleRowUpdate(row)}
                  />
                ) : (
                  <></>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Container>
  );
};

export default AddNewAssembly;
