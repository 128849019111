import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import StatisticsCard from "./statistics";
import TrafficChart from "./trafficChart";
import ProgressBar from "./progress-bar";

const Clients = () => { 
	return <>
		   <Grid
        container
        spacing={2.2}
        justifyContent={"space-between"}
        sx={{ margin: { md: "1% 0", xs: "6% 0 " } }}
      >
        <StatisticsCard heading={"جميع العملاء"} number={"236"} />
        <StatisticsCard heading={"عملاء جدد"} number={"56"} />
        <StatisticsCard heading={"عملاء نشطة"} number={"96"} />
        <StatisticsCard heading={"عملاء القطاعي"} number={"20"} />
        <StatisticsCard heading={"عملاء الجملة"} number={"56"} />
        <StatisticsCard heading={"عملاء معلقه"} number={"4"} />
      </Grid>

      <Grid
        container
        justifyContent={"space-between"}
        sx={{ margin: { md: "1% 0", xs: "6% 0" } }}
      >
        <Grid item border={"1px solid #EBEFF2"} borderRadius={1} md={5.9}>
          <Box>
            <Typography padding={"15px"}>تحليل بيانات طلبات العملاء</Typography>
          </Box>
          <Divider width={"100%"} />
          <Grid container padding={"15px"}>
            <TransactionType md={3} color={"#FF3A29"}>
              الغاء
            </TransactionType>
            <TransactionType md={3} color={"#02A0FC"}>
              طلب
            </TransactionType>
            <TransactionType md={3} color={"#4339F2"}>
              استرجاع
            </TransactionType>
            <TransactionType md={3} color={"#FFB200"}>
              تم الشراء
            </TransactionType>
          </Grid>

          <Box padding={"15px"} width={"100%"}>
            <ProgressBar
              text={"تم الشراء"}
              amount={"650000"}
              color={"#FFB200"}
            />
            <ProgressBar text={"استرجاع"} amount={"650000"} color={"#4339F2"} />
            <ProgressBar text={"طلب"} amount={"650000"} color={"#02A0FC"} />
            <ProgressBar text={"الغاء"} amount={"650000"} color={"#FF3A29"} />
          </Box>

          <Box>
            <Typography padding={"15px"}>من اين عرفنا العملاء</Typography>
          </Box>
          <Divider width={"100%"} />

          <Box width={"100%"} padding={"15px"}>
            <Grid container justifyContent={"start"}>
              <TransactionType md={3} color={"#6360A3"}>
                فيس بوك
              </TransactionType>
              <TransactionType md={3} color={"#779A79"}>
                الموقع الالكتروني
              </TransactionType>
              <TransactionType md={3} color={"#D6A73D"}>
                اخري
              </TransactionType>
            </Grid>
          </Box>

          <Box width={"100%"}>
            <TrafficChart />
          </Box>
        </Grid>

        <Grid item border={"1px solid #EBEFF2"} borderRadius={1} md={5.9}>
          <Box>
            <Typography padding={"15px"}>نشاط العملاء</Typography>
          </Box>
          <Divider width={"100%"} />
          <Grid item padding={"15px"}>
            <TransactionType md={3} color={"#02A0FC"}>
              عملاء جدد
            </TransactionType>
          </Grid>

          <Box width={"100%"}>
            <TrafficChart />
          </Box>
        </Grid>
      </Grid>
	</>;
};

export default Clients;




export function TransactionType({ children, color, ...props }) {
	return (
	  <Grid item {...props} display={"flex"} alignItems={"center"} gap={1}>
		<Box
		  width={"12px"}
		  height={"12px"}
		  border={`2px solid ${color} `}
		  borderRadius={"50%"}
		/>
		<Typography fontWeight={400} fontSize={"14px"} color={"#000000"}>
		  {children}
		</Typography>
	  </Grid>
	);
  }
  