import {Box, IconButton} from "@mui/material";
import React, {useRef, useState} from "react";
import defaultImage from "../../../images/camera/Camera.png";
const PictureButton = (
	{picture = ""},
	paperProps = {},
	onChange = () => {},
	error = false,
	...rest
) => {
	const [picturePath, setPicturePath] = useState("");

	const inputRef = useRef();
	const handlePaperClick = (e) => {
		inputRef.current.click();
	};
	const handleFileChange = (e) => {
		if (e?.target?.files[0]) {
			const path = URL?.createObjectURL(e?.target?.files[0]);
			setPicturePath(path);
			onChange(e?.target?.files[0]);
		}
	};
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				// padding: "4px 12px",
				width: "100%",
				height: "100%",
			}}>
			<input
				type="file"
				accept="image/png, image/gif, image/jpeg"
				ref={inputRef}
				onChange={handleFileChange}
				style={{display: "none"}}
			/>
			{picturePath ? (
				<img
					src={picturePath}
					style={{
						objectFit: "cover",
						objectPosition: "center",
						backgroundColor: "white",
						borderRadius: "4px",
						width: "100%",
						height: "267px",
					}}
					alt="img components"
				/>
			) : (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						borderRadius: "5px",
						boxSizing: "border-box",
						width: "21%",
						height: "71px",
						boxShadow: " 0px 2px 10px 0px rgba(124, 141, 181, 0.12)",
					}}>
					<IconButton onClick={handlePaperClick}>
						<img src={defaultImage} alt="img components" />
					</IconButton>
				</Box>
			)}
		</Box>
	);
};

export default PictureButton;
