import { Box, Container, Grid, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import NumberField from "../../components/features/components/NumberField";
import { MainTitle } from "../../style/buttom-style";
import useControls from "../../hooks/useControls";
import InputField from "../../components/features/components/InputField";
import { BoxColumn, BoxRow } from "../../style/main-box";
import Breadcrumbs from "../../components/features/components/Breadcrumbs";
import Form from "../../components/features/components/Form";
import SelectField from "../../components/features/components/SelectField";
import useRequest from "../../hooks/useRequest";
import BASEURL from "../../data/Api";
import { useDispatch, useSelector } from "react-redux";
import { CiCircleMinus, CiCirclePlus } from "react-icons/ci";
import { useNavigate, useParams } from "react-router-dom";

const AddRawMaterial = () => {
  const materials = useSelector((state) => state.materials.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  
  // State to store fetched material data for editing
  const [materialData, setMaterialData] = useState(null);

  // Request hooks for POST and GET operations
  const [ReuestPostMaterials, ResponsePostMaterials] = useRequest({
    method: "POST",
    path: `${BASEURL}products/materials/`,
    successMessage: "تم إضافة مادة خام جديد بنجاح",
  });

  const [RequestGetMaterial, ResponseGetMaterial] = useRequest({
    path: `${BASEURL}products/materials/${params.id}`,
    method: "Get",
  });
  
  const [RequestPatchMaterial, ResponePatchMaterial] = useRequest({
    path: `${BASEURL}products/materials/${params.id}/`,
    method: "PATCH",
  });

  // Fetch material data if there's an id in the params (edit mode)
  useEffect(() => {
    if (params.id) {
      handleViewMaterial(params.id);
    }
  }, [params.id]);

  const handleViewMaterial = (id) => {
    RequestGetMaterial({
      onSuccess: (res) => {
        console.log(res);
        setMaterialData(res?.data); 
        setControl("id", res?.data?.id);
        setControl("material", res?.data?.material);
        setControl("weight", res?.data?.weight);
      },
      onError: (err) => {
        console.log(err.error);
      },
    });
  };

  // Form controls setup
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    { control: "id", value: "", isRequired: true },
    { control: "material", value: "", isRequired: true },
    { control: "weight", value: "", isRequired: true },
  ]);

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    validate().then(({ isOk }) => {
      if (!isOk) return;

      const requestBody = {
        id: controls.id,
        material: controls.material,
        weight: controls.weight,
      };

      // If fields are invalid, do not submit
      if (!requestBody.id || !requestBody.material || !requestBody.weight) {
        return;
      }

    

      // Submit either for add or update based on presence of material data
      if (materialData) {
        RequestPatchMaterial({
          body: requestBody,
          onSuccess: (res) => {
            console.log("Material updated successfully:", res);
            navigate("/stores"); // Redirect after update
          },
        });
      } else {
        // Add new material (new mode)
        ReuestPostMaterials({
          body: requestBody,
          onSuccess: (res) => {
            console.log("Material added successfully:", res);
            navigate("/stores"); // Redirect after adding
          },
        }).then((res) => {
          const response = res?.response?.data;
          setInvalid({
            id: response?.id,
            material: response?.material,
            weight: response?.weight,
          });
        });
      }
    });
  };

  return (
    <Container maxWidth="xl">
      <Box>
        <Breadcrumbs path={["مواد", "إضافة مادة خام جديد"]} />
        <Box sx={{ mb: "32px" }}>
          <MainTitle sx={{ fontSize: "24px" }}>
            {materialData ? "تعديل مادة خام" : "إضافة مادة خام جديدة"}
          </MainTitle>
        </Box>

        <Form
          childrenProps={{
            saveBtn: {
              onClick: handleSubmit,
              children:`${materialData ? "تعديل " :"إضافة"}`,
              disabled: ResponsePostMaterials.isPending,
              mode:"edit"
            },
            closeBtn: {
              onClick: resetControls,
              disabled: ResponsePostMaterials.isPending,
            },
          }}
        >
          <Grid container spacing={{ md: 4, xs: 0 }}>
            <Grid item md={12} xs={12}>
              <BoxColumn gap={2} width={"100%"}>
                <BoxRow gap={"26px"} flexDirection={{ xs: "column", md: "row" }}>
                  <InputField
                    variant="outlined"
                    label="الكود*"
                    value={controls.id}
                    onChange={(e) => setControl("id", e.target.value)}
                    required={required.includes("id")}
                    error={Boolean(invalid?.id)}
                    helperText={invalid?.id}
                    sx={{ width: "100%" }}
                  />

                  <InputField
                    variant="outlined"
                    label="اسم المادة الخام*"
                    value={controls.material}
                    onChange={(e) => setControl("material", e.target.value)}
                    required={required.includes("material")}
                    error={Boolean(invalid?.material)}
                    helperText={invalid?.material}
                    sx={{ width: "100%" }}
                  />
                </BoxRow>
                <BoxRow gap={"26px"} flexDirection={{ xs: "column", md: "row" }}>
                  <Box width={"100%"}>
                    <InputField
                      variant="outlined"
                      label="وزن الرصيد الافتتاحي*"
                      value={controls.weight}
                      onChange={(e) => setControl("weight", e.target.value)}
                      required={required.includes("weight")}
                      error={Boolean(invalid?.weight)}
                      helperText={invalid?.weight}
                      sx={{ width: "100%" }}
                    />
                  </Box>
                  <Box width={"100%"}></Box>
                </BoxRow>
              </BoxColumn>
            </Grid>
          </Grid>
        </Form>
      </Box>
      {ResponsePostMaterials.failAlert}
      {ResponsePostMaterials.successAlert}
      {ResponePatchMaterial.successAlert}
      {ResponePatchMaterial.failAlert}
    </Container>
  );
};

export default AddRawMaterial;
