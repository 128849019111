import React from "react";
import { Box, TextField } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { SubTitle } from "../../../style/buttom-style";

const NumberField = ({ variant, onChange, label, ...rest }) => {
  const handleValueChange = (values) => {
    onChange({ target: { ...values } });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        width: "100%",
      }}
    >
      <SubTitle>{label}</SubTitle>
      <NumericFormat
        customInput={TextField}
        variant={Boolean(variant) ? variant : "standard"}
        onValueChange={handleValueChange}
        {...rest}
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-root": {
            height: "32px !important",
            background: "#F7F8FA",
            borderColor: "#C9CDD4",
            ":hover": {
              border: "1px solid #1B53C5 !important",
            },
            ":focus-within": {
              border: "1px solid #1B53C5 !important",
              background: "#fff",
            },
          },
          "& .MuiOutlinedInput-root.Mui-disabled": {
            ":hover": {
              border: "1px solid #1B53C5 !important",
              boxShadow: "none",
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #C9CDD4",
          },
          // "& .MuiOutlinedInput-root": {
          //   "&.Mui-focused fieldset": {
          //     color:'#272E3B',
          //     background:'#fff'
          //   },

          // },

          // '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input':{
          //   height:'32px !important',p:'0 8px !important',background:'#F7F8FA',borderColor:'#C9CDD4',
          // }
        }}
      />
    </Box>
  );
};

export default NumberField;
