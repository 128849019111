import styled from "@emotion/styled";
import { Box, Button, Typography } from "@mui/material";

export const GridTitle=styled(Typography)({
    fontFamily: 'Cairo',
fontSize: '18px',
fontWeight: 500,
lineHeight: '28px',
letterSpacing: '0em',
color:' #101828'

})
export const GridBadged=styled(Box)({
    width: '97px',
height: '32px',
padding: '2px 8px 2px 8px',
borderRadius: '16px',
background: '#F9F5FF'

})
export const TextBadged=styled(Typography)({
    fontFamily: 'Cairo',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight:' 28px',
    letterSpacing: '0em',
    textAlign: 'center',
    color:'#6941C6'

})
export const PaginationButton=styled(Button)({
    width: '59px',
    height: '36px',
    padding: '8px 14px 8px 14px',
    borderRadius: '8px',
    gap: '8px',
    border: '1px solid #D0D5DD',
    boxShadow: '0px 1px 2px 0px #1018280D',
    background: ' #FFFFFF',
    color:'#344054',
    fontFamily: 'Cairo',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0em',

})
export const MainText=styled(Typography)({
    fontFamily: 'Cairo',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '30px',
    letterSpacing: '0em',
    textAlign: 'right',
    color:'#AF1016'
})
export const SeconderyText=styled(Typography)({
    fontFamily: 'Cairo',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '30px',
    letterSpacing: '0em',
    textAlign: 'right',
    color:"#7F7F7F"
})