import styled from "@emotion/styled";
import Box from "@mui/material/Box";

export const BoxRow = styled(Box)({
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	width: "100%",
});
export const BoxColumn = styled(Box)({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "space-between",
	width: "100%",
});
export const BoxProfile = styled(Box)({
	boxSizing: "border-box",
	backgroundColor: "#f8f9fa",
	width: "100%",
	height: "auto",
	borderRadius: "10px",
	marginTop: "25px",
	border: "1px solid  #e6edff ",
	justifyContent: "center",
	alignItems: "center",
	boxShadow: "3px 3px 5px 0px rgba(0, 0, 0, 0.04)",
	padding: "10px 20px",
});
