import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PropTypes } from "prop-types";
import { AddButton, Header } from "../../components/header";
import { Box, Container, Typography } from "@mui/material";
import SearchComponent from "../../components/search-component";
import CustomContainer from "../../components/features/components/customContainer";

const columns = [
  { value: "المسلسل", name: "id", type: "checkbox", inputType: "text" },
  { value: "المسلسل", name: "id", type: "text", inputType: "text" },
  { value: "تاريخ الفاتورة", name: "date", type: "text", inputType: "text" },
  {
    value: "اسم العميل ",
    name: "component_source",
    main: "source",
    type: "text",
    inputType: "text",
  },
  { value: "النوع ", name: "code", type: "text", inputType: "text" },
  { value: "الضرائب ", name: "quantity", type: "text", inputType: "text" },
  { value: "السعر ", name: "weight", type: "text", inputType: "text" },
  {
    value: "التنفيذ ",
    name: "component_type",
    main: "type",
    type: "text",
    inputType: "text",
  },
  { value: "الإجراءات", type: "option", name: "phone", inputType: "phone" },
];

const Invoices = () => {
  const [value, setValue] = useState(0);

  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <>
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box sx={{ p: 1 }}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
      </>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  return (
    <Container maxWidth="xl">
      <Header
        name={"الفواتير"}
        buttonAdd={
          <AddButton
            name={"فاتورة جديدة"}
            click={() => {
              navigate("addnewproduct");
            }}
          />
        }
      />{" "}
      <Box
        sx={{
          bgcolor: "background.paper",
          marginTop: "20px",
        }}
      >
        <SearchComponent />
        <CustomContainer
          title={"جميع الفواتير"}
          // length={{length: components?.count, name: "مكون"}}
          columns={columns}
          // rows={components?.results}
          // onDelete={handleDelete}
          onEdit={() => {}}
        />
      </Box>
    </Container>
  );
};

export default Invoices;
