import React from "react";
import Breadcrumbs from "../../components/features/components/Breadcrumbs";
import {Box, Container, Grid} from "@mui/material";
import {MainTitle} from "../../style/buttom-style";
import PictureField from "./../../components/features/components/PictureField";
import NumberField from "../../components/features/components/NumberField";
import InputField from "../../components/features/components/InputField";
import {BoxColumn, BoxRow} from "../../style/main-box";
import Form from "../../components/features/components/Form";
import SelectField from "../../components/features/components/SelectField";
import useRequest from "../../hooks/useRequest";
import useControls from "../../hooks/useControls";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import filter from "../../utils/ClearNull";
// import {MuiPhoneNumber} from "material-ui-phone-number";
import BASEURL from "../../data/Api";
const AddEmployee = () => {
	const materials = useSelector((state) => state.materials.value);
	const components = useSelector((state) => state.components.value);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [
		{controls, invalid, required},
		{setControl, resetControls, setInvalid, validate},
	] = useControls([
		{
			control: "name",
			value: "",
			isRequired: true,
		},
		{
			control: "code",
			value: 0,
			isRequired: false,
		},
		{
			control: "email",
			value: "",
			isRequired: false,
		},
		{
			control: "phone",
			value: 0,
			isRequired: false,
		},
		{
			control: "position",
			value: "",
			isRequired: false,
		},
	]);
	
	return (
		<Container maxWidth={"xl"}>
			<Breadcrumbs path={["الموظفين والوظائف", "   اضافه موظف"]} />
			<Box sx={{mb: 4}}>
				<MainTitle sx={{fontSize: "24px"}}> اضافه موظف</MainTitle>
			</Box>
			<Form
				childrenProps={{
					saveBtn: {
						// onClick: handleSubmit,
						// disabled: ReuestPostManufcturing.isPending,
					},
					closeBtn: {
						// onClick: resetControls,
						// disabled: ReuestPostManufcturing.isPending,
					},
				}}
				sx={{height: "100%"}}>
				<Grid item>
					<Grid container gap={{md: 4, xs: 0}}>
						<Grid item md={3} xs={12}>
							<PictureField label={" صوره الموظف"} />
						</Grid>
						<Grid item md={8.5} xs={12}>
							<BoxColumn gap={2} width={"100%"}>
								<BoxRow gap={4} flexDirection={{xs: "column", md: "row"}}>
									<InputField
										variant={"outlined"}
										label="  اسم الموظف"
										placeholder="اسم الموظف"
										value={controls.name}
										onChange={(e) => {
											let text = /^(?:[A-Za-z\u0600-\u06ff\s]*)$/;

											let match = text.test(e.target.value);

											if (match) {
												setControl("name", e.target.value);
											}
										}}
										required={required.includes("name")}
										textHelper={controls.name}
										error={Boolean(invalid.name)}
										helperText={invalid.name}
										sx={{width: "100%"}}
									/>
									<NumberField
										variant="outlined"
										placeholder="الكود"
										label="الكود*"
										thousandSeparator
										value={controls.code}
										onChange={(e) => setControl("code", e.target.value)}
										required={required.includes("code")}
										textHelper={controls.code}
										error={Boolean(invalid.code)}
										helperText={invalid.code}
										sx={{width: "100%"}}
									/>
								</BoxRow>

								<BoxRow gap={4} flexDirection={{xs: "column", md: "row"}}>
									<InputField
										type={"email"}
										variant="outlined"
										label=" البريد الالكتروني"
										placeholder="Mustafa@gmail.com"
										value={controls.email}
										onChange={(e) => {
											setControl("email", e.target.value);
										}}
										required={required.includes("email")}
										textHelper={controls?.email}
										error={Boolean(invalid?.email)}
										helperText={invalid?.email}
										sx={{width: "100%"}}
									/>
									{/* <MuiPhoneNumber
									variant="outlined"
									defaultCountry={"eg"}
									// value={controls.phone}
									// onChange={(e) => setControl("phone", e)}
									sx={{
										width: "100%",
										"& .MuiOutlinedInput-root": {
											height: "48px !important",
											background: "#F7F8FA",
											borderColor: "#C9CDD4",
											":hover": {
												border: "1px solid #1B53C5 !important",
											},
											":focus-within": {
												border: "1px solid #1B53C5 !important",
												background: "#fff",
											},
										},
										"& .MuiOutlinedInput-root.Mui-disabled": {
											":hover": {
												border: "1px solid #1B53C5 !important",
												boxShadow: "none",
											},
										},
										"& .MuiOutlinedInput-notchedOutline": {
											border: "1px solid #C9CDD4",
										},
									}}
								/> */}
								</BoxRow>
								<Box
									sx={{
										display: "flex",
										justifyContent: "flex-start",
										alignSelf: "flex-start",
										width: {md: "60%", xs: "100%"},
									}}>
									<SelectField
										variant="outlined"
										placeholder=" اختر"
										label=" الوظيفه"
										sx={{width: {md: "80%", xs: "100%"}}}
										// onOpen={}
										// value={controls.position}
										// renderValue={(selected) => {
										// 	return positions.results.find(
										// 		(position) => position.id === selected
										// 	)?.name;
										// }}
										// onChange={(e) =>
										// 	setControl("position", e.target.value)
										// }
										// isPending={ResponseGetComponents.isPending}>
										// {positions?.results?.map((position) => {
										// 	return (
										// 		<MenuItem key={position?.id} value={position?.id}
									>
										{/* {position?.name}
													</MenuItem>
												);
											})} */}
									</SelectField>
								</Box>
							</BoxColumn>
						</Grid>
					</Grid>
				</Grid>
			</Form>
		</Container>
	);
};

export default AddEmployee;
