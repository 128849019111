import React from 'react'
import { AreaChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { Area } from './../../../node_modules/recharts/es6/cartesian/Area';
import { Tooltip } from '@mui/material';

export default function TrafficChart() {
  return (
    <ResponsiveContainer   width="100%" height={250}>
    <AreaChart   data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
      <XAxis   tickLine={false}  reversed={true}  dataKey="none" axisLine={false}   /> 
      <YAxis dx={35} dy={10} orientation='right'   axisLine={false}    tickLine={false}   />
      {/* <CartesianGrid strokeDasharray="3 3" /> */}
      <Tooltip />
      <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
      <Area type="monotone" dataKey="pv" stroke="#82ca9d" fill="#82ca9d" />
    </AreaChart>
  </ResponsiveContainer>


  )
}








const data = [
    {
      "name": "Page A",
      "uv": 4000,
      "pv": 2400,
      "amt": 2400
    },
    {
      "name": "Page B",
      "uv": 3000,
      "pv": 1398,
      "amt": 2210
    },
    {
      "name": "Page C",
      "uv": 2000,
      "pv": 9800,
      "amt": 2290
    },
    {
      "name": "Page D",
      "uv": 2780,
      "pv": 3908,
      "amt": 2000
    },
    {
      "name": "Page E",
      "uv": 1890,
      "pv": 4800,
      "amt": 2181
    },
    {
      "name": "Page F",
      "uv": 2390,
      "pv": 3800,
      "amt": 2500
    },
    {
      "name": "Page G",
      "uv": 3490,
      "pv": 4300,
      "amt": 2100
    }
  ]
  