import {Box, Container, Grid} from "@mui/material";
import React from "react";
import {ButtonAdd, MainTitle} from "../../style/buttom-style";
import {BoxProfile} from "../../style/main-box";
import PasswordField from "../../components/features/components/PasswordField";
import useControls from "../../hooks/useControls";

const ChangePassword = () => {
	const [
		{controls, invalid, required},
		{setControl, resetControls, setInvalid, validate},
	] = useControls([
		{
			control: "current_password",
			value: "",
			isRequired: false,
		},
		{
			control: "new_password",
			value: "",
			isRequired: false,
			validations: [
				{
					test: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/,
					message: "كلمه المرورغير صالحه",
				},
				``,
			],
		},
		{
			control: "confirm_new_password",
			value: "",
			isRequired: true,
			validations: [
				{
					test: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/,
					message: "كلمه المرورغير صالحه",
				},
				``,
			],
		},
	]);

	const handleSubmit = () => {
		validate();
	};
	return (
		<Container maxWidth="xl">
			<Box
				sx={{
					boxSizing: "border-box",
					display: "flex",
					justifyContent: "space-between",
					mt: {md: 0, xs: 7},
					width: "100%",
					alignItems: "center",
					padding: 2,
				}}>
				<MainTitle sx={{fontSize: {md: "24px", xs: "18px"}}}>
					تغير كلمه المرور
				</MainTitle>
			</Box>
			<Grid container spacing={{md: 3, xs: 0}}>
				<Grid item md={6} xs={12}>
					<BoxProfile>
						<Box sx={{padding: "12px 0"}}>
							<PasswordField
								variant={"outlined"}
								type={"password"}
								label="كلمه المرور القديمه"
								placeholder="123156"
								value={controls.current_password}
								onChange={(e) => {
									setControl("current_password", e.target.value);
								}}
								required={required.includes("current_password")}
								textHelper={controls.current_password}
								error={Boolean(invalid.current_password)}
								helperText={invalid.current_password}
							/>
						</Box>
						<Box sx={{padding: "12px 0", marginTop: "10px"}}>
							<PasswordField
								variant={"outlined"}
								type={"password"}
								label="  كلمه المرور الجديده"
								placeholder="21318948"
								value={controls.new_password}
								onChange={(e) => {
									setControl("new_password", e.target.value);
								}}
								required={required.includes("new_password")}
								textHelper={controls.new_password}
								error={Boolean(invalid.new_password)}
								helperText={invalid.new_password}
							/>
						</Box>
						<Box sx={{padding: "12px 0", marginTop: "10px"}}>
							<PasswordField
								variant={"outlined"}
								type={"password"}
								label=" تأكيد كلمه المرور الجديده"
								placeholder="21318948"
								value={controls.confirm_new_password}
								onChange={(e) => {
									setControl("confirm_new_password", e.target.value);
								}}
								required={required.includes("confirm_new_password")}
								textHelper={controls.confirm_new_password}
								error={Boolean(invalid.confirm_new_password)}
								helperText={invalid.confirm_new_password}
							/>
						</Box>
						<Box sx={{textAlign: "left", marginTop: "10px"}}>
							<ButtonAdd
								sx={{
									color: "var(--black-color, #202124)",
									fontSize: {md: "18px", sm: "14px"},
									fontWeight: "600",
									marginRight: "20px",
									fontFamily: "Cairo",
									padding: "6px 16px !important",
									width: "25% !important",
									marginTop: "10px",
								}}
								onClick={() => handleSubmit()}
								variant="contained">
								{" "}
								حفظ{" "}
							</ButtonAdd>
						</Box>
					</BoxProfile>
				</Grid>
			</Grid>
		</Container>
	);
};

export default ChangePassword;
