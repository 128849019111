import React, {useState} from "react";
import {Container, Tabs, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import AccountsState from "./accounts-state";
import ManufacturingState from "./manufacturing-state";
import ComponentsState from "./components-state";
import AggregationState from "./aggregation-state";
import ProductsState from "./products-state";
import StoresState from "./stores-state";
import ResidualState from "./residual-state";
import {TabPanelStyle} from "../../style/tab-panel";
import {Header} from "../../components/header";
const State = () => {
	const [value, setValue] = useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	function TabPanel(props) {
		const {children, value, index, ...other} = props;
		return (
			<>
				<div
					role="tabpanel"
					hidden={value !== index}
					id={`vertical-tabpanel-${index}`}
					aria-labelledby={`vertical-tab-${index}`}
					{...other}>
					{value === index && (
						<Box sx={{p: 1}}>
							<Typography>{children}</Typography>
						</Box>
					)}
				</div>
			</>
		);
	}

	TabPanel.propTypes = {
		children: PropTypes.node,
		index: PropTypes.number.isRequired,
		value: PropTypes.number.isRequired,
	};

	return (
		<Container maxWidth="xl">
			<Header name={"الاحصائيات"} />
			<Box
				sx={{
					bgcolor: "background.paper",
					marginTop: "20px",
				}}>
				<Tabs
					sx={{
						borderBottom: "4px solid #f0f0f0 ",
					}}
					value={value}
					onChange={handleChange}
					variant="scrollable"
					scrollButtons="auto"
					allowScrollButtonsMobile
					aria-label="scrollable auto tabs example">
					<TabPanelStyle label="الحسابات" />
					<TabPanelStyle label="التصنيع" />
					<TabPanelStyle label="المكونات" />
					<TabPanelStyle label="التجميع" />
					<TabPanelStyle label="المنتجات" />
					<TabPanelStyle label="المخازن" />
					<TabPanelStyle label="اوشك على النفاذ" />
				</Tabs>
				<TabPanel value={value} index={0}>
					<AccountsState />
				</TabPanel>
				<TabPanel value={value} index={1}>
					<ManufacturingState />
				</TabPanel>
				<TabPanel value={value} index={2}>
					<ComponentsState />
				</TabPanel>
				<TabPanel value={value} index={3}>
					<AggregationState />
				</TabPanel>
				<TabPanel value={value} index={4}>
					<ProductsState />
				</TabPanel>
				<TabPanel value={value} index={5}>
					<StoresState />
				</TabPanel>
				<TabPanel value={value} index={6}>
					<ResidualState />
				</TabPanel>
			</Box>
		</Container>
	);
};

export default State;
