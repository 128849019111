import React, { useEffect } from "react";
import SearchComponent from "../../components/search-component";
import { Header, AddButton } from "../../components/header";
import { Avatar, Box, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomContainer from "../../components/features/components/customContainer";
import { useDispatch, useSelector } from "react-redux";
import BASEURL from "../../data/Api";
import useRequest from "../../hooks/useRequest";
import useConfirmMessage from "../../hooks/useConfirmMessage";
const Manufacturing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const components = useSelector((state) => state.components.value);
  const columns = [
    { value: "المسلسل", name: "id", type: "checkbox", inputType: "text" },
    { value: "المسلسل", name: "id", type: "text", inputType: "text" },
    {
      value: "المكون",
      name: "name",
      type: "renderCell",
      RenderCell: (row) => {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Avatar src={row?.main_image} alt="ima" />
            <Typography sx={{ fontFamily: "Cairo" }}>{row?.name}</Typography>
          </Box>
        );
      },
    },

    {
      value: "المصدر ",
      name: "component_source",
      main: "source",
      type: "text",
      inputType: "text",
    },
    { value: "الكود ", name: "code", type: "text", inputType: "text" },
    { value: "الكمية ", name: "quantity", type: "text", inputType: "text" },
    { value: "الوزن ", name: "weight", type: "text", inputType: "text" },
    {
      value: "نوع المكون ",
      name: "component_type",
      main: "type",
      type: "text",
      inputType: "text",
    },
    {
      value: " المواد الخام ",
      name: "component_materials",
      main: "material",
      type: "text",
      inputType: "text",
    },
    { value: "الإجراءات", type: "option", name: "phone", inputType: "phone" },
  ];

  const [RequestGetComponents, ResponseGetComponents] = useRequest({
    method: "Get",
    path: `${BASEURL}components/`,
  });

  const [RequestDeleteComponents, ResponseDeleteComponents] = useRequest({
    method: "DELETE",
    path: `${BASEURL}components/`,
  });

  const getComponents = () => {
    RequestGetComponents({
      onSuccess: (res) => {
        dispatch({ type: "components/set", payload: res.data });
      },
    });
  };
  const DeleteComponents = (row) => {
    RequestDeleteComponents({
      id: row?.id,
      onSuccess: (res) => {
        dispatch({ type: "components/deleteItem", payload: { id: row?.id } });
      },
    });
  };

  const [handleDelete, deleteComponentConfirmDialog] = useConfirmMessage({
    onConfirm: DeleteComponents,
    text: "هل انت متأكد من انك تريد حذف هذا المكون؟",
  });
  useEffect(() => {
    getComponents();
  }, []);

  return (
    <Container maxWidth="xl">
      <Header
        name={"التصنيع"}
        buttonAdd={
          <AddButton
            name={"تصنيع جديد"}
            click={() => navigate("addnewmanufcturing")}
          />
        }
      />

      <SearchComponent />
      <CustomContainer
        title={"جميع المكونات"}
        length={{ length: components?.count, name: "مكون" }}
        columns={columns}
        rows={components?.results}
        onDelete={handleDelete}
        onEdit={() => {}}
      />
      {deleteComponentConfirmDialog}
    </Container>
  );
};

export default Manufacturing;
