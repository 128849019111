import styled from "@emotion/styled";
import {ToggleButton} from "@mui/material";

export const MainToggleButton = styled(ToggleButton)({
	display:'flex',
	flexDirection:'row',
	alignItems:'center',
	gap:4,
	width: "100%",
	color: "#7F7F7F",
	fontFamily: "Cairo",
	textAlign: "right",
	fontSize: "18px",
	fontStyle: "normal",
	fontWeight: 500,
	lineHeight: "normal",
	border: "none",
	":hover": {
		background: "transparent",
	},

	"&.MuiButtonBase-root.MuiToggleButton-root.Mui-selected": {
		color: "#183E91",
		background: "transparent",
	},
});
