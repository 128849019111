import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InsightsIcon from "@mui/icons-material/Insights";
import TableViewIcon from "@mui/icons-material/TableView";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PrecisionManufacturingOutlinedIcon from "@mui/icons-material/PrecisionManufacturingOutlined";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import CollectionsBookmarkOutlinedIcon from "@mui/icons-material/CollectionsBookmarkOutlined";
import FortOutlinedIcon from "@mui/icons-material/FortOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import {
  Avatar,
  Container,
  Grid,
  Stack,
  ToggleButtonGroup,
} from "@mui/material";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import MPLogo from "../assets/MP_logo.png";
import Person from "../images/Ellipse 3.jpg";
import { useNavigate } from "react-router-dom";
import { MainToggleButton } from "../style/main-toggle-button";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import { useDispatch } from "react-redux";
import { DoNotDisturb } from "@mui/icons-material";
const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const namePages = [
    {
      name: "الرئيسية",
      icon: <InsightsIcon />,
      url: "/",
    },
    {
      name: "الإحصائيات",
      icon: <AccessTimeIcon />,
      url: "/state",
    },
    {
      name: "الحسابات",
      icon: <TableViewIcon />,
      url: "/accounts",
    },

    {
      name: "الخلطات",
      icon: <GridViewOutlinedIcon />,
      url: "/components",
    },
    {
      name: "الماكينات",
      icon: <SettingsSuggestIcon />,
      url: "/state",
    },
    {
      name: "الفواتير",
      icon: <AccessTimeIcon />,
      url: "/invoices",
    },

    {
      name: "التصنيع",
      icon: <PrecisionManufacturingOutlinedIcon />,
      url: "/manufacturing ",
    },
    // {
    //   name: "التجميع",
    //   icon: <FortOutlinedIcon />,
    // url: "/assembly ",
    // },

    {
      name: "المخازن",
      icon: <HomeWorkOutlinedIcon />,
      url: "/stores ",
    },
    {
      name: "العملاء",

      icon: <PeopleAltOutlinedIcon />,
      url: "/customers",
    },
    {
      name: " التحكم",

      icon: <ControlPointOutlinedIcon />,
      url: "/control",
    },
    {
      name: " الموظفين و الوظائف",

      icon: <BadgeOutlinedIcon />,
      url: "/jobsemployees",
    },
  ];
  const [alignment, setAlignment] = React.useState(namePages[0].name);
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const drawer = (
    <Box
      sx={{
        // display: { xs: "none", md: "flex" },
        width: "100%",
        height: "100vh",
        position: "relative",
        flexDirection: "column",
      }}
      variant="permanent"
      anchor="right"
    >
      <Container
        maxWidth={"xl"}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Grid item sx={{ padding: "24px 12px 32px 0px", width: "100%" }}>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <Grid item md={2} xs={2}>
                <Avatar
                  sx={{ cursor: "pointer" }}
                  alt="profile"
                  src={Person}
                  onClick={() => {
                    navigate("/profile");
                  }}
                />
              </Grid>
              <Grid
                item
                xs={8}
                md={8}
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  "-webkit-line-clamp": 1,
                  "-webkit-box-orient": "vertical",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Cairo",
                    fontSize: "14px",
                    color: "#202124",
                    fontWeight: "600",
                    lineHeight: "20px",
                  }}
                  variant="body1"
                  component="h1"
                >
                  مصطفى حسنين
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Cairo",
                    fontSize: "14px",
                    color: "#7F7F7F",
                    fontWeight: "500",
                    lineHeight: "20px",
                  }}
                  variant="body1"
                  component="paragraph"
                >
                  Mustafa@gmail.com
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                md={2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <LogoutOutlinedIcon
                  sx={{ fontSize: "30px", cursor: "pointer" }}
                  onClick={(e) => {
                    // localStorage.removeItem('userinfo')
                    dispatch({ type: "userInfo/logout" });
                    navigate("/signin");
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Divider
            style={{ height: "2px", background: "#e5e5e5", width: "95%" }}
          />

          <ToggleButtonGroup
            orientation="vertical"
            value={alignment}
            exclusive
            onChange={handleChange}
            aria-label="text alignment"
            sx={{ mt: "24px" }}
          >
            {namePages.map((page) => {
              return (
                <MainToggleButton
                  sx={{ paddingTop: 0, paddingBottom: "15px" }}
                  value={page.name}
                  disableRipple={true}
                  onClick={() => {
                    navigate(page.url);
                  }}
                  // onClick={() => {
                  //   page.name === "العملاء" ||
                  //   page.name === "الرئيسية" ||
                  //   page.name === "الخلطات"
                  //     ? navigate(page.url)
                  //     : alert(
                  //         "لا يزال العمل جاريًا على هذه الميزة، وستتاح قريبًا."
                  //       );
                  // }}
                >
                  <Stack
                    gap={2}
                    flexDirection={"row"}
                    alignItems={"center"}
                    alignSelf={"start"}
                    sx={{ width: "100%", justifyContent: "flex-start" }}
                  >
                    {page.icon} {page.name}
                    {page.name === "العملاء" || page.name === "الخلطات"|| page.name === "المخازن" ? (
                      ""
                    ) : (
                      <DoNotDisturb />
                    )}
                  </Stack>
                </MainToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
          }}
        >
          <Divider
            style={{
              height: "2px",
              background: "#e5e5e5",
              width: "95%",
              mx: "auto",
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: "16px ",
              pb: "32px",
            }}
          >
            <img
              src={MPLogo}
              alt="well-done-img"
              width={"40%"}
              // height={"56px"}
              style={{ width: " 139px" }}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );

  // Remove this const when copying and pasting into your project.
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          background: "linear-gradient(90deg, #AF1016 0%, #DC1820 100%)",
          zIndex: 1100,
          display: { md: "none" },
          width: { sm: `100%` },
          // ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid
            container
            item
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid xs={8}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { md: "none" } }}
              >
                <MenuIcon fontSize="large" />
              </IconButton>
            </Grid>

            <Grid xs={2}>
              <IconButton sx={{ color: "white" }}>
                <NotificationsNoneOutlinedIcon fontSize="large" />
              </IconButton>
            </Grid>
            <Grid xs={2}>
              <Avatar
                alt="profile"
                src={Person}
                onClick={() => {
                  navigate("/profile");
                }}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: "100%" }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          anchor="right"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "80%",
              borderRadius: "20px 0px 0px 20px",
              boxShadow: "-2px 2px 3px 0px #0000000A",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          anchor="right"
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "21%",
              borderRadius: "20px 0px 0px 20px",
              boxShadow: "-2px 2px 3px 0px #0000000A",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
