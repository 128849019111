import { createTheme } from "@mui/material";

const theme = createTheme({
   palette:{
    progress:{ 
        done:"#FFB200",
        refund:"#4339F2",
        order:"#02A0FC",
        cancel:"#FF3A29"
    }
   }
    ,

    typography: {
     "fontFamily": `Cairo`,
    
    }
 });

 export default theme