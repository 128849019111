import React, {useEffect} from "react";
import {
	Box,
	CircularProgress,
	MenuItem,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {SubTitle} from "../../../style/buttom-style";


const SelectField = ({
	variant = null,
	isPending = false,
	label,
	onOpen,
	onClose,
	multiple=false,
	renderValue = null,
	children,
	disabled,
	placeholder = "",
	SelectProps = {},
	...rest
}) => {
	return (
		<Box
			sx={{
				width: "100%",

				"& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select":
					{
						height: "32px !important",
						pl: 2,
						py: 0,
						display: "flex",
						alignItems: "center",
						width: "100%",
						background: "#F7F8FA",
						borderColor: "#C9CDD4",
						":hover": {
							border: "1px solid #1B53C5 !important",
						},
						":focus-within": {
							border: "1px solid #1B53C5 !important",
							background: "#fff",
						},
					},
				"& .MuiOutlinedInput-root.Mui-disabled": {
					":hover": {
						border: "1px solid #1B53C5 !important",
						boxShadow: "none",
					},
				},
				"& .MuiOutlinedInput-notchedOutline": {
					border: "1px solid #C9CDD4",
				},
			}}
			// sx={{display:'flex',flexDirection:'column',alignItems:'start',width:'100%',}}
		>
			<SubTitle>{label}</SubTitle>
			<TextField
				variant={Boolean(variant) ? variant : "standard"}
				select
				SelectProps={{
					defaultValue: "",
					multiple,
					displayEmpty: true,
					onOpen: onOpen,
					onClose: onClose,
					disabled:disabled,
					renderValue: (selected) => {
						if (!Boolean(selected)) {
							return (
								<Typography sx={{color: "currentColor", opacity: "0.42"}}>
									{placeholder}
								</Typography>
							);
						} else {
							return Boolean(renderValue) && typeof renderValue === "function"
								? renderValue(selected)
								: selected;
						}
					},
					MenuProps: {
						PaperProps: {
							sx: {
								maxHeight: "200px",
								overflowY: "auto",
							},
						},
					},
					IconComponent: KeyboardArrowDownIcon,
					...SelectProps,
				}}
				{...rest}>
				{Boolean(isPending) ? (
					<Stack justifyContent="center" alignItems="center" sx={{p: 1}}>
						<CircularProgress size="30px" sx={{color: "gray"}} />
					</Stack>
				) : children?.length ? (
					children
				) : (
					<MenuItem disabled>فارغ</MenuItem>
				)}
			</TextField>
		</Box>
	);
};

export default SelectField;
