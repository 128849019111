import { Box, Grid, LinearProgress, Typography } from '@mui/material'
import React from 'react'

export default function ProgressBar({text, amount ,color}) {
  return (
    <Box  sx={{ width: "100%" , marginBottom:"24px"}}>
		 
    <Box>
    <Grid container marginBottom={"6px"} justifyContent={"space-between"}>
    <Typography>{text}</Typography>
    <Typography>{amount}</Typography>
    </Grid>
    </Box>
   
  
      <LinearProgress
sx={{
  borderRadius:1,
  height: "8px" ,
        '& .MuiLinearProgress-bar': {
          backgroundColor: `${color}`, 
        },
      }}

      
        variant="determinate"
        value={20}
        // color={type || "primary"}
   
      />
    </Box>
  )
}
