import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
export const ButtonPrinter = styled(Button)({
  color: "var(--black-color)",
  height: "40px",
  padding: "20px",
  width: "fit-content",
  borderRadius: "4px",
  border: "1px solid #d9d9d9",
  backgroundColor: "#FFF",
  fontFamily: "Cairo",
  textAlign: "center",
});

export const ButtonAdd = styled(Button)({
  padding: "15px 0 15px 15px",
  borderRadius: "4px",
  width: "fit-content",
  color: "var(--light-gray-color)",
  // background: "linear-gradient(90deg, #AF1016 0%, #DC1820 100%)",
  background: "#183E91",
  marginLeft: "0 !important",

  height: "40px",
  ":hover": {
    backgroundColor: "red",
    boxShadow: "none",
  },
});
export const MainTitle = styled(Typography)({
  color: "#181823",
  fontFamily: "Cairo",
  fontWeight: 700,
  lineHeight: "150% " /* 36px */,
});
export const SubTitle = styled(Typography)({
  color: " #6B7785",
  fontFamily: "Cairo",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "normal",
});
export const TextInner = styled(Typography)({
  //styleName: Text md/Regular;
  fontFamily: "Cairo",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
  letterSpacing: "0em",
  textAlign: "right",
});

export const ButtonSave = styled(Button)({
  display: "flex",
  width: "138px",
  height: "44px",
  padding: " 6px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  flexShrink: 0,
  color: "var(--light-gray-color)",
  background: "#183E91" ,
  fontFamily: "Cairo",
});
export const ButtonCancel = styled(Button)({
  display: "flex",
  width: "138px",
  height: "44px",
  padding: " 6px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  flexShrink: 0,
  color: "#4E5969",
  fontFamily: "Cairo",
  background: "transparent",
  ":hover": {
    background: "transparent",
  },
});
export const ButtonChoose = styled(Button)({
  width: { md: "20%", xs: "40%" },
  height: " 28px",
  padding: "6px 16px 6px 16px",
  borderRadius: "4px",
  gap: " 8px",
  border: "1px solid #4E5969",
  background: "#F5F5F5",
  fontSize: "14px",
  color: "#202124",
  fontFamily: "Cairo",
  ":hover": {
    background: "#F5F5F5",
  },
});

export const ButtonDelete = styled(Button)({
  display: "flex",
  width: "50%",
  height: "44px",
  borderRadius: " 8px",
  border: "1px solid #D92D20",
  padding: " 6px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  flexShrink: 0,
  color: "#FFFFFF",
  boxShadow: "0px 1px 2px 0px #1018280D",
  background: "#D92D20",
  fontFamily: "Cairo",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "24px",
  letterSpacing: "0em",
  ":hover": {
    background: "#D92D20",
  },
});

export const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: "#f5f8fa",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
}));

export const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage: "#183E91",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});


// export const 
