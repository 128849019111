import React from "react";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import InputField from "../../components/features/components/InputField";
import PasswordField from "../../components/features/components/PasswordField";
import { ButtonAdd } from "../../style/buttom-style";
import loginImage from "../../images/login1.png";
import useControls from "../../hooks/useControls";
import useRequest from "../../hooks/useRequest";
import BASEURL, { login } from "../../data/Api";
import filter from "../../utils/ClearNull";
import { useDispatch } from "react-redux";
import { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import InputText from "../../components/features/components/TextField";
import { useNavigate } from "react-router-dom";
import { MainText, SeconderyText } from "../../style/main";
import { BoxRow } from "../../style/main-box";
const Login = () => {
  const [visibilities, setVisibilities] = useState({
    password: false,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [LoginPostRequest, LoginPostResponse] = useRequest({
    path: `https://el-mostafa.cyparta.com/users/login/`,
    method: "POST",
    successMessage: "تم تسجيل الدخول بنجاح",
    withoutAuth: true,
    // Authorization: `JWT`,
  });

  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "username",
      value: "",
      isRequired: true,
    },
    {
      control: "password",
      value: "",
      isRequired: true,
    },
  ]);

  const [data, setData] = useState();

  const handleLogin = (e) => {
    e.preventDefault();
    validate().then(({ isOk }) => {
      if (!isOk) return;

      const requestBody = filter({
        obj: {
          username: controls.username,
          password: controls.password,
        },
        output: "formData",
      });

      LoginPostRequest({
        body: requestBody,
        onSuccess: (res) => {
          dispatch({ type: "userInfo/setToken", payload: res?.data?.access });
          navigate("/");
          resetControls();
        },
      });
    });
  };
  return (
    <Box
      sx={{
        with: "100%",
        height: "100vh",
        position: "relative",
      }}
    >
      <Grid container style={{ zIndex: "-1", position: "absolute" }}>
        <Grid
          md={6}
          xs={6}
          sx={{ with: "100%", bgcolor: "#fff", height: "100vh" }}
        ></Grid>
        <Grid
          md={6}
          xs={6}
          sx={{ with: "100%", bgcolor: "#4E5969", height: "100vh" }}
        ></Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{
          minHeight: "100vh",
        }}
      >
        <Box
          p={{ md: 4, xs: 2 }}
          bgcolor="#fff"
          borderRadius={{ md: 7, xs: 5 }}
          sx={{
            boxShadow: "0px 4px 35px rgba(0, 0, 0, 0.08)",
            width: { md: "25%", xs: "60%" },
            position: "relative",
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontFamily: "Cairo", fontSize: "20px", fontWeight: "400" }}
          >
            مرحبا بك في El-Mostafa Plast{" "}
          </Typography>
          <Box sx={{ p: 1, mt: 1 }}>
            <InputText
              variant={"outlined"}
              label="اسم المستخدم "
              placeholder=" اسم المستخدم"
              value={controls.username}
              onChange={(e) => {
                setControl("username", e.target.value);
              }}
              required={required.includes("username")}
              textHelper={controls.username}
              error={Boolean(invalid.username)}
              helperText={invalid.username}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  background: "#fff !important",
                },
              }}
            />
          </Box>
          <Box sx={{ p: 1, mt: 1 }}>
            <InputText
              variant={"outlined"}
              type={visibilities.password ? "text" : "password"}
              label="  الرقم السري"
              placeholder="الرقم السري"
              value={controls.password}
              onChange={(e) => {
                setControl("password", e.target.value);
              }}
              required={required.includes("password")}
              textHelper={controls.password}
              error={Boolean(invalid.password)}
              helperText={invalid.password}
            />
          </Box>
          <Box textAlign={"center"} mt={{ md: 4, xs: 2 }}>
            <ButtonAdd
              type="submit"
              ispending={LoginPostResponse.ispending}
              onClick={handleLogin}
              sx={{
                width: "96% !important",
                fontWeight: "600 !important",
                fontSize: { md: "18px", xs: "14px" },
                fontFamily: "Cairo",
              }}
            >
              {" "}
              تسجيل الدخول
            </ButtonAdd>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <SeconderyText>ليس لديك حساب ؟</SeconderyText>
            <MainText onClick={() => navigate("/signup")}> انشاء حساب</MainText>
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom: "-17%",
              right: "-13%",
              width: "122px",
              height: "124px",
              zIndex: "-1",
            }}
          >
            <img
              src={loginImage}
              alt="loginImage"
              width={"100%"}
              height={"100%"}
              style={{ objectFit: "contain", opacity: "0.5" }}
            />
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "-14%",
              right: "1%",
              width: "94px",
              height: "96px",
              zIndex: "-1",
            }}
          >
            <img
              src={loginImage}
              alt="loginImage"
              width={"100%"}
              height={"100%"}
              style={{ objectFit: "contain", opacity: "0.5" }}
            />
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "-9%",
              left: "20%",
              width: "78px",
              height: "80px",
              zIndex: "-1",
            }}
          >
            <img
              src={loginImage}
              alt="loginImage"
              width={"100%"}
              height={"100%"}
              style={{ objectFit: "contain", opacity: "0.5" }}
            />
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "29%",
              left: "-18%",
              width: "127px",
              height: "130px",
              zIndex: "-1",
            }}
          >
            <img
              src={loginImage}
              alt="loginImage"
              width={"100%"}
              height={"100%"}
              style={{ objectFit: "contain", opacity: "0.5" }}
            />
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom: "-15%",
              left: "10%",
              width: "34px",
              height: "35px",
              zIndex: "-1",
            }}
          >
            <img
              src={loginImage}
              alt="loginImage"
              width={"100%"}
              height={"100%"}
              style={{ objectFit: "contain", opacity: "0.5" }}
            />
          </Box>
        </Box>
      </Grid>
      {LoginPostResponse.failAlert}
    </Box>
  );
};

export default Login;
