import {Accordion, Box} from "@mui/material";
import React from "react";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import {MainToggleButton} from "../../../style/main-toggle-button";

const ToggleLanguage = ({name, icon, description, navigatePage}) => {
	return (
		<Box
			sx={{
				width: "100%",
			}}>
			<Accordion
				sx={{
					"&.MuiAccordion-root": {
						boxShadow: "none !important",
					},
				}}>
				<AccordionSummary
					expandIcon={<ArrowBackIosOutlinedIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
					sx={{background: "#f8f9fa"}}>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-end",
						}}>
						{icon}
						<Typography
							sx={{fontFamily: "Cairo", fontSize: "16px", marginRight: "10px"}}>
							{name}
						</Typography>
					</Box>
				</AccordionSummary>
				<AccordionDetails sx={{background: "#f8f9fa"}}>
					<MainToggleButton
						onClick={navigatePage}
						sx={{width: "30% !important"}}>
						<Typography sx={{fontFamily: "Cairo"}}>{description}</Typography>
					</MainToggleButton>
				</AccordionDetails>
			</Accordion>
		</Box>
	);
};

export default ToggleLanguage;
