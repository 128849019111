/* eslint-disable no-sparse-arrays */
import React, { useEffect, useState } from "react";
import SearchComponent from "../../components/search-component";
import { useDispatch, useSelector } from "react-redux";
import CustomContainer from "../../components/features/components/customContainer";
import BASEURL from "../../data/Api";
import useRequest from "../../hooks/useRequest";
import { Avatar, Box, Typography } from "@mui/material";
import useConfirmMessage from "../../hooks/useConfirmMessage";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/details-veiw/Modal";
const columns = [
  { value: "select", name: "id", type: "checkbox",inputType:"text"},
  {
    value: "المنتج",
    name: "name",
    type: "text",
    // RenderCell: (row) => {
    //   return <Box sx={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center',gap:1}}>
    //     <Avatar src={row?.main_image} alt={row.name}/>
    //     <Typography sx={{fontFamily:'Cairo'}}>{row?.name}</Typography>
    //   </Box>
    // },
  },
  { value: "الكود ", name: "id", type: "text", inputType: "text" },
  { value: "الوزن ", name: "weight", type: "text", inputType: "text" },
  // {
  //   value: "الرقم على الرف ",
  //   name: "number_on_roof",
  //   type: "text",
  //   inputType: "text",
  // },
  // { value: "السعر", name: "price", type: "text", inputType: "text" },
  // {
  //   value: "الكمية",
  //   name: "quantity",
  //   type: "text",
  //   inputType: "text",
  // },
  { value: "الإجراءات", type: "option", name: "phone", inputType: "phone" },
];




const AllProducts = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  const navigate = useNavigate()
  const products = useSelector((state) => state.products.value);
  const [selectedProduct, setSelectedProduct] = useState(null);


  const deleteProduct = (product) => {
    ReuestDeleteProduct({
      customPath: `products/products/${product.id}`,
      onSuccess: () => {
        dispatch({ type: "products/deleteItem", payload: { id: product.id } });
      },
    });
  };

  const handleView = (material) => {
    setSelectedProduct(material);  // Set selected material
    setOpenModal(true);  // Open modal
  };


 
  // get all products request
  const [ReuestGetProducts, ResponseGetProducts] = useRequest({
    method: "Get",
    path: `${BASEURL}products/products`,
  });

  // delete product request
  const [ReuestDeleteProduct, ResponseDeleteProduct] = useRequest({
    method: "DELETE",
    path: `${BASEURL}products/products`,
  });


  const getProducts = () => {
    ReuestGetProducts({
      onSuccess: (res) => {
        dispatch({ type: "products/set", payload: res?.data });
      },
    });
  };

  const DeleteProduct = (row) => {
    console.log(row);
    ReuestDeleteProduct({
      customPath: `products/products/${row.id}`,

      onSuccess: (res) => {
        dispatch({ type: "products/deleteItem", payload: {id:row?.id} })
      },
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedProduct(null);  
  };

  
  const [handleDelete, deleteProductConfirmDialog] = useConfirmMessage({
		onConfirm: DeleteProduct,
		text: "هل انت متأكد من انك تريد حذف هذا المنتج؟",
	  });
  useEffect(() => {
    getProducts();
  }, []);
  return (
    <>
      <SearchComponent />
      <CustomContainer
        title={'المنتجات'}
        length={{length:products?.results.length,name:'منتج'}}
        columns={columns}
        rows={products?.results}
        onDelete={(e,row) =>handleDelete(row)}
        onView={(e, row) => handleView(row)}  // Add onView to trigger modal

        onEdit={(e,row) => {navigate(`/control/add-new-product/${row.id}`)}}
      />
       <Modal title={"تفاصيل المنتج"} openModal={openModal} handleCloseModal={handleCloseModal} >
	 {selectedProduct ? (
        <>
          <p><strong>اسم المادة:</strong> {selectedProduct.name}</p>
          <p><strong>الكود:</strong> {selectedProduct.id}</p>
          <p><strong>الوزن:</strong> {selectedProduct.weight}</p>
        </>
      ) : (
        <p>جاري تحميل البيانات...</p>
      )}
	 </Modal>
      {deleteProductConfirmDialog}
      {ResponseDeleteProduct.failAlert}
      {ResponseDeleteProduct.successAlert}
     
    </>
  );
};

export default AllProducts;
