import { Container, Grid } from "@mui/material";
import React from "react";
import { Header } from "../../components/header";
import ControlCard from "../../components/ControlCard";
import product from "../../images/control/box.png";
import tools from "../../images/control/service.png";
import material from "../../images/control/raw-materials 1.png";
import customer from "../../images/control/customer.png";
import purchase from "../../images/control/invoice.png";
import manifacture from "../../images/control/factory-machine 2.png";
const Control = () => {
  const dataOfCard = [
    {
      title: "اضافه منتج",
      description: "اضافه منتج وتحديد القطع الخام والاجزاء اللازمه لتكوينه",
      image: product,
      route: "/products/addnewproduct",
    },
    {
      title: "اضافه قطع غيار",
      description: "اضافه قطع غيار جديده لتشارك فى تصنيع منتج جديد",
      image: tools,
      route: "",
    },
    {
      title: " اضافه ماده خام",
      description: "اضافه ماده خام للتصنيع",
      image: material,
      route: "/control/add-new-raw",
    },
    {
      title: " اضافه عميل",
      description: "اضافه عميل جديد وتحديد الكميات المطلوبه من المنتج",
      image: customer,
      route: "/customers/add-new-customer",
    },
    {
      title: " اضافه عمليه شراء",
      description: "اضافه عمليه شراء او فاتوره مسبقه غير مسجله",
      image: purchase,
      route: "",
    },
    {
      title: "اضافه اله تصنيع",
      description: "اضافه قطع غيار جديده لتشارك في تصنيع منتج جديد",
      image: manifacture,
    },
  ];

  return (
    <Container maxWidth="xl">
      <Header name={"التحكم"}></Header>
      <Grid item mt={"24px"}>
        <Grid container spacing={2}>
          {dataOfCard.map((card) => (
            <Grid item lg={2.4} md={3} sm={4} key={card}>
              <ControlCard
                title={card.title}
                description={card.description}
                route={card.route}
                image={card.image}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Control;
