import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import StatisticsCard from "./statistics";
import { TransactionType } from "./clients";
import ProgressBar from "./progress-bar";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis } from "recharts";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
  },
];

const Stores = () => {
  return (
    <>
      {/* First Grid container for Statistics Cards */}
      <Grid
        container
        spacing={2.2}
        justifyContent={"space-between"}
        sx={{ margin: { md: "1% 0", xs: "6% 0 " } }}
      >
        <StatisticsCard heading={"مساحة متبقية"} number={"263"} />
        <StatisticsCard heading={"عدد الخامات"} number={"56"} />
        <StatisticsCard heading={"قطع الغيار"} number={"97"} />
        <StatisticsCard heading={"المنتجات"} number={"20"} />
        <StatisticsCard heading={"خامات مطلوبة"} number={"56"} />
        <StatisticsCard heading={"الات منتجة"} number={"4"} />
      </Grid>

      {/* Second Grid container for Analysis and BarChart */}
      <Grid
        container
        justifyContent={"space-between"}
        sx={{ margin: { md: "1% 0", xs: "6% 0" } }}
      >
        {/* First Grid item for Transaction Analysis */}
        <Grid item border={"1px solid #EBEFF2"} borderRadius={1} md={5.9}>
          <Box marginTop={"24px"}>
            <Typography padding={"15px"}>تحليل بيانات طلبات العملاء</Typography>
          </Box>
          <Divider width={"100%"} />
          <Grid container padding={"15px"}>
            <TransactionType md={3} color={"#FF3A29"}>
              الغاء
            </TransactionType>
            <TransactionType md={3} color={"#02A0FC"}>
              طلب
            </TransactionType>
            <TransactionType md={3} color={"#4339F2"}>
              استرجاع
            </TransactionType>
            <TransactionType md={3} color={"#FFB200"}>
              تم الشراء
            </TransactionType>
          </Grid>
          <Box padding={"15px"} width={"100%"}>
            <ProgressBar text={"تم الشراء"} amount={"650000"} color={"#FFB200"} />
            <ProgressBar text={"استرجاع"} amount={"650000"} color={"#4339F2"} />
            <ProgressBar text={"طلب"} amount={"650000"} color={"#02A0FC"} />
            <ProgressBar text={"الغاء"} amount={"650000"} color={"#FF3A29"} />
          </Box>
          <Divider width={"100%"} />
        </Grid>

        {/* Second Grid item for Bar Chart */}
        <Grid item border={"1px solid #EBEFF2"} borderRadius={1} md={5.9}>
          <Box>
            <Typography padding={"15px"}>مساحة المخزن</Typography>
          </Box>
          <Divider width={"100%"} />
          <Grid item padding={"15px"}>
            <TransactionType md={3} color={"#02A0FC"}>
              مساحة المخزن
            </TransactionType>
          </Grid>
		  <ResponsiveContainer   width="100%" height={450}>
            <BarChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}  data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis tickLine={false}  reversed={true}  dataKey="none" axisLine={false}  />
              <YAxis   dx={40} dy={0} orientation='right'   axisLine={false}    tickLine={false} />
              <Legend />
              <Bar   radius={20} barSize={20} dataKey="pv" fill="#109CF1" />
            </BarChart>
			</ResponsiveContainer>
    
        </Grid>
      </Grid>
    </>
  );
};

export default Stores;
