import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const ControlCard = ({ image, title, description,route }) => {
    const navigate=useNavigate()
  return (
    <Box
      sx={{
        width: "100%",
        height:'100%',
        padding: "20px 20px 16px 20px",
        borderRadius: "10px",
        border: '2px solid #F5F5F5',
        cursor:'pointer'
      }}
      onClick={()=>navigate(route)}
    >
    
        <Box
          sx={{
            width: " 44px",
            height: "44px",
            borderRadius: "2px",
            border: "0.5px solid #7F7F7F",
            display:'flex',
            justifyContent:"center" ,
            alignItems:'center'
          }}
        >
          <img src={image} alt="icon" style={{height:'30px',width:'30px'}}/>
        </Box>
        <Box>
          <Typography
            sx={{
              fontFamily: "Cairo",
              fontSize: "20px",
              fontWeight: 500,
              lineHeight: "30px",
              letterSpacing: "0.01em",
              mt:'16px'
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Cairo",
              fontSize: "15px",
              fontWeight: 500,
              lineHeight: " 28px",
              letterSpacing: "0.01em",
              color:"#7F7F7F"
            }}
          >
            {description}
          </Typography>
        </Box>
     
    </Box>
  );
};

export default ControlCard;
