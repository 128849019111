import React from "react";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import InputField from "../../components/features/components/InputField";
import PasswordField from "../../components/features/components/PasswordField";
import { ButtonAdd } from "../../style/buttom-style";
import loginImage from "../../images/login1.png";
import useControls from "../../hooks/useControls";
import useRequest from "../../hooks/useRequest";
import { SIGNUP } from "../../data/Api";
import filter from "../../utils/ClearNull";
import { useDispatch } from "react-redux";
import { useState } from "react";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

import InputText from "../../components/features/components/TextField";
import { useNavigate } from "react-router-dom";
import { MainText, SeconderyText } from "../../style/main";
import MuiPhoneNumber from "material-ui-phone-number";

const Signup = () => {
  const [visible, setVisible] = useState(false);

  const handleToggleVisibility = () => {
    setVisible((prevVisible) => !prevVisible);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [SignupPostRequest, SignupPostResponse] = useRequest({
    path: SIGNUP,
    method: "POST",
    successMessage: "تم إنشاء حسابك بنجاح",
  });

  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "username",
      value: "",
      isRequired: true,
    },
    {
      control: "email",
      value: "",
      isRequired: true,
    },
    {
      control: "phone",
      value: "",
      isRequired: false,
    },
    {
      control: "password",
      value: "",
      isRequired: true,
    },
    {
      control: "confirm_password",
      value: "",
      isRequired: false,
    },
  ]);

  const [data, setData] = useState();

  const handleSignup = (e) => {
    e.preventDefault();
    validate().then(({ isOk }) => {
      if (!isOk) return;

      const requestBody = filter({
        obj: {
          username: controls.username,
          phone: controls.phone,
          password: controls.password,
          email: controls.email,
        },
        output: "formData",
      });

      SignupPostRequest({
        body: requestBody,
        onSuccess: (res) => {
          dispatch({ type: "userInfo/setToken", payload: res?.data?.access });
          navigate("/");
          resetControls();
        },
      });
    });
  };
  return (
    <Box
      sx={{
        with: "100%",
        height: "100vh",
        position: "relative",
      }}
    >
      <Grid container style={{ zIndex: "-1", position: "absolute" }}>
        <Grid
          md={6}
          xs={6}
          sx={{ with: "100%", bgcolor: "#fff", height: "100vh" }}
        ></Grid>
        <Grid
          md={6}
          xs={6}
          sx={{ with: "100%", bgcolor: "#4E5969", height: "100vh" }}
        ></Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{
          minHeight: "100vh",
        }}
      >
        <Box
          p={{ md: 4, xs: 2 }}
          bgcolor="#fff"
          borderRadius={{ md: 7, xs: 5 }}
          sx={{
            boxShadow: "0px 4px 35px rgba(0, 0, 0, 0.08)",
            width: { md: "25%", xs: "60%" },
            position: "relative",
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontFamily: "Cairo", fontSize: "20px", fontWeight: "400" }}
          >
            مرحبا بك في El-Mostafa Plast{" "}
          </Typography>
          <Box sx={{ p: 1, mt: 1 }}>
            <InputText
              variant={"outlined"}
              label="اسم المستخدم "
              placeholder=" اسم المستخدم"
              value={controls.username}
              onChange={(e) => {
                setControl("username", e.target.value);
              }}
              required={required.includes("username")}
              textHelper={controls.username}
              error={Boolean(invalid.username)}
              helperText={invalid.username}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  background: "#fff !important",
                },
              }}
            />
          </Box>
          <Box sx={{ p: 1, mt: 1 }}>
            <InputText
              variant={"outlined"}
              type={"email"}
              label="البريد للإلكترونى"
              placeholder="ادخل البريد البريد الإلكترونى"
              value={controls.email}
              onChange={(e) => {
                setControl("email", e.target.value);
              }}
              required={required.includes("email")}
              textHelper={controls.email}
              error={Boolean(invalid.email)}
              helperText={invalid.email}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  background: "#fff !important",
                },
              }}
            />
          </Box>
          <Box sx={{ p: 1, mt: 1 }}>
            <Typography
              sx={{
                mb: "6px !important",
                fontFamily: "Cairo",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "30px",
                letterSpacing: "0em",
                textAlign: "right",
              }}
            >
              الهاتف المحمول
            </Typography>
            <MuiPhoneNumber
              variant="outlined"
              defaultCountry={"eg"}
              value={controls.phone}
              onChange={(e) => setControl("phone", e)}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  height: "48px !important",
                  background: "#fff",
                  borderColor: "#C9CDD4",
                  borderRadius: "9px",
                  ":hover": {
                    border: "1px solid #1B53C5 !important",
                  },
                  ":focus-within": {
                    border: "1px solid #1B53C5 !important",
                    background: "#fff",
                  },
                },
                "& .MuiOutlinedInput-root.Mui-disabled": {
                  ":hover": {
                    border: "1px solid #1B53C5 !important",
                    boxShadow: "none",
                  },
                },
                "& .MuiOutlinedInput-notchedOutlin e": {
                  border: "1px solid #C9CDD4",
                },
              }}
            />
          </Box>
          <Box sx={{ p: 1, mt: 1 }}>
            <InputText
              variant={"outlined"}
              //   type={visible ? "text" : "password"}
              label="  الرقم السري"
              placeholder="الرقم السري"
              value={controls.password}
              onChange={(e) => {
                setControl("password", e.target.value);
              }}
              required={required.includes("password")}
              textHelper={controls.password}
              error={Boolean(invalid.password)}
              helperText={invalid?.password}
              //   InputProps={{
              //     endAdornment: (
              //         <InputAdornment position="end" sx={{margin: 0}}>
              //             <IconButton  onClick={handleToggleVisibility}>
              //                 {visible ? (
              //                     <VisibilityOffOutlinedIcon />
              //                 ) : (
              //                     <VisibilityOutlinedIcon />
              //                 )}
              //             </IconButton>
              //         </InputAdornment>
              //     ),
              // }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: "48px !important",
                },
              }}
            />
          </Box>
          <Box textAlign={"center"} mt={{ md: 4, xs: 2 }}>
            <ButtonAdd
              type="submit"
              ispending={SignupPostResponse.ispending}
              onClick={handleSignup}
              sx={{
                width: "96% !important",
                fontWeight: "600 !important",
                fontSize: { md: "18px", xs: "14px" },
                fontFamily: "Cairo",
              }}
            >
              إنشاء حساب{" "}
            </ButtonAdd>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <SeconderyText> لديك حساب ؟</SeconderyText>
            <MainText onClick={() => navigate("/signin")}>
              تسجيل الدخول
            </MainText>
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom: "-17%",
              right: "-13%",
              width: "122px",
              height: "124px",
              zIndex: "-1",
            }}
          >
            <img
              src={loginImage}
              alt="loginImage"
              width={"100%"}
              height={"100%"}
              style={{ objectFit: "contain", opacity: "0.5" }}
            />
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "-14%",
              right: "1%",
              width: "94px",
              height: "96px",
              zIndex: "-1",
            }}
          >
            <img
              src={loginImage}
              alt="loginImage"
              width={"100%"}
              height={"100%"}
              style={{ objectFit: "contain", opacity: "0.5" }}
            />
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "-9%",
              left: "20%",
              width: "78px",
              height: "80px",
              zIndex: "-1",
            }}
          >
            <img
              src={loginImage}
              alt="loginImage"
              width={"100%"}
              height={"100%"}
              style={{ objectFit: "contain", opacity: "0.5" }}
            />
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "29%",
              left: "-18%",
              width: "127px",
              height: "130px",
              zIndex: "-1",
            }}
          >
            <img
              src={loginImage}
              alt="loginImage"
              width={"100%"}
              height={"100%"}
              style={{ objectFit: "contain", opacity: "0.5" }}
            />
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom: "-15%",
              left: "10%",
              width: "34px",
              height: "35px",
              zIndex: "-1",
            }}
          >
            <img
              src={loginImage}
              alt="loginImage"
              width={"100%"}
              height={"100%"}
              style={{ objectFit: "contain", opacity: "0.5" }}
            />
          </Box>
        </Box>
      </Grid>
      {SignupPostResponse.failAlert}
      {SignupPostResponse.successAlert}
    </Box>
  );
};

export default Signup;
