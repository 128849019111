import React, {useEffect} from "react";
import SearchComponent from "../../components/search-component";
import {Header, AddButton} from "../../components/header";
import {Avatar, Box, Container, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import CustomContainer from "../../components/features/components/customContainer";
import useRequest from "../../hooks/useRequest";
import BASEURL from "../../data/Api";
import {useDispatch, useSelector} from "react-redux";
import useConfirmMessage from "../../hooks/useConfirmMessage";

const Assembly = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const products = useSelector((state) => state.products.value);
	const columns = [
		{value: "select", name: "id", type: "checkbox", inputType: "text"},
		{value: "المسلسل", name: "id", type: "text", inputType: "text"},
		{
			value: "المكون",
			name: "name",
			type: "renderCell",
			RenderCell: (row) => {
			  return <Box sx={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center',gap:1}}>
				<Avatar src={row?.main_image} alt={row?.name} />
				<Typography sx={{fontFamily:'Cairo'}}>{row?.name}</Typography>
			  </Box>
			},
		  },
		
		{value: "الكود ", name: "code", type: "text", inputType: "text"},
		{
			value: "الرقم على الرف ",
			name: "number_on_roof",
			type: "text",
			inputType: "text",
		},
		{value: "السعر", name: "price", type: "text", inputType: "text"},
		{
			value: "الكمية",
			name: "quantity",
			type: "text",
			inputType: "text",
		},
		{value: "الإجراءات", type: "option", name: "phone", inputType: "phone"},
	];
	const [ReuestGetProducts, ResponseGetProducts] = useRequest({
		method: "Get",
		path: `${BASEURL}products/`,
	});
	const [ReuestDeleteProduct, ResponseDeleteProduct] = useRequest({
		method: "DELETE",
		path: `${BASEURL}products/`,
	});


	const getProducts = () => {
		ReuestGetProducts({
			onSuccess: (res) => {
				dispatch({type: "products/set", payload: res.data});
			},
		});
	};
	const DeleteProduct = (row) => {
		ReuestDeleteProduct({
			id:row?.id,
			onSuccess: (res) => {
				dispatch({type: "products/deleteItem", payload: {id:row?.id}});
			},
		});
	};

	const [handleDelete, deleteProductConfirmDialog] = useConfirmMessage({
		onConfirm: DeleteProduct,
		text: "هل انت متأكد من انك تريد حذف هذا المنتج؟",
	  });

	useEffect(() => {
		getProducts();
	}, []);
	return (
		<Container maxWidth="xl">
			<Header
				name={"التجميع"}
				buttonAdd={
					<AddButton
						name={"	تجميع جديد"}
						click={() => {
							navigate("addnewassembly");
						}}
					/>
				}
			/>
			<SearchComponent />
			<CustomContainer
				title={" التجميع"}
				length={{length: products?.count, name: "مكون"}}
				columns={columns}
				rows={products?.results}
				onDelete={handleDelete}
				onEdit={() => {}}
			/>
			{deleteProductConfirmDialog}
			{ResponseDeleteProduct.failAlert}
			{ResponseDeleteProduct.successAlert}
		</Container>
	);
};

export default Assembly;
