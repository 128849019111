import React from "react";
import {
	Button,
	Divider,
	Grid,
	Paper,
	Stack,
	Typography,
	useMediaQuery,
} from "@mui/material";
import {Box} from "@mui/system";
import {useSelector} from "react-redux";
import {ButtonCancel, ButtonSave} from "../../../style/buttom-style";
import PictureField from "./PictureField";

const Form = ({
	title = "مرحباً بك!",
	subtitle = "قم بملئ الحقول للحصول على النتائج المطلوبة",
	hideHeader = false,
	hideFooter = false,
	maxChildWidth = null,
	minChildWidth = null,
	childrenProps = {
		title: {},
		subtitle: {},
		saveBtn: {},
		closeBtn: {},
	},
	children,
	sx = {},
	onSubmit = () => {},
	...props
}) => {
	// Ghange lang
	// const lang = useSelector((state) => state.lang.value.lang);
	// const globalLang = useSelector((state) => state.lang.value.global);
	// const formLang = useSelector((state) => state.lang.value.form);

	///////////////////
	const sm = useMediaQuery("(max-width: 768px)");

	const handleSubmit = (e) => {
		e.preventDefault();
		onSubmit(e);
	};

	return (
		<Paper
			sx={{...sx}}
			onSubmit={handleSubmit}
			noValidate
			{...props}
			elevation={0}>
			{/* {!Boolean(hideHeader) && (
        <>
          <Stack sx={{ padding: 2, bgcolor: "#f8f8f9" }}>
            <Typography sx={{ fontWeight: "bold" }} {...childrenProps.title}>
              {formLang.title[lang]}
            </Typography>
            <Typography {...childrenProps.subtitle}>
              {formLang.subtitle[lang]}
            </Typography>
          </Stack>
          <Divider orientation="horizontal" />
        </>
      )} */}
			<Grid>
				<Grid item>
					<Grid container>
						<Grid item md={9} mb={{md: 20, xs: 4}}>
							{children}
						</Grid>
						<Grid item md={3} alignSelf={"end"} justifyContent={"flex-end"}>
							{!Boolean(hideFooter) && (
								<Stack
									direction="row"
									justifyContent="center"
									gap={3}
									sx={{padding: 2, bgcolor: "#fff", alignSelf: "flex-end"}}>
									<ButtonSave
										variant="contained"
										type="submit"
										{...childrenProps.saveBtn}>
										{Boolean(childrenProps.saveBtn?.children) ? childrenProps.saveBtn.children : "أضافة"}
											                  
											
									</ButtonSave>
									<ButtonCancel
										variant="contained"
										color="error"
										{...childrenProps.closeBtn}>
										{Boolean(childrenProps.closeBtn?.children)
											? childrenProps.closeBtn.children
											: "إلغاء"}
									</ButtonCancel>
								</Stack>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{/* <Box
        sx={{
          display: sm ? "flex" : "grid",
          flexDirection: "column",
          gridTemplateColumns: `repeat(auto-fit, minmax(${
            Boolean(minChildWidth) ? minChildWidth : sm ? "210px" : "310px"
          }, ${Boolean(maxChildWidth) ? maxChildWidth : "1fr"}))`,
          rowGap: "30px",
          columnGap: "10%",
          p: 2,
        }}
      >
        
      </Box> */}
		</Paper>
	);
};

export default Form;
